import React from "react";
import { DriverFormSection, DriverFormSectionProps } from "../Section";

export function DriverFormBusiness(props: DriverFormSectionProps) {

  const { register } = props;

  return (

    <DriverFormSection {...props}>
      <>

      <div>
          <input
            autoFocus
            ref={register({ required: true })}
            name="etag1"
            type="text"
            placeholder="Enter Etag1"
            className="register-input white-input"
          />
        </div>

        <div>
          <input
            autoFocus
            ref={register({ required: false })}
            name="etag2"
            type="text"
            placeholder="Enter Etag2"
            className="register-input white-input"
          />
        </div>
        <div>
          <input
            autoFocus
            ref={register({ required: true })}
            name="businessRegistration"
            type="text"
            placeholder="ABN"
            className="register-input white-input"
          />
        </div>

        <input
        style={{marginBottom:'50px'}}
          value='Next'
          className="register-submit"
          type="submit"
        />
      </>
    </DriverFormSection>

  );
}