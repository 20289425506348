import React, { useState } from "react";
import { DriverFormSection, DriverFormSectionProps } from "../Section";
import { FormUpload } from "../Upload";

export function DriverFormPic(props: DriverFormSectionProps) {
  const { register, getValues } = props;
  const [picturefile, setPictureFile] = useState();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onchange = () => {
    const data = getValues();
    setPictureFile(data.newPicFile);
    if (picturefile == undefined) {
      setErrorMessage('Profile Picture is missing')
    }
    else {
      setErrorMessage(undefined);
    }
  }
  return (

    <DriverFormSection {...props}>
      <>
        <div>{picturefile == undefined && errorMessage && <div className="error">{errorMessage}</div>}</div>
        <div> <FormUpload
          label='Profile Picture'
          name="newPicFile"
          originalName="picFile"
          onchange={onchange}
          multiple={false}
          {...props}
        />
        </div>

        {picturefile == undefined ?
          <div
            onClick={onchange}
            className="register-submit"
          >Next</div>
          :
          <input
            style={{ marginBottom: '50px' }}
            value={'next'}
            className="register-submit"
            type={'submit'}
          />
        }
      </>
    </DriverFormSection>

  );
}