import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { add } from "date-fns";
import React, { useRef, useState } from "react";
import { DriverFormSection, DriverFormSectionProps } from "../Section";

export function DriverFormPersonal(props: DriverFormSectionProps) {

  const { register, setValue, getValues } = props;
  let autocompleteRef = useRef<Autocomplete>(null);
  const [errorMessage, setErrorMessage] = useState<string>();



  const validateAddress = (event: any) => {
    const data = getValues();
    if (data.address.lat == "" || data.address.lng == "" || data.address.city == "" || data.address.state == "") {
      setErrorMessage("Please Enter Correct Location")
      event.preventDefault()
    }
    else {
      setErrorMessage(undefined)

    }

  }


  const onPlaceChanged = () => {
    const autocomplete: google.maps.places.Autocomplete | null | undefined = autocompleteRef.current?.state.autocomplete;

    if (autocomplete) {
      const place = autocomplete.getPlace();
      var city, state, country;
      const address: any = place.address_components
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      address?.map((component: any) => {
        var types = component.types;
        if (types.indexOf('locality') > -1) {
          city = component.long_name;
        }

        if (types.indexOf('administrative_area_level_1') > -1) {
          state = component.short_name;
        }

        if (types.indexOf('country') > -1) {
          country = component.long_name;
        }
      });


      // if(updatedCoordinates)
      // updatedCoordinates(autocomplete.getPlace())
      const lat = place.geometry?.location.lat();
      const lng = place.geometry?.location.lng();
      setValue(`address.lat`, lat);
      setValue(`address.lng`, lng);
      setValue(`address.text`, place.formatted_address);
      setValue(`address.placeId`, place.place_id);
      setValue(`address.country`, country);
      setValue(`address.state`, state);
      setValue(`address.city`, city);

      const data = getValues()
      // } else {
      // console.log(`Unable to fetch current coordinates!`)
      // }
      if (data.address.lat == "" || data.address.lng == "" || data.address.city == "" || data.address.state == "") {
        setErrorMessage("Please Enter Correct Location")
      }
      else {
        setErrorMessage(undefined)

      }
    }
  }

  const onLoad = (ref: google.maps.places.Autocomplete) => {
    // autocompleteRef.current?.setState({ autocomplete: ref})

  }
  const MAP_LIBS: Libraries = ['places'];


  return (

    <DriverFormSection {...props}>
      <>
        <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
          <div>{errorMessage ? <div className="error">{errorMessage}</div> : ""}</div>
          <div>

            {/* <input
            autoFocus
            ref={register({ required: true })}
            name="address"
            type="text"
            placeholder="Home Address"
            className="register-input white-input"
          /> */}
            <Autocomplete
              onPlaceChanged={onPlaceChanged}
              onLoad={onLoad}
              ref={autocompleteRef}
              fields={['geometry', "address_component", "formatted_address"]}
              restrictions={{ country: ['au'] }}

            >
              <input
                autoFocus
                ref={register({ required: true })}
                name="address"
                type="text"
                placeholder="Home Address"
                className="register-input white-input"
                onChange={(e) => {
                  if (e.target.value) {
                    setErrorMessage("Please AutoFill Location"); setValue(`address.lat`, "");
                    setValue(`address.lng`, "");
                  }
                }}
              />


            </Autocomplete>
            <input type="hidden" name={`address.lat`} ref={register} />
            <input type="hidden" name={`address.text`} ref={register} />
            <input type="hidden" name={`address.lng`} ref={register} />
            <input type="hidden" name={`address.placeId`} ref={register} />
            <input type="hidden" name={`address.country`} ref={register} />
            <input type="hidden" name={`address.state`} ref={register} />
            <input type="hidden" name={`address.city`} ref={register} />


          </div>

          <div>
            <input
              ref={register({ required: true })}
              name="dateOfBirth"
              placeholder="Date of Birth"
              type="text"
              onFocus={(e) => e.target.type = 'date'}
              className="register-input white-input"
            />
          </div>

          <div>
            <input
              ref={register()}
              name="address.postalCode"
              placeholder="Postal Code"
              type="text"
              className="register-input white-input"
            />
          </div>

          <div>
            <label className="register-radio">Male
              <input
                ref={register({ required: true })}
                name="gender"
                type="radio"
                value={'male'}
              />
            </label>
            <label className="register-radio">Female
              <input
                ref={register({ required: true })}
                name="gender"
                type="radio"
                value={'female'}
              />
            </label>
          </div>


          {/* <button
          // value='Next'
          className="register-submit"
          // type="submit"
          onClick={}
        >
          Next
          </button> */}



          <button
          style={{marginBottom:'50px'}}
            onClick={validateAddress}
            className="register-submit"
            type={"submit"}
          >Next</button>
          {/* :
          <input
            value={'next'}
            className="register-submit"
            type={'submit'}
          />
        } */}



        </LoadScript>
      </>

    </DriverFormSection>


  );
}