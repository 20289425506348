import React from 'react';
import {Footer} from '../../footer/Footer';

export function Standards(){
    return(
        <>
            <section className="section-yellow section-bottom-border feature-section"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> Taxi Service Provider’s Handbook for Taxi Drivers and Taxi Operators Taxis Australia Xpress Internet Services TAXIS.PLUS </h4>
                                <h2 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Rules And Standards</h2>
                                <p className="section-subtitle">January 2020</p>
                            </div>
                            <hr className="col-md-12" style={{margin:"1rem 0", border:"1px solid rgba(0,0,0,.1)"}}/>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Welcome to TAXIS.PLUS, as a Taxi Driver or as a Taxi Operator working with the new Taxi Services Provider Network and Booking Service operating on our system as Taxis Australia Xpress Internet Services.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Your Registration to use the system also confirms your acceptance of the Rules and Standards generally applicable to Taxi Drivers and Operators.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Those Rules are limited to the legal obligations you have as a Taxi Service Provider under the Point to Point Transport ( Taxis and Hire Vehicles ) Act of 2016. They include your compliance with the NSW and Australian Road Rules, and to such Regulations still in force as the Passenger Transport Regulations 2007 and those Regulations as proscribed under the Point to Point Transport Regulations 2017.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Enforcement of those Rules are the responsibility of the Point to Point Commissioner for Taxis and Hire Vehicles and the NSW Police, and Transport for NSW.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The Standards of TAXIS are based generally on the Duty of Care that you, and we, have towards Passengers and other Service Providers under the new Act.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The single additional Rule of absolute focus is your obligation to complete the accepted hiring, being a Booking or Rank and Hail hiring, subject only to a provable and reasonable excuse for failing to complete the hiring, and / or Regulatory requirements and exemptions.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">In all cases a warning will be the first action, to be followed by a fair and transparent process for repeat breaches of those Standards, or of this Rule.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">TAXIS response to any breach after a first warning in relation to that breach will never be more than a withdrawal of job offer / bookings services we provide to the driver for a discrete period of time. We will not compromise our Duty of Care towards the safety of the Driver, or of the Passenger, and the duration or term of services not to be provided will be as determined by TAXIS, subject always to the rights of Appeal by the Driver</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">If a third failure to complete a booking or hiring occurs without reasonable excuse, TAXIS may determine to indefinitely suspend such services offered to the Driver. The Driver may advise TAXIS of his or her intention to Appeal that determination and the matter will be heard with due process and administrative fairness within 30 days. A Panel of three persons composed of a Representative of the Driver, a Representative of TAXIS, and of a third and independent agreed person shall determine the matter. A nominal bond for costs of $250 shall be payable by the Driver, to be refunded if his Appeal is upheld.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">As a matter of proper Public Passenger Service, you must not smoke in the Vehicle, and you should not eat in the vehicle.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">This Handbook will be further expanded to the extent that Taxi Service Rules are so regulated, by variation of Regulations.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">TAXIS have no requirements on you to use or not use the Booking, Despatch or Payment processes made available to you as the Driver.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">We recommend their exclusive use as that will provide you and your passenger with the best available service, and request feedback on your suggestions. The system does provide for passenger feedback on your taxi driving, and we will inform you of comments made, but will not use those comments for any purpose other than as advice to you.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Please contact TAXIS or the Australian Taxi Drivers Association for assistance with any and all taxi related matters. This Taxi Network is intended to provide an optimal taxi experience to all users – both Drivers and Passengers. Any improvements you wish to suggest are welcomed and will be assessed and implemented to advance our services.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Please act at all times in a manner which favourably improves the image of Professional Taxi Drivers and promotes our Industry.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Uniforms : It is a strong recommendation that Taxi Drivers should present themselves to the travelling public, their customers, in a neat, clean and tidy manner, and be free of body odours so far as reasonably possible. TAXIS recommends the wearing of a uniform whilst driving, and that such a uniform should consist of :</p>
                                <ul className="mb-20" style={{ fontSize: "20px", color: '#858585' }}>
                                    <li>
                                        Light Blue Collared and Buttoned Shirt ( Long or Short Sleeve)
                                    </li>
                                    <li>
                                        Navy Trousers or Tailored Shorts or Skirts for Female drivers.
                                    </li>
                                    <li>
                                        Jeans or Track-pants are not suitable as a uniform.
                                    </li>
                                    <li>
                                        Navy or Black Pullover or Jacket (if required)
                                    </li>
                                    <li>
                                        Dark coloured Shoes and Socks ( Long Socks if Shorts)
                                    </li>
                                    <li>
                                        High Visibility Safety Vest ( for use outside the taxi at night)
                                    </li>
                                    <li>
                                        Ties and Caps or Headgear are optional
                                    </li>
                                </ul> 
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>USE OF DEVICE :</h4> 
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The Tablet has been fitted to the Taxi by your Operator to facilitate many aspects of Taxi Driving.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The emergency process and special feature will be explained to you prior to your first shift. For your and the passenger’s safety this device records all audio /video activity within the cab whilst hired, and takes a snapshot each ten seconds at all other times on the road. All data is stored electronically and can be downloaded for an Authorised Purpose, which may include your defence of certain allegations, by an Authorised Person.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">For your Safety we require that you always log-on, stay on-line and log-off.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>USE OF YOUR OWN SMARTPHONE :</h4> 
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Where a Tablet has not been fitted, and you are using the system while driving for another Network, it remains a condition of service that you complete all Bookings obtained through our system.Please ensure that your smartphone is securely and legally fitted and not hand-held. Please use hands-free operations as a first option, and refrain from speaking on the phone whilst a passenger is on-board.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>The Operating System </h4> 
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">As a Taxi Driver you will need to Register with your personal details in order to use the system. This process is all on-line on the website, and you will need to log-on, stay connected and log-off at the end of a shift. You will automatically receive on your registered e-mail address all details of the shift activity, and you will also be able to access and print a wide range of Reports for Income, GST and Tax.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>A demonstration video will be available on-line.</h4> 
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Our Information data-base and records may be required to be supplied to Authorised Persons and Government Departments, but access to individual specific Bank Account monetary transactions is password protected, and you are the only password holder.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>TAXIS.NET.AU and TAXIS.PLUS - Safety of Services </h4> 
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">As the proposed Authorised Affiliated Provider, and as the Authorised Facilitator of an Affiliated Service, and as the Authorised Provider of a Booking Service, under the NSW Point to Point (Taxis and Hire Vehicles) Ac of 2016 and the attendant Regulations of 2017, we have developed the following regime for the Safety of Services provided, and specific Safety Standards, as a risk management procedure.Compliance with these Standards is mandatory on all passenger vehicle service providers connected to, or supplied with Taxi or Hire Vehicle Services from taxis.net.au or TAXIS.PLUS</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Any breach of these Standards by either the Driver or the Vehicle shall result in a Warning to the responsible person or entity. Any second breach shall result in a Severe Warning to the responsible person or entity. Any third breach within any one twelve month period shall result in the responsible person or entity being removed from all access to the services, and the matter being referred to the Commissioner.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Restoration of Services shall be subject to a successful appeal to a properly constituted Appeals Committee, and on such terms as that Committee determines, and / or as the Commissioner may determine</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">In order to meet the requirements of the Regulations, the following four identified areas of responsibility, and the consequential related Standards are :</p>
                                <ul className="mb-20" style={{ fontSize: "20px", color: '#858585' }}>
                                    <li>
                                        Risks to the Health and Safety of Drivers and Passengers
                                    </li>
                                    <li>
                                        Driver Records
                                    </li>
                                    <li>
                                        Vehicle Roadworthiness and Registration Standards
                                    </li>
                                    <li>
                                        Maintenance and Inspection Records
                                    </li>
                                     
                                </ul>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">TAXIS.PLUS, as a part of its services to member passenger services providers makes available electronic data access systems to provide records for the Drivers and Service Providers which can substantiate compliance. We do not warrant that these records are true and correct since, in part, they are derived from entries made by the affected persons or entities. In part, the records result from automated and verifiable entries from the Operating Systems. Such records will be supplied to the Commissioner on request. We believe that a “three strikes” policy is a reasonable interpretation of the Duty of Care we accept and that within any one twelve month period of two such breaches which do not result in “notifiable incidents” are acceptable but of concern. Any third breach is unacceptable. All identified breaches will be recorded.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The issue of Driver Fatigue is a major concern, and is not solely addressed by hours at the wheel of the relevant Passenger Service Vehicle. Certainly, the system will caution and, by due process, exclude from the booking system any driver who has driven more than 12 hours in any 18 hour period and without reasonable explanation. Driving before or after the recorded shift, in a private or other vehicle, is beyond our control.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The issue of Vehicle Roadworthiness is of equal concern, and our Duty of Care cannot reasonably go beyond maintaining strict records of Maintenance, Inspection and Rectification. The TAXIS system will flag all shortcomings to the Operator / Service Provider, and will in the near future make available a telemetric On Board Device for electronic reporting of vehicle and driver behaviour.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">We note that Driver Fatigue remains a primary responsibility of the Driver, as does the Roadworthiness of the Vehicle for the Service Provider.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Risks and Hazards</h4> 
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">1.1: Continuous driving of a Public Passenger Vehicle for more than six hours without a break of at least 20 minutes is a risk to the safety of both Driver and Passengers. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">1.2 It is a mandatory requirement of TAXIS.PLUS that such a break be taken during the shift, and be recorded on the system.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">1.3 Driving of more than 12 hours in any 18 hour period hours without a break of at least 8 hours is a risk to the safety of both Driver and Passengers. Log-on of drivers without such a break will not be permitted.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">1.4 There may be situations where such a shift period is unavoidable, and such situations are notifiable to the appropriate Booking Service.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">1.5 Driving of a Public Passenger Vehicle not equipped with a functional continuous audio / video recording systems available for immediate access for authorised purposes is, in our view, an unacceptable risk and hazard.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">1.6 All Public Passenger Vehicles operating on our TAXIS.PLUS network, must be equipped with audio / video devices that record all activities in the vehicle.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">1.7 A driver shall not accept, or be obliged to accept, a vehicle which is evidently un-roadworthy.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">2.1 Driver Records. A driver must not drive a Public Passenger Vehicle unless he or she is currently authorised by the appropriate Authority being, RMS and / or the Authorised Booking Service or Affiliated Service Provider, and with auditable records of such Authorisation.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">3.1 A Public Passenger Vehicle must, at all times, meet the requirements of Vehicle Registration and Roadworthiness, together with any additional requirements as may apply to Public Passenger Vehicles.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">3.2 Making available for use by an Engaged Driver of a vehicle which does not meet Roadworthiness Standards is a breach of these Standards.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">3.3 The Operator of a Public Passenger Vehicle shall ensure that a functional continuous audio / video recording systems available for immediate access for authorised purposes is installed and operative.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">4.1 A Passenger Vehicle Service Provider shall maintain an accessible electronic record for each Vehicle operated which provides details as to Dates, Service Provider, Invoice No. and which can be substantiated by hard copies. Where rectification of defects is indicated, the proof of such rectification must be recorded.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">4.2 Such accessible records are intended to provide compliance verification with Roadworthiness and Inspection Requirements at any time when the vehicle is operational, or available for hire.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">4.3 A Passenger Vehicle Service Provider shall also maintain accessible electronic records for each Vehicle operated which provides details as to usage of the vehicle by an engaged driver, or the Operator acting as a Driver, including log-on and log-off data, number of trips, kilometres travelled, and such other information as may be required to substantiate the fulfillment of the terms of engagement, and NSW Government Levy.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">4.4 Where a Public Passenger Vehicle is not operating on the TAXIS.PLUS Network, and / or where a Driver is making use of no more than the facilitation for Bookings, Despatch and Payment of the TAXIS.PLUS Booking Services, the management of Risks and Hazards relating to the Vehicle are beyond the reasonable ability of TAXIS.PLUS to control.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Compliance with other Regulatory / Legislative Requirements.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">In Sydney, NSW, the 1984 Taxi Drivers Contract Determination remains as the legally binding Agreement between Bailee Taxi Drivers and Taxi Operators. In Victoria the Taxi Services Commission has a similarly enforceable Driver Agreement. In Queensland there is an enforceable Taxi Industry Agreement as there is in other States and Territories. All these arrangements are outdated and ineffective. In conjunction with the Australian Taxi Drivers Association we are preparing a Workplace Agreement for Drivers of Public Passenger Vehicles for registration with Fair Work Australia.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>FEES AND CHARGES JANUARY 2019</h4>
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Full Network Membership $450 per month in advance</h4> 
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Operator Rebate of $1.00 per Booked Trip</h4> 
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Booking Fees retained by TAXIS.PLUS ($2.50)</h4> 
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Credit Card Surcharge 4% payable by Passenger</h4> 
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>All Fares paid by Credit Card credited to Drivers’ PayPal Account or nominated Bank Account</h4> 
                                <h4 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Edited January 2020</h4> 
                            </div>
                        </div>
                    </div>
            </section>
            <Footer/>
        </>
    )
}