import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../footer/Footer';
import imgPlayStore from './../../img/play-store.png';

export function Drivers() {
  return (
    <>
      <section className="section-yellow home-section" id="home">
        <div className="container">
          <h1 className="margin-top-30">TAXIS.PLUS for the DRIVER</h1>
          <h3>Sydney's Newest Taxi Network</h3>
          <h4>Our Expectation : That our Drivers are Professional Taxi-drivers.</h4>
          <h4>Our Rule : If you accept a job, you do the job.</h4>
          <p>Register with all the Point to Point Requirements</p>
          <p>Do it on-line and complete all the boxes. Join a Driver Group or make your own, and build your own Customer Base.</p>
          <p>That's it. No outrageously unreasonable Code of Conduct. No Penalties in Breach - just a simple three 'no-shows' and you're out, (but still with a right to Appeal). And we make it extremely unlikely for you not to show up. You get Destinations on all Booked Trips, plus Fare Estimate and full Google Mapping to pick-up and drop-off.</p>
          <p>You and the Passenger get Voice and SMS live contact. You can reject job offers, but are expected to do the job you accept. You can see each other's location.</p>
          <p>Payments by Passenger Credit Card at end of shift to your Stripe Account - plus on-screen prompt for Tips. E-mail Receipt to the Passenger. Credited to your Bank Account usually within 24 hours.</p>
          <p>A QR code to make to link the Passenger with us, and provide quick sign -up</p>
          <p>Start now with the App on your Android Mobile or Tablet. Move to our Network, with your Operator</p>
          <p>The Cost to you as a Driver - NIL. Taxis.Plus gets the Booking Fee and credits the "Group" with $1.00 from Booked Jobs coming through your Group. You get the full fare, less the Levy, and Booking Fee if applicable, on each fare. If the Passenger pays by Credit Card he or she is charged only 4% as the Transaction Fee.</p>
          <p>When you join the Network, same thing. The Operator who pays $3000 once-off gets a new Roofsign, Cameras and Tablet with pre-paid SIM. And you both then get Security. All the Audio-Video is stored on the Cloud for immediate access by Authorised Officers, and the external views are available for Insurance Claims, or Court matters. The Duress Alarm is monitored, and a full alarm goes straight to Police Central, as well as to the Network, and if you want as third point to whoever you nominate. Stage one puts the Camera Display on the Screen.</p>
          <p>TAXIS.PLUS is not just a new way of doing things, it's the way things should be done. It leapfrogs Uber, Ola and DIDI, and it gives the Taxi Industry the ways and means to regain our role as the premier Point to Point Transportation system.</p>
          <p>Every Passenger you now pick-up can easily become a Taxis.Plus user, and that's how we build the customer base - through taxi trips and not by "free lunches" and by their satisfaction with your standards of Professionalism. Fares are as set by Government, and despite all other cost increases have increased by less than 2% in over ten years. The average taxi-driver still earns less than $10.00 an hour. Taxis.Plus will increase that to at least the minimum wage by increased utilization and lower expenses. That's the plan.</p>
          <p>You can operate the TAXIS.PLUS system from the installed Tablet ( and link your mobile so that you are "hands-free") or use your own Smartphone or Tablet when driving in another Network as a starting point. Everything except the Security Cameras and Alarm are fully operative on your own Device. Our Driver App is Android only because of Apple charges.</p>
          <p>If you want, and especially for the Owner / Driver who wants to try it out before actually changing Networks, we suggest a basic Android Tablet and Mounting which we can supply for $250 or use your own Android Tablet or Smartphone. Then, when you move to Taxis.Plus , you can get the whole system for $3000 or $1500 if you use an existing and compatible Roof Bar and Cameras.</p>
          <p>When using as your Taxi a vehicle that is not Registered in our Fleets, please use T0000 as the Virtual Taxi that then  allows you to Register the actual vehicle. Change to your actual Taxi, enter some details, re-start, and you are on the way.</p>
          <p>Scanning the QR Code not creates a complete trip record, and a link to direct, hands-free payment (and a tip for you).</p>
          <p>We want to move the whole of our Industry forward.</p>
          <Link to="/drivers/register/" className="register-submit" type="submit">Register</Link>
          <hr />
          <h3>Driver App: <a className="store-btn android" target='_blank' href={`${process.env.REACT_APP_GOOGLE_PLAYSTORE_DRIVER_URL}`}><img src={imgPlayStore} alt="play store" /></a></h3>
          
        </div>
      </section>
      <Footer />
    </>
  );
}
