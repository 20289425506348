import React from 'react';
import { OperatorFormSection, OperatorFormSectionProps } from '../Section';

export function OperatorFormDone(props: OperatorFormSectionProps) {
  return (
    <OperatorFormSection {...props}>
      <div className="registered">
        <p>Thank you for registering as a operator with Taxis.Plus!</p>
        <p>We have received your application and will review and inform you about the next steps in the registration process.</p>
      </div>
    </OperatorFormSection>
  );
}