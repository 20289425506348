import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { PaxApiService } from '../../service/api.service';
import { authentication } from '../../service/auth.service';
import { Footer } from '../footer/Footer';


export const PassengerLogout = (props:any) => {
    useEffect(()=>{
        authentication.PassengerLogout()
        props.history.push('/');
    props.handleCorporateLogout();
    props.handlePassengerLogout()
    },[])
 
    return <></>
}