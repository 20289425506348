import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { ApiService } from "../../../service/api.service";
import { DriverFormSection, DriverFormSectionProps } from "../Section";

interface FleetList {
  id: string,
  title: string,
}

export const DriverFormFleet = (props: DriverFormSectionProps) => {

  const { control } = props;
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    refresh();
  }, [selectedOption])

  const refresh = async () => {
    try {
      const response = await ApiService.get('fleet/register/list');
      const data: FleetList[] = response.data.map((item: any) => ({ value: item.id, label: item.title }));
      setOptions(data);
    } catch (err) {
      console.log('Failed to load fleet list', err);
    }
  }

  return (

    <DriverFormSection {...props}>
      <>
        <Controller
          autoFocus
          control={control}
          name='fleets'
          defaultValue={null}
          placeholder="Fleets"
          isMulti
          backspaceRemovesValue
          isClearable
          isSearchable
          hideSelectedOptions
          closeMenuOnSelect={false}
          options={options}
          rules={{ required: true }}
          className="register-input white-input"
          as={Select}
        />
        <input
        style={{marginBottom:'50px'}}
          value='Next'
          className="register-submit"
          type="submit"
        />
      </>
    </DriverFormSection>

  );
}