import React from 'react';
import { Footer } from '../../footer/Footer';

export function AugNews(){
    return(
        <>
            <section className="section-yellow section-bottom-border feature-section"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-bottom-10">
                                <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> August Newsletter </h1>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">From October 1st the Sydney Taxi Industry changes : We will have an Authorised Booking Service and an Authorised Taxi Network that is free to use and join. Registered and properly authorised Taxi Drivers and Taxi Service Providers ( Operators ) can join TAXIS.PLUS and use the most advanced system for free.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Drivers can use their own Mobiles to run the Bookings / Despatch / Payments modules.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Operators can buy a complete set of devices for $2000 – Tablet and Mounting, Cameras and Roofbar.  SIM and Internet connections are included at no extra cost. All Wireless / WiFi, so no installation costs. Full Security.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Or, for $600  in total, over time, the Driver can buy just the Tablet  and Mounting.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">TAXIS.PLUS runs the whole system on the Credit Card Transaction Fees (4%) and the Booking Fee ($2.50)</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">There are no Network Fees. There is full Network Service. And the Driver and Operator get a share of the $2.50  Booking Fee.  Sounds Good !  Go to the web and Sign-up.</p>
                            </div>
                        </div>
                    </div>
            </section>
            <Footer />
        </>
    )
}