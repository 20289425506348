import React from 'react';
import { DriverFormSection, DriverFormSectionProps } from '../Section';

export function DriverFormDone(props: DriverFormSectionProps) {
  return (
    <DriverFormSection {...props}>
      <div className="registered">
        <p>Thank you for registering as a driver with Taxis.Plus!</p>
        <p>We have received your application and will review and inform you about the next steps in the registration process.</p>
      </div>
    </DriverFormSection>
  );
}