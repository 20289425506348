import React from 'react';
import { OperatorStep, OperatorViewState } from './Register';

interface Props {
  steps: OperatorStep[],
  currentView: OperatorViewState,
  setStep?: (step: OperatorStep) => void,
}

export function OperatorSteps({ steps, currentView, setStep }: Props) {

  const isCurrentStep = (step: OperatorStep): boolean => step.view === currentView;

  const isIncomplete = (step: OperatorStep): boolean => !step.completed;

  const getClassNames = (step: OperatorStep): string => {
    const classNames = ['nav-link'];
    if (isCurrentStep(step)) {
      classNames.push('active');
    } else if (isIncomplete(step)) {
      classNames.push('muted');
    }
    return classNames.join(' ');
  }

  const onSelect = (step: OperatorStep) => {
    if (!isIncomplete(step) && setStep) {
      setStep(step);
    }
  }

  return (
    <div className="form-steps nav flex-column nav-pills">
      { steps.map(step => (
        <a href={`#step-${step.view}`} key={step.view} className={getClassNames(step)} onClick={() => onSelect(step)}>
          <div className="features-second">
            <h4>{step.title}</h4>
            <p>{step.description}</p>
          </div>
        </a>
      ))}

    </div>
  );
}
