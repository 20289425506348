/// <reference types="googlemaps" />
import { Autocomplete, DirectionsRenderer, GoogleMap, LoadScript, Marker, TrafficLayer } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { add, formatDuration, intervalToDuration } from 'date-fns';
import car from '../../../img/car.svg'
import { ApiService } from '../../../service/api.service';
import { useHistory } from "react-router-dom";
import { Booking } from '../../entities/booking.entity';
import { Zone } from '../../entities/zone.entity';
import { Currency } from '../../entities/currency.entity';
import { Passenger } from '../../entities/passenger.entity';
import { Modal } from "react-responsive-modal";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { authentication } from '../../../service/auth.service';
import Select from "react-select";
import { CorporateAccountList } from '../../corporate/corporateAccount';
const MAP_LIBS: Libraries = ['places'];

let directionsService: google.maps.DirectionsService;
interface ServiceList {
   id: string,
   title: string,
   isDefault: boolean,
}

enum Rate {
   Meter = "meter",
   Flat = "flat"
}
enum Status {
   Pickup = "pickup",
   Dropoff = "dropoff",
   Bidding = "bidding",
   Abandoned = "abandoned",
   DriverCancelled = "driver-cancelled",
   PassengerCancelled = "passenger-cancelled",
   AwaitingAccept = "awaiting-accept",
   NotAccepted = "not-accepted",
   DriverEnroute = "driver-enroute",
   DestinationEnroute = "destination-enroute",
   Arrived = "arrived",
   Completed = "completed"
}

interface Driver {
   id: string,
   code: string,
   firstName: string,
   lastName: string,
   gender: string,
   email: string,
   phone: string,
   dateOfBirth: Date,
   businessRegistration: string,
   licenseNumber: string,
   licenseType: string,
   licenseExpiryDate: Date,
   emergencyName: string,
   emergencyEmail: string,
   emergencyPhone: string,
   bankName: string,
   bankAccountNumber: string,
   bankAccountRouting: string,
   bankAccountTitle: string,
   status: string,
   createTime: string,
   updateTime: string,
   deletedTime: string
}

interface Vehicle {
   id: string,
   vin: string,
   plate: string,
   registrationExpiryDate: Date,
   year: string,
   make: string,
   model: string,
   color: string,
   numPassengers: number,
   numLuggage: number,
   ownership: string,
   status: string,
   createTime: string,
   updateTime: string,
   deletedTime: string
}

enum Type {
   Dispatch = "dispatch",
   Corporate = 'corporate'
}
enum StepType {
   Personal = "personal",
   OTP = 'otp',
   Stripe = "stripe"
}

interface Area {
   driver: Driver,
   vehicle: Vehicle,
   lat: number,
   lng: number,
   heading: number,
}

interface Gps {
   GpsNearBy: Area[]
}

interface Name {
   firstName: string;
   lastName: string
}

interface location {
   lat: number | undefined,
   lng: number | undefined,
   text: string | undefined,
   area: string | undefined,
   city: string | undefined,
   state: string | undefined,
   country: string | undefined,

}




export function Home() {
   let pickupAutocompleteRef: any = useRef<Autocomplete>(null);
   let dropoffAutocompleteRef: any = useRef<Autocomplete>(null);
   let pickup: any = useRef();
   let dropoff: any = useRef();

   let history = useHistory();
   const [dropOffMarker, setDropOffMarker] = useState<boolean>(false);
   const [pickupMarker, setPickupMarker] = useState<boolean>(false);
   const [center, setCenter] = useState<google.maps.LatLng>();
   const [zoom, setZoom] = useState(10);
   const [map, setMap] = useState<google.maps.Map>();
   const [generalCurrency, setGeneralCurrency] = useState<Currency>();
   const [zone, setZone] = useState<Zone>();
   const [pickupPlace, setPickupPlace] = useState<google.maps.places.PlaceResult>();
   const [dropoffPlace, setDropoffPlace] = useState<google.maps.places.PlaceResult>();
   const [directions, setDirections] = useState<google.maps.DirectionsResult>();
   const [pickupPlaceFormatted, setPickupPlaceFormatted] = useState<location>();
   const [dropoffPlaceFormatted, setDropoffPlaceFormatted] = useState<location>();

   const [distance, setDistance] = useState<number>();
   const [scheduledBooking, setScheduledBooking] = useState<boolean>(false);
   const [repeatBooking, setRepeatBooking] = useState<boolean>(false);
   const [duration, setDuration] = useState<number>();
   const [trafficDuration, setTrafficDuration] = useState<number>();
   const [estimate, setEstimate] = useState<number>();
   const [pickupLatLng, setPickupLatLng] = useState<google.maps.LatLng>();
   const [options, setOptions] = useState<any[]>([]);
   const [dayOptions, setDayOptions] = useState<any[]>([{ label: "Monday", value: "Monday" }, { label: "Tuesday", value: "Tuesday" }, { value: "Wednesday", label: "Wednesday" }, { value: "Thursday", label: "Thusrday" }, { label: "Friday", value: "Friday" }, { label: "Saturday", value: "Saturday" }, { label: "Sunday", value: "Sunday" }]);
   const [findArea, setFindArea] = useState<Gps>();
   const [serviceId, setServiceId] = useState<string>('');
   const [point, setPoint] = useState<google.maps.Point>();
   const [dropoffLatLng, setDropoffLatLng] = useState<google.maps.LatLng>();
   const [size, setSize] = useState<google.maps.Size>();
   const [sendData, setSendData] = useState<boolean>(true);
   const [email, setEmail] = useState<string>();
   const [repeatDays, setRepeatDays] = useState<string[]>([]);
   const [phone, setPhone] = useState<string>();
   const [scheduledTimestamp, setScheduledTimestamp] = useState<Date>();
   const [repeatEndTime, setRepeatEndTime] = useState<Date>();
   const [name, setName] = useState<Name>({ firstName: '', lastName: '' });
   const [passenger, setPassenger] = useState<Passenger>();
   const [clientSecret, setClientSecret] = useState<string>();
   const [step, setStep] = useState<StepType>(StepType.Personal);
   const [verificationCode, setVerficationCode] = useState<string>();
   const [stripeOptions, setStripeOptions] = useState<any>()
   const [booking, setBooking] = useState<any>();
   const [corporateData, setCorporateData] = useState<any>();
   const [loading, setLoading] = useState<any>(false);

   const onChangeDays = (e: any) => {
      const final = e?.map((item: { value: string }) => item.value)
      setRepeatDays(final);
   }

   useEffect(() => {
      const corporateUser = authentication.getCorporateUser()
      setCorporateData(corporateUser?.corporateAccount)
   }, [])

   const stripePromise = loadStripe((process.env.REACT_APP_STRIPE_KEY) as string);

   const getZoneByLocation = async (pickupLocation: google.maps.places.PlaceResult) => {
      if (pickupLocation) {
         const response = await ApiService.post('fare/getZone', {
            lat: pickupLocation?.geometry?.location.lat(),
            lng: pickupLocation?.geometry?.location.lng(),
         });
         setGeneralCurrency(response.data.generalCurrency);
         setZone(response.data);
      }
      try {

      } catch (error) {
         console.log('Error Inside Zone:', error);
      }

   }


   const onLoad = React.useCallback((map) => {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
      setMap(map)

      setTimeout(() => {
         // Sydney
         const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
         const DEFAULT_POINT = new google.maps.Point(32, 32);
         const DEFAULT_SIZE = new google.maps.Size(64, 64);

         setCenter(DEFAULT_CENTER);
         setPoint(DEFAULT_POINT)
         setSize(DEFAULT_SIZE)
         setZoom(12);
      }, 100);

      directionsService = new google.maps.DirectionsService();
      if (pickupAutocompleteRef.current?.state?.autocomplete) { pickupAutocompleteRef.current.state.autocomplete.addListener('place_changed', () => onAddressSelect('pickup')); }
      if (dropoffAutocompleteRef.current?.state?.autocomplete) {
         dropoffAutocompleteRef.current.state.autocomplete.addListener('place_changed', () => onAddressSelect('dropoff'));
      }

   }, [])

   const onUnmount = React.useCallback(() => {
      setMap(undefined)
   }, []);

   const onAddressSelect = async (type: 'dropoff' | 'pickup') => {
      const ref = type === 'dropoff' ? dropoffAutocompleteRef : pickupAutocompleteRef;
      const areaTypes = ['locality', 'sublocality_level_1', 'sublocality', 'political'];
      const autocomplete: google.maps.places.Autocomplete | null | undefined | any = ref.current?.state.autocomplete;
      if (!autocomplete) {
         return;
      }
      var city, state, country;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const area = await autocomplete?.getPlace()?.address_components.find((component: any) => {
         for (let areaType of areaTypes) {
            if (component.types.includes(areaType)) {
               return true;
            }
         }
         return false;
      });

      await autocomplete?.getPlace()?.address_components?.map((component: any) => {
         if ((component?.types?.indexOf("locality") > -1)) {
            city = component.long_name;
         }

         if ((component.types.indexOf("postal_town") > -1)) {
            city = component.long_name;
         }

         if (component.types.indexOf("administrative_area_level_1") > -1) {
            state = component.short_name;
         }

         if (component.types.indexOf("country") > -1) {
            country = component.long_name;
         }
      });


      let key: string = Object.keys(autocomplete.gm_accessors_.place)[0];

      type === 'dropoff' ? setDropoffPlaceFormatted({ lat: autocomplete.getPlace().geometry?.location.lat(), lng: autocomplete.getPlace().geometry?.location.lng(), text: autocomplete.getPlace().formatted_address, area: area.long_name, city: city, country, state })
         : setPickupPlaceFormatted({ lat: autocomplete.getPlace().geometry?.location.lat(), lng: autocomplete.getPlace().geometry?.location.lng(), text: autocomplete.getPlace().formatted_address, area: area.long_name, city, country, state })

      const setter = type === 'dropoff' ? setDropoffPlace : setPickupPlace;
      setter(autocomplete.getPlace());

      if (type === 'dropoff') {
         setDropoffPlace(autocomplete.getPlace())
         setDropOffMarker(false)
      }
      if (type === 'pickup') {
         setPickupPlace(autocomplete.getPlace())
         setPickupMarker(false)
      }
   }

   const findInArea = async () => {
      try {
         if (pickupPlace) {
            if (sendData === true) {
               const response = await ApiService.post('driver/findInArea', {
                  latitude: pickupPlace?.geometry?.location.lat().toString(),
                  longitude: pickupPlace?.geometry?.location.lng().toString(),
                  serviceId: serviceId.toString()
               })
               setFindArea(response.data)
               setSendData(false)
            } else {
               setInterval(async function () {
                  const response = await ApiService.post('driver/findInArea', {
                     latitude: pickupPlace?.geometry?.location.lat().toString(),
                     longitude: pickupPlace?.geometry?.location.lng().toString(),
                     serviceId: serviceId.toString()
                  })
                  setFindArea(response.data)
               }, 30000)
            }
         } else {
            console.log('this api will not run until pickupPlace location filled')
         }
      } catch (err) {
         console.log('Failed to find in area', err)
      }
   }

   useEffect(() => {
      findInArea()
      if (pickupPlace) {
         getZoneByLocation(pickupPlace)
      }
   }, [pickupPlace])

   const loadEstimate = async () => {
      console.log('Trafic Time:', trafficDuration, serviceId);
      const response = await ApiService.post('fare/estimate', {
         lat: pickupPlace?.geometry?.location.lat(),
         lng: pickupPlace?.geometry?.location.lng(),
         distance,
         duration,
         traffic: trafficDuration,
         serviceId,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
      });

      console.log('Estimate:', response);
      setEstimate(response.data);
   }

   useEffect(() => {
      if (!pickupPlace || !distance || !duration || !serviceId === undefined) {
         return;
      }
      loadEstimate();

   }, [pickupPlace, distance, duration, trafficDuration, serviceId, estimate]);

   useEffect(() => {
      refresh();
   }, [])

   const refresh = async () => {
      try {
         const response = await ApiService.get('service/public/list');
         const data: ServiceList[] = await response.data.map((item: any) => ({ id: item.id, title: item.title, isDefault: item.isDefault }));
         setOptions(data)
         
         let service;
         for (let i = 0; i < data.length; i++) {
            if (data[i].isDefault == true) {
               service = data[i].id
               setServiceId(data[i].id)
            }
         }
         if (service === "" || typeof service=='undefined') {
            setServiceId(data[0].id)

         }

      } catch (err) {
         console.log('Failed to load fleet list', err);
      }
   }
   const onChange = (e: any) => {
      setServiceId(e.target.value);
   }

   useEffect(() => {
      if (!pickupPlace || !dropoffPlace) {
         if (pickupPlace) {
            setCenter(pickupPlace.geometry?.location);
            setZoom(18);
         }
         if (dropoffPlace) {
            setCenter(dropoffPlace.geometry?.location);
            setZoom(18);
         }
         // setDirections(undefined);
         // return;
      }

      // Set departure time to 10 minues from now
      const departureTime = add(new Date(), { minutes: 10, hours: 5 });
      if (directionsService && pickupPlace && dropoffPlace) {
         directionsService.route({
            origin: pickupMarker === true ? pickupLatLng : pickupPlace?.geometry?.location,
            destination: dropOffMarker === true ? dropoffLatLng : dropoffPlace?.geometry?.location,
            travelMode: google.maps.TravelMode.DRIVING,
            durationInTraffic: true,
            drivingOptions: { departureTime }
         }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
               setDirections(result);

               const route = result.routes[0].legs[0];
               if (!route) {
                  return;
               }
               setDistance(route.distance.value);
               setDuration(route.duration_in_traffic ? route.duration_in_traffic.value : route.duration.value);

               const trafficTime = route.duration_in_traffic ? route.duration_in_traffic.value - route.duration.value : 0;
               if (trafficTime > 0)
                  setTrafficDuration(trafficTime);
               else {
                  setTrafficDuration(0);
               }
            }
         })
      }
   }, [pickupPlace, dropoffPlace, serviceId]);

   const distanceText = (): string => {
      if (!distance) {
         return 'N/A';
      }
      return `${roundTo(distance / 1000)} km`;
   }

   const durationText = (): string => {
      if (!duration) {
         return 'N/A';
      }
      const epoch = new Date(0)
      const secondsAfterEpoch = new Date(duration * 1000)
      const durationObj = intervalToDuration({
         start: epoch,
         end: secondsAfterEpoch
      })
      return formatDuration(durationObj, { format: ['hours', 'minutes'] });
   }

   const trafficText = (): string => {
      if (!trafficDuration) {
         return 'N/A';
      }
      const epoch = new Date(0)
      const secondsAfterEpoch = new Date(trafficDuration * 1000)
      const durationObj = intervalToDuration({
         start: epoch,
         end: secondsAfterEpoch
      })
      return formatDuration(durationObj, { format: ['hours', 'minutes'] });
   }

   const estimateText = (): string => {
      if (!estimate || isNaN(estimate)) {
         return '...';
      }

      return estimate.toFixed(2).toString();
   }

   const roundTo = (val: number): number => {
      return Math.round((val + Number.EPSILON) * 100) / 100
   }

   const onHandleVerfication = async (e: any) => {
      e.preventDefault()
      setLoading(true);
      try {
         const response = await ApiService.post('passenger/verify/phone', {
            phone: phone,
            code: verificationCode
         });
         if (response) {
            if (passenger?.defaultCard) {
               createBooking()
            }
            //       const {data}: Booking = await ApiService.post('booking/passenger', {
            //    peerId: '123e4567-e89b-12d3-a456-426614174000',
            //    firstName: name.firstName,
            //    lastName: name.lastName,
            //    service: serviceId,
            //    pickupAddress: { lat: pickupPlaceFormatted?.lat, lng: pickupPlaceFormatted?.lng, text: pickupPlaceFormatted?.text,area:pickupPlaceFormatted?.area },
            //    dropOffAddress: { lat: dropoffPlaceFormatted?.lat, lng: dropoffPlaceFormatted?.lng, text: dropoffPlaceFormatted?.text, area:dropoffPlaceFormatted?.area },
            //    email: email,
            //    phone: phone,
            //    distance: distance,
            //    duration: durationText(),
            //    status: Status.Dropoff,
            //    zone:zone,
            //    estimate: estimate,
            //    rate: Rate.Meter,
            //    type: Type.Dispatch
            // })
            else {
               setStep(StepType.Stripe)
               setLoading(false);
            }
            // setBooking(data)
            // history.push(`/track/${data.code}`)
         }
         else {
            setLoading(false);
            alert("Wrong OTP Code")
         }
      } catch (error) {
         console.log("Error Inside Verification:", error)
         setLoading(false);
      }

   }

   const onHandleClick = async (e: any) => {
      setLoading(true)
      e.preventDefault()
      try {
         const response = await ApiService.post('passenger/createWithStripe', {
            phone,
            name: name.firstName,
            lastName: name.lastName,
            email,
            appName: `${process.env.REACT_APP_NAME?.toUpperCase()}`,
            corporateAccount: corporateData ? true : false,
            corporateId: corporateData ? corporateData.id : null
         });

         if (response.data?.error) {

            switch (response.data.error.code) {
               case "23505":
                  alert("Combination of email address and phone are already registered !")
                  break;
               default:
                  alert(`Error: ${response.data?.error?.message}`)
            }

         }
         else {
            let passengerData: any;
            if (response.data?.newPassenger || response.data?.passenger) {
               // setNewPassenger(response.data?.newPassenger)
               passengerData = response?.data?.newPassenger ? response?.data?.newPassenger : response.data?.passenger
               setPassenger(passengerData)
               setClientSecret(response.data?.clientSecret)
               const options = {
                  clientSecret: response.data?.clientSecret,
               }
               setStripeOptions(options)
               if (corporateData) {
                  if (corporateData?.defaultCard) {
                     createBooking()
                  }
                  else {
                     setStep(StepType.Stripe)
                  }
               }
               else {
                  setStep(StepType.OTP)
               }

            }
            else {
               console.error("no passenger data received ")
            }
         }

         setLoading(false);
      }
      catch (error: any) {
         setLoading(false);
         alert(`Error: ${error?.message}`)
      }
   }

   const createBooking = async () => {
      try {
         setBooking(undefined);
         const corporateUser = authentication.getCorporateUser()
         if (repeatEndTime && scheduledTimestamp && new Date(scheduledTimestamp) > new Date(repeatEndTime)) {
            alert("invalid end time for repeat booking")
            return;
         }
         const { data }: Booking = await ApiService.post('booking/passenger', {
            peerId: '123e4567-e89b-12d3-a456-426614174000',
            firstName: name.firstName,
            lastName: name.lastName,
            isCorporate: corporateUser ? true : false,
            corporateAccount: corporateUser?.corporateAccount?.id,
            corporateUser: corporateUser?.id,
            service: serviceId,
            pickupAddress: { lat: pickupPlaceFormatted?.lat, lng: pickupPlaceFormatted?.lng, text: pickupPlaceFormatted?.text, area: pickupPlaceFormatted?.area, city: pickupPlaceFormatted?.city, country: pickupPlaceFormatted?.country, state: pickupPlaceFormatted?.state },
            dropOffAddress: { lat: dropoffPlaceFormatted?.lat, lng: dropoffPlaceFormatted?.lng, text: dropoffPlaceFormatted?.text, area: dropoffPlaceFormatted?.area, city: dropoffPlaceFormatted?.city, state: dropoffPlaceFormatted?.state, country: dropoffPlaceFormatted?.country },
            email: email,
            phone: phone,
            scheduledTimestamp: scheduledTimestamp,
            repeatEndTime: repeatEndTime,
            distance: distance,
            duration: durationText(),
            status: Status.Dropoff,
            zone: zone,
            estimate: estimate,
            repeatDays: repeatDays,
            rate: Rate.Meter,
            type: corporateUser ? Type.Corporate : Type.Dispatch
         })
         if (scheduledTimestamp) {
     
            let Booking:any={};
            Booking.passenger=`${name.firstName} ${name.lastName}`;
            Booking.pickup=pickup.current.value;
            Booking.dropoff=dropoff.current.value;
            Booking.code=data.code;
            setName({
               firstName: "",
               lastName: ""
            })
            setEmail("");
            setDropOffMarker(false);
            setPickupMarker(false);
            setZone(undefined);
            setPickupPlace(undefined);
            setDropoffPlace(undefined);
            setDirections(undefined);
            setDropoffPlaceFormatted(undefined);
            setPickupPlaceFormatted(undefined);
            setPickupLatLng(undefined);
            setDropoffLatLng(undefined);
            setDirections(undefined);
            setTrafficDuration(undefined);
            setScheduledBooking(false);
            setScheduledTimestamp(undefined);
            setRepeatBooking(false);
            setEstimate(undefined);
            setRepeatDays([]);
            setPoint(undefined);
            setLoading(false);
            setDistance(undefined);
            setFindArea(undefined);
            setSendData(true);
            setPhone(undefined);
            setPassenger(undefined);
            pickup.current.value = null
            dropoff.current.value = null
            setBooking(Booking);
         }
         else
            history.push(`/track/${data.code}`)
      } catch (error: any) {
         console.log('Error in Booking:', error.response.data.message);
         alert(`${error.response.data.message}`)
      }

   }
   const onChangeName = (e: any) => {
      let temp = e.target.value;
      let first = temp.split(' ')
      let last;
      first = first[0]
      if (temp.split(' ').slice(-1).join(' ') === first) {
         last = ''
      } else {
         last = temp.split(' ').slice(-1).join(' ')
      }
      setName({
         firstName: first,
         lastName: last
      })
   }

   const handlePhone = async (value: any) => {
      setPhone(value)
      if (value) {
         var { data } = await ApiService.get(`passenger/public/${value}`)
         if (data) {
            setName({
               firstName: data.firstName,
               lastName: data.lastName
            })
            setEmail(data.email)
         }
         else {
            setName({
               firstName: "",
               lastName: ""
            })
            setEmail("")

         }
      }

   }
   return (
      <>
         <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
            <section className="section-yellow home-section" id="home">
               <div className="container">  {corporateData?.companyName ? <h1 style={{ textAlign: 'center' }}>{corporateData?.companyName}</h1> : null}
                  <div className="row">
                     <div className="col-md-5 padding-top-20">
                        {corporateData ? <h1>Corporate Booking</h1> :
                           <>
                              <h1>Sydney Cabs Rejuvenated</h1>
                              <p><i>...and the rest of Australia to follow</i></p>
                              <p>Taxis.Plus heralds a new era of modern taxi service built from the ground up to accomodate your every transport-related need.</p></>}
                        <div className="register-form-wrapper wow bounceIn" data-wow-delay="0.5s">
                           <div>
                              <p className="register_success_box" style={{ display: "none" }}>We received your message and you'll hear from us soon. Thank You!</p>
                              {step == StepType.Personal ? <><form id="register-form" className="register-form register" method="post" onSubmit={onHandleClick}>
                                 <PhoneInput
                                    placeholder="Phone number"
                                    value={phone as any}
                                    onChange={(e) => handlePhone(e)}
                                    id="phone"
                                    required
                                    inputprops={{
                                       name: "phone",
                                       required: true,
                                    }}
                                    defaultCountry="AU"
                                    countries={["AU", "PK"]}
                                    international={true}
                                 />

                                 <Autocomplete
                                    ref={pickupAutocompleteRef}
                                    fields={['geometry', "address_component", "formatted_address"]}
                                    restrictions={{ country: ['au', 'pk'] }}
                                 >
                                    <input ref={pickup} autoFocus className="register-input white-input" required placeholder="Pickup" type="text" />
                                 </Autocomplete>
                                 <Autocomplete
                                    ref={dropoffAutocompleteRef}
                                    fields={['geometry', "address_component", "formatted_address"]}
                                    restrictions={{ country: ['au', 'pk'] }}
                                 >
                                    <input ref={dropoff} className="register-input white-input" required placeholder="Dropoff" type="text" />
                                 </Autocomplete>
                                 <input disabled={phone ? false : true} className={`register-input white-input ${phone ? '' : "bgDisable"}`} required placeholder="Name" type="text" value={name.firstName ? `${name.firstName} ${name.lastName}` : ""} onChange={onChangeName} />
                                 {/* <input className="register-input white-input" required placeholder="Phone" type="tel" onChange={(e: any) => setPhone(e.target.value)} /> */}

                                 <input disabled={phone ? false : true} value={email} className={`register-input white-input ${phone ? '' : "bgDisable"}`} required placeholder="Email" type="email" onChange={(e: any) => setEmail(e.target.value)} />
                                 {serviceId ? <select className="register-input white-input" onChange={onChange} defaultValue={serviceId}>
                                    {options.map((item) =>
                                       <option key={item.id} className="register-input white-input" value={item.id}>{item.title}</option>
                                    )}
                                 </select> : null}

                                 {corporateData ? <>
                                    <div className="form-check" onClick={() => { if (scheduledBooking == false) { setScheduledTimestamp(undefined) } setScheduledBooking(!scheduledBooking) }} style={{ marginBottom: '10px' }}>
                                       <input className="form-check-input" type="checkbox" checked={scheduledBooking} onChange={() => { if (scheduledBooking == false) { setScheduledTimestamp(undefined) } setScheduledBooking(!scheduledBooking); }} style={{ marginTop: '10px', padding: '10px', width: '15px', height: '15px' }} />
                                       <label className="form-check-label" style={{ marginLeft: '5px', fontSize: '20px', fontWeight: '500' }}>
                                          Scheduled Booking
                                       </label>
                                    </div>
                                    {scheduledBooking ?
                                       <input className="register-input white-input" type="datetime-local" onChange={(e) => { setScheduledTimestamp(new Date(e.target.value)); }} name="scheduledTimestamp" /> : null
                                    }
                                    {scheduledBooking ? <>
                                       <div className="form-check" onClick={() => { if (repeatBooking == false) { setRepeatEndTime(undefined) } setRepeatBooking(!repeatBooking) }} style={{ marginBottom: '10px' }}>
                                          <input className="form-check-input" type="checkbox" checked={repeatBooking} onChange={() => { if (repeatBooking == false) { setRepeatEndTime(undefined); setRepeatDays([]) } setRepeatBooking(!repeatBooking) }} style={{ marginTop: '10px', padding: '10px', width: '15px', height: '15px' }} />
                                          <label className="form-check-label" style={{ marginLeft: '5px', fontSize: '20px', fontWeight: '500' }}>
                                             Repeat Booking
                                          </label>
                                       </div>
                                       {repeatBooking ?
                                          <div style={{ marginBottom: '10px' }}><Select
                                             autoFocus
                                             name='repeatDays'
                                             onChange={onChangeDays}
                                             defaultValue={null}
                                             placeholder="Select Days"
                                             isMulti
                                             backspaceRemovesValue
                                             isClearable
                                             isSearchable
                                             hideSelectedOptions
                                             closeMenuOnSelect={false}
                                             options={dayOptions}
                                             rules={{ required: true }}
                                             menuPortalTarget={document.body}
                                             styles={{
                                                menuPortal: (provided) => ({ ...provided, zIndex: 9999, }),
                                                menu: (provided) => ({ ...provided, zIndex: 9999 })
                                             }}
                                          /></div>
                                          : null
                                       }</>
                                       :
                                       null
                                    }</> : null}
                                 {

                                    (estimate && isValidPhoneNumber(`${phone}`)) ? !loading ?
                                          scheduledBooking ? (scheduledTimestamp ?
                                             repeatBooking ? (repeatDays.length > 0 ?
                                                <input value="Book a Taxi" className="register-submit" type="submit" /> :
                                                <button disabled className="register-submit" type="submit" style={{ backgroundColor: "#858585" }} >Book A Taxi</button>) :

                                                <input value="Book a Taxi" className="register-submit" type="submit" /> :
                                             <button disabled className="register-submit" type="submit" style={{ backgroundColor: "#858585" }} >Book A Taxi</button>) :
                                             <input value="Book a Taxi" className="register-submit" type="submit" />
                                          : <button disabled className="register-submit" type="submit" style={{ backgroundColor: "#858585" }} >Processing...</button> :
                                       <button disabled className="register-submit" type="submit" style={{ backgroundColor: "#858585" }} >Book A Taxi</button>}
                              </form>  
                              {booking?<div className="panel panel-success" style={{backgroundColor:'#00c851',padding:'5px',margin:'10px 0px',borderRadius:'10px',boxShadow: '0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)'}}>
                                    <div className="panel-heading" style={{fontWeight:'20px',fontSize:'20px',color:'white',padding: "0.75rem 1.25rem"}}>Your booking has been scheduled.</div>
                                    <div className="panel-body" style={{backgroundColor:'white',padding:'5px',borderRadius:'10px'}}><p><b>Passenger: </b>{booking.passenger}</p><p><b>Pickup: </b>{booking.pickup}</p><p><b>DropOff: </b>{booking.dropoff}</p><button style={{display:'flex',margin:'auto', borderColor:'transparent',borderRadius:'10px',padding:'10px',backgroundColor:'#007bff',color:'white'}} onClick={()=>{history.push(`/track/${booking.code}`)}}><b>Track Booking</b></button></div>
                                 </div>:null}
                              </>
                                 : ""}

                              {step == StepType.OTP ?
                                 <form id="register-form" className="register-form register" method="post" onSubmit={onHandleVerfication}>


                                    <input className="register-input white-input" required placeholder="Code" type="tel" onChange={(e: any) => setVerficationCode(e.target.value)} />


                                    {!loading ? <input value="Verify Code" className="register-submit" type="submit" /> : <input value="Processing..." disabled className="register-submit" type="submit" />}
                                 </form>

                                 : ""}

                              {step == StepType.Stripe ?
                                 <Elements stripe={stripePromise} options={stripeOptions}>
                                    {clientSecret && <CheckoutForm passengerId={passenger?.id as string} bookingCode={booking?.code} clientSecret={clientSecret} createBooking={createBooking} />}
                                 </Elements>
                                 : ""}
                              <p className="register-form-terms"></p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-7 margin-top-20">
                        <div className="main-map">
                           <GoogleMap
                              mapContainerStyle={{ width: '100%', height: 530 }}
                              center={center}
                              zoom={zoom}
                              onLoad={onLoad}
                              onUnmount={onUnmount}
                           >
                              {findArea && findArea.GpsNearBy.length > 0 ? findArea.GpsNearBy.map((area: any) => {
                                 return <Marker key={area.lat} position={{ lat: area.lat, lng: area.lng }} zIndex={1000000000} icon={{
                                    url: car,
                                    scaledSize: size,
                                    anchor: point,
                                    rotation: area.heading
                                 }} visible={true} />
                              }) : null
                              }
                              {!directions && pickupPlace && pickupPlace.geometry && <Marker position={pickupPlace.geometry.location} />}
                              {!directions && dropoffPlace && dropoffPlace.geometry && <Marker position={dropoffPlace.geometry.location} />}
                              {directions && <DirectionsRenderer options={{ directions }} />}
                              {directions && <TrafficLayer />}
                           </GoogleMap>
                        </div>
                        <div className={'estimate' + (distance ? ' show' : '')}>
                           <div className="estimate-box hero">
                              <p>{generalCurrency?.symbol}{estimateText()}</p>
                           </div>
                           <div className="estimate-box">
                              <h4>Distance</h4>
                              <p>{distanceText()} </p>
                           </div>
                           <div className="estimate-box">
                              <h4>Duration</h4>
                              <p>{durationText()}</p>
                           </div>
                           <div className="estimate-box">
                              <h4>Traffic</h4>
                              <p>{trafficText()}</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </LoadScript>
      </>)
}
