import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { ApiService } from "../../service/api.service";
import { authentication } from "../../service/auth.service";
import { Footer } from "../footer/Footer";
import { CheckoutForm } from "../landing/sections/CheckoutForm";
import { RideReport } from "./report/rides.component";
import ReactTooltip from 'react-tooltip';
import { CorporateUser } from "../entities/corporate-user.entity";

interface props {
  id?: string,
  corporateUser:CorporateUser
}
enum StepType {
  Personal = "personal",
  Stripe = "stripe"
}

export function CorporatePayment({ id ,corporateUser}: props) {
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [corporateUserData, setCorporateUserData] = useState<any>(corporateUser);
  const [step, setStep] = useState<StepType>(StepType.Personal);
  const [stripeOptions, setStripeOptions] = useState<any>()
  const [clientSecret, setClientSecret] = useState<string>();
  const stripePromise = loadStripe((process.env.REACT_APP_STRIPE_KEY) as string);
  const [cardsList, setCardsList] = useState([]);
  const [defaultCardStatus,setDefaultCardStatus] = useState(false)

  

  const getCorporateAccount = async () => {
    const { data } = await ApiService.get(`corporateAccount/account/${id ? id : corporateUserData?.corporateAccount?.id}`)
    if(data?.corporate){
    corporateUserData.corporateAccount = data?.corporate}
    let method: any = [];
    if (data?.paymentMethods?.length > 0) {
      // setOptions
      data.paymentMethods.map((item: any) => {
        method.push({
          value: item,
          label: "Credit Card"
        })
      })

    }
    return data;

  }
  useEffect(() => {
    onPageload();
  }, [id])

  useEffect(()=>{

    getCardsList()

  },[defaultCardStatus,corporateUser])

  const getCardsList = async()=>{
    const corporateData = await getCorporateAccount()
    const { data } = await ApiService.get(`corporateAccount/cardsList/${corporateData.id}`)
      setCardsList(data.data)

  }


  const onPageload = async () => {
    try {
      let corporate = await getCorporateAccount()
      const response = await ApiService.patch('corporateAccount/payment/update', {
        paymentMethods: [{ value: 'credit-card', label: 'Credit' }],
        id: corporate.id
      })
      getCardsList()
      if (response) {
        const options = {
          clientSecret: response.data?.clientSecret,
        }
        setStripeOptions(options)
        setClientSecret(response.data.clientSecret)
      }
    } catch (err: any) {
      console.log('Payment Error:', err?.response?.data?.message);
      throw (err)
    }
    // cont 

  }
  const setDefaultCard = async (id: string, defaultCard: string) => {
    try {
      const response = await ApiService.patch('corporateAccount/card/update', {
        defaultCard,
        id
      })
      corporateUserData.corporateAccount=response.data
      setCorporateUserData(corporateUserData)
      setDefaultCardStatus(!defaultCardStatus)
      authentication.onAuthenticationCorporate(corporateUserData);
    }
    catch (error: any) {
      throw error
    }


  }

  const deleteCard = async (id: string, card: string) => {
    try {
      const response = await ApiService.patch('corporateAccount/card/delete', {
        card,
        id
      })
      setDefaultCardStatus(!defaultCardStatus)
    }
    catch (error: any) {
      throw error
    }


  }
  return (
    <>
      <div style={{ margin: 'auto', width: '50%', textAlign: 'center' }}><div className="padding-top-20">
        {cardsList?
        <>
        <h3 style={{ textAlign: 'center', marginBottom: "20px" }}>Payment Methods</h3>
        <div className="card" style={{
          width: "100%",
          marginBottom: "20px"
        }}>

          <ul className="list-group list-group-flush" >
            {cardsList?.map((item: any) => {
              return <li className="list-group-item" style={{ background: "#eedb25" }}>
                <strong style={{ fontSize: "24px", marginLeft: '20px' }}>********{item.card.last4}</strong>
                {item.id === corporateUserData?.corporateAccount?.defaultCard ? <span style={{ float: "right" }} className="badge badge-primary">Default</span> :
                  <>
                    <span onClick={() => deleteCard(corporateUserData?.corporateAccount?.id, item.id)} data-tip="Remove" style={{ float: "right", color: "red", marginLeft: '12px' }} className="os-icon os-icon-trash-2"></span>
                    <span onClick={() => setDefaultCard(corporateUserData?.corporateAccount.id, item.id)} data-tip="Set as Default" style={{ float: "right", color: "green" }} className="os-icon os-icon-settings"></span>

                  </>
                }
                <ReactTooltip />
              </li>
            })}

          </ul>
        </div> </>:""}
        {stripeOptions && stripePromise && clientSecret && corporateUserData?.corporateAccount && <>
          <Elements stripe={stripePromise} options={stripeOptions}>
            {clientSecret && <CheckoutForm clientSecret={clientSecret} corporateUser={corporateUserData} />}
          </Elements>
        </>}
      </div></div>
    </>
  );
}
