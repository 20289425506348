import React from 'react';
import { Footer } from '../../footer/Footer';

export function OctNews(){
    return(
        <>
           <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> October Newsletter </h1>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <h2 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>The Way Forward</h2>

                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">When you are at the bottom of a roller coaster ride, the only direction is up. For over a decade my passion has been a better Taxi system, and a new approach that positively benefits all participants. That starts with the Passengers, pivots on the Drivers, and gives a return to Investors. We now have TAXIS.PLUS</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Our Taxi Industry which used to sustain 65,000,000 Passenger Trips in Sydney annually, utilized over 6,000 Taxis, and engaged about 18,000 Drivers has changed. Now , towards the end of COVID 19 we are working at the rate of less than 10,000,000 Trips, 2,500 Taxis and less than 3,000 Drivers. Bottom of the hole and a long way from the level playing field promised. The only way forward is up. And that is what TAXIS.PLUS now offers. </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Plate Lease Fees are at an all time low. CTP Insurance is coming down on Distance based premiums. And, with TAXI.PLUS, Network Fees are zero. Taxi Operating costs are down about $20,000 and, if passed on and shared with Drivers equates to about $40 a shift. It’s beginning to make it worthwhile operate and drive a Taxi again.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">At long last, we have fully integrated Taxis with Technology. Just by scanning the QR Code, not only is the full sequence of the trip commenced, but the Covid Safe Tracking forwarded to Services NSW – and with Pick-up and Drop-off. Embracing Wi-Fi means the conversion of car into taxi is wireless and costs less. With a new Android Auto screen we leap forward to a system run off a single device. No more junk to confuse and distract, the Driver – and all ‘hands-free’ with Google checking local speed limits. Tips prompted and within the system.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">No more arguments or conflict about ‘long way, wrong way’. Google mapping and an e-mail Tax-Invoice on record. Tolls automated naturally. Easy for a Passenger to re-book their own Driver or his selected Group</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Each participant has access to the relevant parts of the TAXIS.PLUS system: Passenger, Driver, Operator, Group Leader, Fleet Manager and Network Manager as well as Emergency Services, P2P, SIRA and Insurance Companies – but all on a ‘need to know’ basis and not including ‘tips’.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">How much does it cost? Nothing! Fully funded from passenger-pays Transaction Fees and Booking Fees!</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">If you the Operator join our Network, we charge a once only $1500 to cover a new Tablet, Mounting and pre-paid SIM for all data transmission. We provide a new Roof Bar with a 360&deg; Camera as well as an internal Camera. And the Driver and Operator gets back 50c per booking. That is our confidence for the Future.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The Marketing plan is this: Scanning the QR code is a Safety requirement, which doubles as the entry point to TAXIS.PLUS and Payments and enables every Passenger to join us and use us as the preferred next trip provider. We neither need nor can afford the expense of a mass-marketing campaign. We have social media instead.</p>
                        </div>
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Michael Jools</th>
                                    <th scope="col">TAXIS.PLUS</th>
                                    <th scope="col"><a href="!#">www.taxis.net.au</a></th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href="!#">www.taxis.plus</a></td>
                                    <td>04 1927 2744</td>
                                    <td></td> 
                                </tr> 
                            </tbody>
                        </table>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Fully operational December 2020</p>
                        </div>
                    </div>
                     
                </div>
            </section>
            <Footer />
        </>
    )
}