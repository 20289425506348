import React, { useEffect, useState } from 'react';
import { ApiService } from '../../../service/api.service';
import { OperatorFormSection, OperatorFormSectionProps } from '../Section';


export interface OperatorConsent {
    id: string,
    operatorUseOfInformation: string,
    operatorAgreement: string,
}

export function OperatorFormAgreement(props: OperatorFormSectionProps) {
    const [consent, setConsent] = useState<string>();
    const [consentId, setConsentId] = useState<string>();
    const { register } = props;

    useEffect(() => {
        refresh();
    }, [])

    const refresh = async () => {
        const response = await ApiService.get('operatorConsent/public/currentConsent');
        const data: OperatorConsent = response.data;
        setConsent(data.operatorAgreement);
        setConsentId(data.id)
    }
    return (
        <OperatorFormSection {...props}>
            <>
                <div className="container text-justify">
                    <h1 className="padding-top-20">Operator Agreement</h1>
                    <p className="mb-30" style={{ whiteSpace: 'pre-wrap' }}>
                        {consent}
                    </p>
                </div>
                <input
                    ref={register({ required: true })}
                    name="consent"
                    type="hidden"
                    value={consentId}
                />
                <p><b>By clicking the "Next" button below, you consent to the agree "Operator Agreement" above.</b></p>
                <input
                    value='Next'
                    className="register-submit"
                    type="submit"
                />
            </>

        </OperatorFormSection>
    );
}