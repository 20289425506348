import React, { Children, cloneElement, PropsWithChildren, ReactElement } from "react";
import { UseFormMethods } from 'react-hook-form';

interface Props {
  show: boolean;
  onSubmit?: () => void,
}

export type DriverFormSectionProps = UseFormMethods & Props;

export function DriverFormSection(props: PropsWithChildren<DriverFormSectionProps>) {

  const { show } = props;
  const children: ReactElement[] = props.children as ReactElement[];

  const getClassNames = (): string => {
    const classNames: string[] = ['form-section'];

    if (show) {
      classNames.push('active');
    }

    return classNames.join(' ');
  }

  const FormSection = Children.map(children, (child: ReactElement) => cloneElement(child, props));

  return (

    <div className={getClassNames()}>
      { FormSection}
    </div>

  );
}