import React, { useEffect } from 'react';
import { Home } from './sections/Home';
import { About } from './sections/About';
import { Features } from './sections/Features';
// import { Drivers } from './sections/Drivers';
import { Newsletter } from './sections/Newsletter';
import { Footer } from '../footer/Footer';
import { authentication } from '../../service/auth.service';

export function LandingPage() {



    return (
        <>
            <Home />
            {!authentication.getCorporateUser() ? <>   <About />
                <Features />
                {/* <Drivers/> */}
                <Newsletter />
                <Footer /></> : <Footer />
            }
        </>
    )
}
