import React from 'react';
import img1 from './../../../img/passenger.png';
import img2 from './../../../img/driver.png';
import img3 from './../../../img/operator.png';
import img4 from './../../../img/play-store.png';
import img5 from './../../../img/app-store.png';
import { Link } from 'react-router-dom';

export function About() {
   return (
      <section className="section-black section-top-border" id="about">
         <div className="services-wrapper">
            <div className="container">
               <div className="row">
                  <div className="col-md-4">
                     <div className="main-services">
                        <img src={img1} alt="Passenger" className="main-img" />
                        <h3>Passengers</h3>
                        <p>Download our easy-to-use app on Android or iOS phones to start booking a taxi right away</p>
                        <a className="store-btn android" target='_blank' href={`${process.env.REACT_APP_GOOGLE_PLAYSTORE_URL}`}><img src={img4} alt="play store" /></a>
                        <a className="store-btn ios" target='_blank' href={`${process.env.REACT_APP_APPLE_APPSTORE_URL}`}><img src={img5} alt="app store" /></a>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="main-services">
                        <img src={img2} alt="Driver" className="main-img" />
                        <h3>Drivers</h3>
                        <p>Register as a Driver and join our new Network with the single Driver friendly rule of Job acceptance equals Job performance</p>
                        <Link to="/drivers/" className="btn-yellow scrool">Register</Link>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="main-services">
                        <img src={img3} alt="Operator" className="main-img" />
                        <h3>Operators</h3>
                        <p>Obtain maximum returns from your Taxis with our algorithms and control of your Drivers and Taxis</p>
                        <Link to="/operators/" className="btn-yellow scrool">Register</Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
} 