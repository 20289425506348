import axios, { AxiosResponse } from 'axios';
import React, { BaseSyntheticEvent, FormEvent, ReactElement, useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { ApiService } from '../../service/api.service';
import { Footer } from '../footer/Footer';
import { DriverAgreement } from './Agreement';
import { DriverFormBank } from './forms/Bank';
import { DriverFormBasic } from './forms/Basic';
import { DriverFormBusiness } from './forms/Business';
import { DriverFormConsent } from './forms/Consent';
import { DriverFormAgreement } from './forms/Agreement';
import { DriverFormDone } from './forms/Done';
import { DriverFormEmergency } from './forms/Emergency';
import { DriverFormFeatures } from './forms/Features';
import { DriverFormFleet } from './forms/Fleet';
import { DriverFormLicense } from './forms/License';
import { DriverFormNetwork } from './forms/Network';
import { DriverFormPersonal } from './forms/Personal';
import { DriverFormPic } from './forms/Pic';
import { DriverFormError } from './forms/Error';
import { DriverFormSaving } from './forms/Saving';
import { DriverFormVerification } from './forms/Verification';
import { DriverSteps } from './Steps';
import { DriverFormOperator } from './forms/operator';

export interface DriverStep {
  title: string;
  view: DriverViewState;
  description: string;
  fields: string[];
  completed?: boolean;
}

export enum DriverViewState {
  Basic,
  Personal,
  Business,
  Emergency,
  License,
  Bank,
  Pic,
  Verification,
  Fleet,
  Network,
  Operator,
  Features,
  Review,
  Agreement,
  Consent,
  Saving,
  Done,
  Error,
}

const STEPS: DriverStep[] = [
  
  {
    title: 'License',
    view: DriverViewState.License,
    description: 'Provide your License information',
    fields: ['licenseNumber', 'licenseExpiryDate'],
  },
  {
    title: 'Profile Picture',
    view: DriverViewState.Pic,
    description: 'Upload your profile picture which will be visible publicly to passengers',
    fields: ['newPicFile'],
  },
  {
    title: 'Verification Documents',
    view: DriverViewState.Verification,
    description: 'Submit verification documents for our approval process',
    fields: ['newIdentityFiles', 'newVevoFiles','newMedicareCardFiles','newEnglishLanguageProficiencyFiles', 'newPoliceFiles', 'newHistoryFiles'],
  },
  {
    title: 'Agreement',
    view: DriverViewState.Agreement,
    description: 'Review and consent to the driver agreement',
    fields: [],
  },
  {
    title: 'Use of Information',
    view: DriverViewState.Consent,
    description: 'Review and consent to the driver use of information statement',
    fields: [],
  },
  // {
  //   title: 'Review',
  //   view: DriverViewState.Review,
  //   description: 'Review our application prior to submitting',
  //   fields: [],
  // },
];

export function ImportDriver(props: any) {
    const [selectedDriver,setSelectedDriver] = useState<any>();
  const getDriver=async ()=>{
  const {data} = await ApiService.get<any, any>(`driver/code/${props.match.params.code}`);
  setSelectedDriver(data)
  const {reset} = formProps
  reset(data)
}
  useEffect(() => {
    getDriver()
}, [])
  const formProps = useForm();

  const [view, setView] = useState(DriverViewState.License);
  const [steps, setSteps] = useState(STEPS);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [ip,setIP] = useState<any>();


  const renderForm = (): ReactElement =>
    <>
     
      <DriverFormLicense show={view === DriverViewState.License} {...formProps} />
      <DriverFormPic show={view === DriverViewState.Pic} {...formProps} />
      <DriverFormVerification show={view === DriverViewState.Verification} {...formProps} />
      <DriverFormAgreement show={view === DriverViewState.Agreement} {...formProps} />
      <DriverFormConsent show={view === DriverViewState.Consent} {...formProps} />
      <DriverFormSaving show={view === DriverViewState.Saving} {...formProps} />
      <DriverFormDone show={view === DriverViewState.Done} {...formProps} />
      <DriverFormError show={view === DriverViewState.Error} {...formProps} />
    </>

  const handleNext = () => {
    nextStep();
  }

  const handleError = (errors: FieldErrors, e?: BaseSyntheticEvent) => {
    const currentStep = steps.find(step => step.view === view);
    const errorMessages: string[] = [];
    for (let [key, value] of Object.entries(errors)) {
      const { ref } = value;
      // Only show errors for the current step's fields
      if (currentStep?.fields.includes(key)) {
        if (ref.placeholder === undefined) { errorMessages.push(`Following Field is ${value.type}`); }
        else { errorMessages.push(`${ref.placeholder} ${value.type}`); }
      }
    }
    if (errorMessages.length > 0) {
      setErrorMessage(errorMessages.join(', '))
      return;
    } else {
      setErrorMessage(undefined);
      handleNext();
    }
  }

  const nextStep = (e?: FormEvent<HTMLFormElement>) => {
    const currentStepIndex = steps.findIndex(step => step.view === view);
    if (currentStepIndex === -1) {
      throw new Error('While next stepping driver form, step missing for view');
    }

    // Mark current step as completed
    const currentStep = steps[currentStepIndex];
    currentStep.completed = true;
    const updatedSteps = [...steps];
    updatedSteps[currentStepIndex] = currentStep;
    setSteps(updatedSteps);

    const nextStep = steps[currentStepIndex + 1];
    // If we're on the last step, submit the form
    if (!nextStep) {
      submit();
    } else {
      setErrorMessage(undefined);
      setView(nextStep.view);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    e && e.preventDefault()

    return false;
  }

  const setStep = (step: DriverStep) => {
    setView(step.view);
  }

  useEffect(() => {
    getIp();
  }, []);

  const getIp = async () => {
    const res: any = await axios.get("https://api.db-ip.com/v2/free/self");
    setIP(res.data.ipAddress);
  };


  const submit = async () => {
    setView(DriverViewState.Saving)
    setErrorMessage(undefined);
    try {
      let data:any = formProps.getValues();
     
      data.id = selectedDriver?.id
      data.ip = ip
      const driver = await ApiService.post<any, AxiosResponse<any>>('driver/importRegister', data);
      const uploadData = new FormData();
      uploadData.append('id', driver.data.id);
      uploadData.append('picFile', data.newPicFile);
      if(data.newMedicareFiles){
      data.newMedicareFiles.forEach((file: any) => {
        uploadData.append("medicareFiles", file, file.name);
      });
    }
    if(data.newProficiencyFiles){
      data.newProficiencyFiles.forEach((file: any) => {
        uploadData.append("proficiencyFiles", file, file.name);
      });
    }
      if (data.newLicenseFiles) {
        data.newLicenseFiles.forEach((file: any) => {
          uploadData.append("licenseFiles", file, file.name);
        });
      }

      if (data.newIdentityFrontFile) {
       
        uploadData.append("identityFrontFile", data.newIdentityFrontFile);
      
    }
    if (data.newIdentityBackFile) {
       
      uploadData.append("identityBackFile", data.newIdentityBackFile);
    }
      if (data.newInsuranceFiles) {
        data.newInsuranceFiles.forEach((file: any) => {
          uploadData.append("insuranceFiles", file, file.name);
        });
      }
      if (data.newHistoryFiles) {
      data.newHistoryFiles.forEach((file: any) => {
        uploadData.append("historyFiles", file, file.name);
      });
    }
    if (data.newPoliceFiles) {
      data.newPoliceFiles.forEach((file: any) => {
        uploadData.append("policeFiles", file, file.name);
      });
    }
      if (data.newVevoFiles) {
        data.newVevoFiles.forEach((file: any) => {
          uploadData.append("vevoFiles", file, file.name);
        });
      }
      
      await ApiService.post('driver/register/upload', uploadData, { timeout: 0 });
      setView(DriverViewState.Done)
    } catch (err:any) {
      setErrorMessage(err?.message);
      setView(DriverViewState.Error);
    }
  }

  

  return (
    <>
      <section className="section-yellow home-section" id="home">
        <div className="container">
          <div className="row">
            <div className="col-md-5 padding-top-20">
              <h1 className="margin-top-10">Register as a Driver</h1>
              <p>You will need to upload the following images:<br />
              - A Selfie <br />
              - Scan of Driver License (front an back) <br />
              - ID Proof <br />
              - Police Check <br />
              - Driving History <br />
              - Insurance Details <br />
              </p>
              <form id="formElem" onSubmit={formProps.handleSubmit(handleNext, handleError)}>
                {errorMessage && <div className="error">{errorMessage}</div>}
                {renderForm()}
              </form>
            </div>
            <div className="col-md-7">
            {view === DriverViewState.License
                ? <DriverAgreement />
                : <DriverSteps steps={steps} currentView={view} setStep={setStep} />
              }
              {/* <DriverFormVerification show={view === DriverViewState.Verification} {...formProps}/> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
} 