import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { ApiService, PaxApiService } from '../../service/api.service';
import { authentication } from '../../service/auth.service';
import { Footer } from '../footer/Footer';



export const CorporateWelcome = () => {

   const [recoveryCode, setRecoveryCode] = useState<string>();
   const [password, setPassword] = useState<string>(); 
   const [errorMessage, setErrorMessage] = useState<string>("");
    useEffect(()=>{

    })
    const onHandleClieck = async (e: any) => {
      setErrorMessage("")
      e.preventDefault()
      try {
        const response =  await ApiService.post('corporateUser/passwordRecovery',{
            recoveryCode,
            password
          })

          if(response){
            // navbarShow = false
            // authentication.onAuthentication(response.data.passenger)  
            window.location.replace('/corporate/login/')
          }

        //   passenger = response.data.passenger  
      } catch (err:any) {
      setErrorMessage(err?.response?.data?.message)
          throw(err)
      }
    } 

    return (
        <>
        <section style = {{paddingBottom:160}} className="section-yellow section-bottom-border feature-section" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 padding-top-20">
                        <h1>Welcome ! Please Enter Your Password</h1>
                        <div className="register-form-wrapper wow bounceIn" data-wow-delay="0.5s">
                            <div>
                                <p className="register_success_box" style={{ display: "none" }}>We received your message and you'll hear from us soon. Thank You!</p>
                                <form id="register-form" className="register-form register" method="post" onSubmit={onHandleClieck}>  
                                        <input autoFocus className="register-input white-input" required placeholder="Recovery Code" type="number" onChange={(e: any) => { setErrorMessage("");setRecoveryCode(e.target.value)}} /> 
                                        <input className="register-input white-input" required placeholder="Enter Password" type="password" onChange={(e: any) => {setErrorMessage("");setPassword(e.target.value)}} />
                                    <div style={{textAlign:"center"}}>
                                    {errorMessage && <h4 style={{ color: 'red' }}>{errorMessage}</h4>}
                                    </div>
                                    <input style={{marginTop:"13px"}} value="Submit" className="register-submit" type="submit" />
                                </form>
                                <p className="register-form-terms"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </>
    )
}