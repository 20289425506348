import React from 'react';
import { Footer } from '../footer/Footer';

const FareStyle = {
    Cell: { border: '2px solid #000000' },
    HeaderRow: { backgroundColor: "#000", color: "#e8f71c", border: "2px solid #000" },
    CellText: { color: "#292929", fontSize: 18 },
}

export function Fares() {
    return (
        <>
            <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>TAXI FARES IN NSW</h1>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Fares for Rank or Hail Taxi Trips are regulated in NSW to a maximum Fare based on Distance Travelled and on Time taken at speeds less than 26 KMS per hour, plus a Flag-fall or Hiring Charge. Tolls are payable and a Cleaning Fee may be charged if you soil the Taxi. The Distance rate is an extra 20% for trips between 10:00 pm and 6:00 am. If you are five or more travellers the regulated rate has a 50% surcharge. Maxi or Van Rates. Country Rates are different to Metropolitan Rates. The table shows these rates, and they are the maximum rates chargeable for Rank and Hail trips.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Fares for Booked Trips are no longer Regulated, and an Estimate MUST be provided and accepted before a trip starts. TAXIS.PLUS has made this an important part of our Booking Service, but we are aware that not all Booking Services do this. Our Booked Rates are based on the Rank and Hail Rates plus a Booking Fee of $4.00 and the estimate will include the Levy, Tolls and an assumption of card payment.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">On occasions of localised peak demand, and short supply we may suggest car-pooling or multiple hire arrangements, to reduce shared hire costs; and we may put a supplementary geofenced extra fee on required single hirings. We do not have multiple percentage "surge pricing", and we don't exaggerate our competitor's prices.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">So called "RideSharing" fares used to be cheaper (not counting "surge pricing"), thanks to Billions of Investor Dollars subsidizing passenger trips. Now, and according to National Media reporting, the free lunches are over. Changes to the “gig economy” IR practises and the recent Settlement by Uber of $272 M in a Class action will even out Fares.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Taxi Fares went up by 10 cents a kilometre in January 2023, the first increase in ten years. Everything else has gone up with Inflation, but not the fares of Taxis. What has happened, however, is that the cabbie still earns about $10.00 per hour on a twelve-hour shift. We are required to charge the Government Regulated Fare, and can charge a bit more on a Booked Fare. We want the Driver to get "a fair share of a fair fare".</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">When IPART, the NSW Independent Pricing and Regulatory Tribunal determines that Taxi Drivers are earning the National Minimum Wage, we will reduce fares. Until then, we will lift Driver earnings by improved Occupancy and Utilization. More Jobs per Shift and More Taxis on the Road. And a bit more on Booked trips.</p>
                        </div>
                    </div>
                    <div className="table-responsive"> 
                        <table className="table table-bordered">
                            <thead>
                                <tr style={ FareStyle.HeaderRow }>
                                    <th style={{ ...FareStyle.Cell, textAlign: 'center' } } scope="col">Metropolitan</th>
                                    <th style={{ ...FareStyle.Cell, textAlign: 'center' } } scope="col">Price (AUD)</th>
                                    <th style={{ ...FareStyle.Cell, textAlign: 'center' } } scope="col">Day/Time</th>
                                    <th style={{ ...FareStyle.Cell, textAlign: 'center' } } scope="col">Country</th>
                                    <th style={{ ...FareStyle.Cell, textAlign: 'center' } } scope="col">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>Hiring Charge</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$3.60</td>
                                    <td style={ FareStyle.Cell }></td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$4.10</td>
                                    <td style={ FareStyle.Cell }></td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Peak Hiring Charge</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$2.50</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Friday, Saturday, Pre-Public Holiday, Nights</td>
                                    <td style={ FareStyle.Cell }></td>
                                    <td style={ FareStyle.Cell }></td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Distance Rate - Day</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$2.29 / KM</td>
                                    <td style={ FareStyle.Cell }> </td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$2.36 / KM</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$3.23 if {'>'} 12 KM</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Distance Rate - Night</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$2.73 / KM</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>10.00 pm - 6.00 am</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$2.81 / KM</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$3.85 if {'>'} 12KM</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Waiting Time</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>94.4c / Min</td>
                                    <td style={ FareStyle.Cell }> </td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>96c / Min</td>
                                    <td style={ FareStyle.Cell }></td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>MAXI Rate</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }} colSpan={2}>150% Of total Fare<br/> $120.00 Cleaning Fee is the maximum charge if you soil the Taxi</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Multiple Hire</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>75% Of Total Fare</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                        <p className="section-subtitle"><b><i>Victoria, Queensland, South Australia, Western Australia, Tasmania, Northern Territory, Australian Capital Territory -- Coming Soon</i></b></p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
