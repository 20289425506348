import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { ApiService } from '../../service/api.service';
import { Footer } from '../footer/Footer';

interface Article {
    id: string,
    title: string,
    excerpt: string,
    description: string,
    slug: string,
}

export const News = () => {

    const [articleData, setArticleData] = useState<Article[]>();

    useEffect(() => {
        refresh();
    }, [])

    const refresh = async () => {
        try {
            const response = await ApiService.get('article/public/list');
            setArticleData(response.data);
        } catch (err) {
            console.log('Failed to load fleet list', err);
        }
    }

    return (
        <>
            <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    {articleData?.map((item) => {
                        return (<div className="row" key={item.id}>
                            <div className="col-md-12 padding-bottom-10" key={item.id}>
                                <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>
                                    <Link to={`/news/${item.slug}`}>{item.title}</Link>
                                </h1>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">{item.excerpt}... <Link to={`/news/${item.slug}`}>read more</Link></p>
                            </div>
                        </div>)
                    })}
                </div>
            </section>
            <Footer />
        </>
    )
} 