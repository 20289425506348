import React from 'react';
import { Footer } from '../../footer/Footer';

export function JulyNews(){
    return(
        <>
            <section className="section-yellow section-bottom-border feature-section"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-bottom-10">
                                <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> July Newsletter </h1>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h2 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>GETTING BACK ON THE ROAD</h2>
                                <h2 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>START UP YOUR TAXI WITH <a href="!#">WWW.TAXIS.PLUS</a></h2>

                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Now that we are all getting ready to get back on the road, whether we want to or not, but because have to feed and house the Family, and because there is no other option, you as a Taxi Driver need to start Planning.</p>
                            </div> 
                            <div className="col-md-12 padding-bottom-10">
                                <ol className="mb-20" style={{ fontSize: "20px", color: '#858585' }}> 
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Your own Safety:</h5>
                                        <p className="section-subtitle">Plan on wearing a quality Mask when you have a Passenger. Use the Air Conditioning on External Air and leave a rear window down a bit – all the time. Spray and Wipe after each Passenger. </p>
                                    </li> 
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>The Passenger’s Safety : </h5>
                                        <p className="section-subtitle">Try to limit to Two Passengers, and both in the Back Seat. Give a standard Mask and ask or require that they wear it. Add it to the fare as an Extra.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>The Taxi’s Safety :</h5>
                                        <p className="section-subtitle">Spray and Wipe. Spray and Wipe. Spray and Wipe. Get the Taxi Sanitized once a day. There will be a Sanitization bay at the Domestic Airport.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Negotiate a new Pay-In with your Operator / Bailor :</h5>
                                        <p className="section-subtitle">Our Taxi business is going to take a long time to recover – at least into 2021. We suggest a 25 / 75 % split, with the Driver, as a Casual, paying Fuel and Wash. </p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Join TAXIS.PLUS as a Driver :</h5>
                                        <p className="section-subtitle">it’s free - and use your own Smartphone for all Credit Card transactions. Its cheaper for your Passengers and it will bring them onto TAXIS.PLUS as their Booking Service. This is the best way to break the 13 Cabs stranglehold on the market. Start both devices, and then close your Network meter, so it shows that you have started the trip.</p>
                                    </li>
                                    <li> 
                                        <p className="section-subtitle">When you are happy with the work you are getting from TAXIS.PLUS just advise your Operator to change to us and save $$$$. If you are the Operator and would like Network Fees of $100 a month for the rest of 2020, and then $200 for 2021, register with TAXIS.PLUS now. Get 50c a Booked Trip rebate.</p>
                                    </li>
                                    <li> 
                                        <p className="section-subtitle">Drive Safely and with consideration for other road users. You are the Professional Driver. Set an example and re-develop the Respect for and Self Respect of a post-COVID 19 Taxi Industry and the Safest Transport System.</p>
                                    </li>  
                                </ol>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The only thing we can all be confident about is that our whole World has changed, and that we have to work in a "new normal". An overdue change is the necessity of increased Productivity which for a Taxi is more jobs per shift. We never could, and now absolutely cannot, afford to travel Vacant for 65% of the Time or Kilometres. We need a smarter Booking Service and Network. We must have reduced Plate Lease Fees, Network Fees and Insurance Charges. We have to get our Passengers back on board. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">TAXIS.PLUS is the answer. </p>
                            </div>
                        </div>
                    </div>
            </section>
            <Footer />
        </>
    )
}