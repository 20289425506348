import React, { useEffect } from 'react';
import { DriverFormSection, DriverFormSectionProps } from '../Section';

export function DriverFormSaving(props: DriverFormSectionProps) {

  useEffect(() => {
    if (!props.show) {
      return
    }
  }, [props.show]);

  return (
    <DriverFormSection {...props}>
      <div className="registering">
        <p>Please wait...</p>
        <p>Your profile is being submitted for approval.</p>
      </div>
    </DriverFormSection>
  );
}