import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { ApiService, PaxApiService } from '../../service/api.service';
import { authentication } from '../../service/auth.service';
import { Footer } from '../footer/Footer';
import { CorporatePayment } from './corporatePayment';



export const CorporatePaymentMethod = () => {
    const [corporateUser, setCorporateUser] = useState<any>(authentication.getCorporateUser());
    useEffect(() => {
        setCorporateUser(authentication.getCorporateUser());
    },[localStorage.getItem("corporate")])
    return (
        <>
        <section style = {{paddingBottom:160}} className="section-yellow section-bottom-border feature-section" id="features">
            <div className="container">
      <h1 style={{ textAlign: 'center' }}>{authentication.getCorporateUser().corporateAccount?.companyName}</h1>
                <div className="row">
                    <div className="col-md-12 padding-top-20">
                        <div className="register-form-wrapper wow bounceIn" data-wow-delay="0.5s">
                            <div>
                                <CorporatePayment id={corporateUser.corporateAccount.id} corporateUser={corporateUser}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </>
    )
}