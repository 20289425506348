export enum ModuleName {
  Accounting = 'accounting',
  Dashboard = 'dashboard',
  Dispatch = 'dispatch',
  Article = 'article',
  Driver = 'driver',
  Fare = 'fare',
  Feature = 'feature',
  Fleet = 'fleet',
  Network = 'network',
  Group = 'group',
  Operator = 'operator',
  Passenger = 'passenger',
  Security = 'security',
  Settings = 'settings',
  Support = 'support',
  User = 'user',
  Vehicle = 'vehicle',
  Inspection = 'inspection',
  Incident = 'incident',
  Subscriber = 'subscriber',
  Zone = 'zone',
  Service = 'service',
  Emergency = 'emergency'
}

export enum FormMode {
  Adding,
  Editing,
}

export enum BookingFilter{
  Booking = "booking",
  OnGoingBooking = 'onGoingBooking',
  ScheduledBooking = 'scheduledBooking',
  CorporateUser = 'corporateUser' 
}
