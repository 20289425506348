import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter,
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import { About } from './component/about/About';
import { Contact } from './component/contact/Contact';
import { Drivers } from "./component/drivers/Driver";
import { DriverRegister } from './component/drivers/Register';
import { Fares } from './component/fares/Fares';
import { LandingPage } from './component/landing/Landing';
import { Links } from "./component/links/Links";
import { Navbar } from './component/navbar/Navbar';
import { News } from './component/news/News';
import { AugNews } from "./component/news/sections/AugNews";
import { NewsDetail } from "./component/news/sections/NewsDetail";
import { JulyNews } from './component/news/sections/JulyNews';
import { NovNews } from "./component/news/sections/NovNews";
import { OctNews } from "./component/news/sections/OctNews";
import { Safety } from './component/news/sections/Safety';
import { Security } from './component/news/sections/Security';
import { SeptNews } from "./component/news/sections/SeptNews";
import { Standards } from './component/news/sections/Standards';
import { TaxiDrivers } from './component/news/sections/TaxiDrivers';
import { Operators } from './component/operators/Operator';
import { OperatorRegister } from './component/operators/Register';
import { Page404 } from './component/page404/page404';
import { Passengers } from "./component/passenger/Passenger";
import { PrivacyPolicy } from './component/privacy/Privacy';
import { ScrollToTop } from "./component/scroll/Scroll";
import { TermAndCondition } from './component/terms/Terms';
import './css/animate.css';
import './css/style.css';
import * as serviceWorker from './serviceWorker';
import { UnsubscribeEmail } from "./component/email/unsbscribe.component";
import { Tracking } from "./component/track/track";
import { PassengerLogin } from "./component/passenger/passengerLogin";
import { PassengerRoute } from "./component/routes/passengerRoute";
import { PassengerDetail, tripIds } from "./component/passenger/passengerDetails";
import { TripReceipt } from "./component/passenger/tripReceipt";
import { ImportDriver } from "./component/drivers/ImportDriver";
import { authentication } from "./service/auth.service";
import { PassengerLogout } from "./component/passenger/passengerLogout";
import { CorporateLogin } from "./component/corporate/corporateLogin";
import { CorporateReset } from "./component/corporate/corporateReset";
import { CorporateWelcome } from "./component/corporate/corporateWelcome";
import { CorporateAccountList } from "./component/corporate/corporateAccount";
import { CorporatePayment } from "./component/corporate/corporatePayment";
import { CorporatePaymentMethod } from "./component/corporate/corporatePaymentMethod";
import { CorporateRoute } from "./component/routes/corporateRoute";
import { PublicRoute } from "./component/routes/publicRoute";
import { BookingReport } from "./component/corporate/bookingReport";
import { ScheduleBookingReport } from "./component/corporate/scheduleBookingReport";
import { CorporateUser } from "./component/corporate/corporateUser";

export default function App() {
    const [passenger, setPassenger] = useState<any>(authentication.getPassengerUser() ? true : false);
    const [corporate, setCorporate] = useState<any>(authentication.getCorporateUser() ? true : false);
    const [corporateUser, setCorporateUser] = useState<any>(authentication.getCorporateUser());
    const handlePassengerLogout = () => setPassenger(false);
    const handleCorporateLogout = () => setCorporate(false);

    useEffect(() => {
        setCorporateUser(authentication.getCorporateUser());
    },[localStorage.getItem("corporate")])
    return (
        <Router>
            <>
                <ScrollToTop />
                <Navbar isPassenger={passenger} isCorporate={corporate} corporateUser={corporateUser} />
                <Switch>
                    <Route exact path='/' component={LandingPage} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/about/' component={About} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/fares/' component={Fares} />
                    {/* <Route exact path='/passengers' component={Passengers} /> */}
                    {/* <Route exact path='/drivers' component={Drivers} />
                    <Route exact path='/drivers/register' component={DriverRegister} />
                    <Route exact path='/drivers/import/:code' component={ImportDriver} /> */}
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/operators/' component={Operators} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/operators/register/' component={OperatorRegister} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/contact/' component={Contact} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/links/' component={Links} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/' component={News} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/taxi-security-system/' component={Security} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/taxi-standards/' component={Standards} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/safety-management-system/' component={Safety} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/all-sydney-taxi-drivers/' component={TaxiDrivers} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} path='/news/:article/' component={NewsDetail} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/nov-news/' component={NovNews} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/oct-news/' component={OctNews} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/sept-news/' component={SeptNews} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/aug-news/' component={AugNews} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/news/july-news/' component={JulyNews} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} path='/mail/unsubscribe/:id/' component={UnsubscribeEmail} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/privacy-policy/' component={PrivacyPolicy} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/terms-conditions/' component={TermAndCondition} />
                    <Route exact path='/track/:code' component={Tracking} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/login/' component={PassengerLogin} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/corporate/login/' component={CorporateLogin} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/corporate/password-reset/' component={CorporateReset} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/corporate/welcome/' component={CorporateWelcome} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/passengers/' component={Passengers} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/drivers/' component={Drivers} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/drivers/register/' component={DriverRegister} />
                    <PublicRoute isCorporate={corporate} isPassenger={passenger} exact path='/drivers/import/:code/' component={ImportDriver} />
                    <Route exact path='/logout/' render={(rest) => {
                        return <PassengerLogout {...rest} handlePassengerLogout={handlePassengerLogout} handleCorporateLogout={handleCorporateLogout} />
                    }} />

                    <PassengerRoute isPassenger={passenger} path='/passenger-details' component={PassengerDetail}></PassengerRoute>
                    <PassengerRoute isPassenger={passenger} path={`/trip-details/${tripIds}`} component={TripReceipt}></PassengerRoute>

                    <CorporateRoute isCorporate={corporate} exact path='/transaction/list/' component={CorporateAccountList} />
                    <CorporateRoute isCorporate={corporate} exact path='/corporate/scheduled/' component={ScheduleBookingReport} />
                    <CorporateRoute isCorporate={corporate} exact path='/corporate/payment/' component={CorporatePaymentMethod} />
                    <CorporateRoute isCorporate={corporate} exact path='/corporate/booking/' component={BookingReport} />
                    <CorporateRoute isCorporate={corporate} exact path='/corporate/users/' component={CorporateUser} />
                    <Route exact path='*' render={(data)=>(<Page404 {...data} isCorporate={corporate} isPassenger={passenger}/>)} />

                </Switch>

            </>
        </Router>
    );
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

serviceWorker.unregister();
