import React from 'react';
import {Footer} from '../../footer/Footer';

export function Safety(){
    return(
        <>
           <section className="section-yellow section-bottom-border feature-section"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-bottom-10">
                                <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> Safety Management System </h1>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The fundamental premises of the TAXIS.PLUS Safety management System are the integrated application and on-going use of the our System both in the function of a Booking Service and as a Taxi Service Providor. The innovative Security System uses data from inside and outside the vehicle and is initiated at the Driver sign-on to the Service Provider. Many of the traditional and effectively stand-alone requirements have been incorporated into the one, end-to-end, “sign-on /sign-off” sequence such that data is supplied, confirmed and recorded for a variety of end usage. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The initial registration of Drivers for the Booking Service and of Vehicles affiliating to the Taxi Service requires input of verifiable and verified data to fully support that registration, and unless the data is current and appropriate, neither driver nor Vehicle can start operations. Movement of a non-compliant vehicle or by an unauthorised Driver is flagged and reported. Consent for intervention by an authorised officer of the Service is a part of that Registration. At the initial period of Operation, the sole Authorised person responsible for all matters is Michael Jools, and as Operations expand other authorised persons will be nominated and advised for other areas of responsibility. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Subject to on-going assessment of our System, we are satisfied that we are effecting and maintaining a safe workplace environment, and one in which a sudden and unpredictable risk or danger situation is able to be immediately, and in real time, addressed. We are aware that the measures adopted go far beyond specified requirements, which are , to our mind, inadequate. The Standards now set will bring the Industry forward.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Risk Management</h5>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The identifiable risks addressed in our SMS are :</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <ul className="mb-20" style={{ fontSize: "20px", color: '#858585' }}>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>High Risk Issues – Violence</h5>
                                        <p className="section-subtitle">Violence, actual or apprehended, is a very real risk to Drivers in their workplace. That violence can range from verbal abuse to murder, and virtually every NSW taxi driver experiences multiple instances of workplace violence each year. The Duress and Security systems installed to our networked taxis have been designed not only to record, store and make accessible all that occurs in and around the Taxi, ( to authorised persons ) but also to display, on demand, internally, the video being recorded. The duress / alarm signal with two levels of alert goes out instantly to up to four recipients – 000, the Network Call Centre, the Operator, and another nominated person.</p>
                                        <p className="section-subtitle">The intent and purpose of this system is to minimise actual or apprehended violence. Every occurrence is logged and investigated. All Drivers are to be made aware, and trained, in the use of the Security System.</p>

                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Psychological Risk</h5>
                                        <p className="section-subtitle">The psychological risks arising from assaults and accidents, are both complex and unique to each Driver. One of the duties and responsibilities of the Driver Manager , or his team, is to counsel Drivers and advise the need for professional assistance as appropriate. The unusual legal relationship between an Affiliated Taxi Service Provider and a Driver render it not possible to mandate counselling. </p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Driver Distraction</h5>
                                        <p className="section-subtitle">In order to maintain Safe Driving behaviour, the Driver must have as few potential distractions as possible. Road and traffic conditions are a constant, which require attention and concentration as to safe driving. Passengers are a major and necessary distraction and our training is to advise Drivers to pull over and get clear instructions from Passengers before proceeding. Maintaining a legal speed is a responsibility and a distraction and we advise the use, in background mode, of Apps such as the RMS Speed Assist or Google Live. The other and major distraction are the plethora of Mobile Smartphones, GPS Mapping devices and Network Communication devices (MTD).</p>
                                        <p className="section-subtitle">The single tablet which Taxis.Plus use as the only required device has universal capacity to meet all communication and security needs with minimal distraction together with Voice activation for all functions.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Soliciting and Touting</h5>
                                        <p className="section-subtitle">Both the activities of Soliciting and Touting for Fares are prohibited by Regulation. Neither are defined in the Regulations and the only recourse for a Driver having received an Infringement is to contest the matter in Court. Taxis.plus will endeavour to, in the new driver Training process, give examples of such activities. The guideline is to not physically or verbally approach an apparently intending passenger other than to ask if he or she is trying to contact the particular Driver or Taxi. </p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Transporting Vulnerable Passengers</h5>
                                        <p className="section-subtitle">Whilst Taxi Drivers are not specifically required to be certificated by a “Working with Children”, there is an expectation that all Drivers are to be conscious of, and behave appropriately, with Vulnerable Passengers. Given that the bulk of Drivers are experienced and have at some stage progressed through an officially recognised Course, the action and response will in initial stages only be initiated following a passenger complaint.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Wheelchair Accessible Vehicles</h5>
                                        <p className="section-subtitle">Pre P2P WATS Drivers are able to be deemed as trained, but new Drivers will be assessed as required by Regulation as to their capabilities and awareness as WATS Drivers. Our website will have an on-line Training manual.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Driver Monitoring</h5>
                                        <p className="section-subtitle">The OBD installed to Registered Vehicles in the Network provide an ability for an Authorised Officer to monitor all aspects of Driver behaviour. That Officer has the discretionary authority to act or react to observed behaviour both on-line and as recorded. Within the provisions of the Workplace Surveillance Act and the acceptance by the Driver on Sign-up, it is expected that such monitoring facility will be beneficial for the safety of Drivers and Passengers.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Fatigue</h5>
                                        <p className="section-subtitle">Whilst it is not possible to have effective oversight on the activities of a Driver before he commences a shift, the system restricts continuous driving to a 13 hour shift. We reserve the ability to actually immobilise the vehicle in an extreme situation, but we can turn off all meter and payment activity. The OBD records and can report driving behaviour where necessary.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Drug & Alcohol Policies</h5>
                                        <p className="section-subtitle">By Traffic and Road Rules , and by Regulation we are equally bound to a Zero Tolerance Policy. We cannot enforce testing as such, but will pursue a three strikes report Policy.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Training & Education</h5>
                                        <p className="section-subtitle">In preparation as at July 2019 is a Training and Education Programme that will be a mandatory, one day, refresher course for all Drivers to attend tri-annually. Class size will be a maximum of 15 and on-road re-assessment is planned. Our media team will also be involved in occasional video clips to remind and warn all drivers.</p>
                                    </li>
                                    <li>
                                        <h5 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Incident & Accident Management and Notifiable Occurences</h5>
                                        <p className="section-subtitle">Taxi Service Providers, both as Drivers and Operators, are required to report Notifiable Occurences , and access for both to the Register thereof for each Vehicle and Driver is part of the Sign-on / Sign-off. Either as the BSP or the TSP, Taxis.plus has overarching access to the records and record of action.</p>
                                    </li> 
                                    
                                </ul>
                            </div>
                             
                        </div>
                    </div>
            </section>
            <Footer/>
        </>
    )
}