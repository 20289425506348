import React, { useCallback, useEffect, useState } from "react";
import { BookingFilter } from "../enums/core.enum";
import { Footer } from "../footer/Footer";
import { RideReport } from "./report/rides.component";


export function CorporateAccountList(props: any) {


  return (
    <>
      <section className="section-yellow home-section" id="report">
        <RideReport
          bookingFilter={BookingFilter.Booking}
        />
        <Footer />
      </section>
    </>
  );

}
