import React from 'react';
import {Footer} from '../../footer/Footer';

export function Security(){
    return(
            <>
                <section className="section-yellow section-bottom-border feature-section"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-bottom-10">
                                <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> Taxi Security System </h1>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The new operating system incorporates a wholly new approach to Public Passenger Vehicle Safety and Security. It makes full and efficient use of new Technology, and implements a process which has hitherto simply not been available. Within the system, which is more than an App, and which is part of the new concept of an Internet of Things, we have a substantially, if not totally, new way of recording, retaining and making accessible all of that which occurs in the Taxi environment.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Not just occasional snaps of the occupants and limited range walk-ups, but of the interior, live, with Voice, and of a 360o live view of the outside. It’s all tagged to GPS and Meter, and is operational from log-on to log-off with a few secure extras. At this time we are starting with a once per ten second snap when the Taxi is not Hired. From that point on it is fully live and sending data for Cloud storage. There is an additional 45 second buffer which retains data locally, and transmits on crash type de-acceleration. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Recording is a relatively simple function. For the interior, we use the TaxiCam 6 with low light resources. For the exterior we use a roof-mounted 360o camera which will provide driving behaviour and accident proof. All as live video imaging.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Retaining the data on the Cloud overcomes all the local storage issues and past limitations, of time and quantity. There is no longer a “black box”. Part of the sequence of operations is that if there is no security function occurring, there are no taxi operations. And the fix is a simple re-set or at worst a new Tablet plugged in. All Wi Fi.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Accessing the data is the issue, or would be the issue if not for our system. Permission based access.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The system provides for varying levels of access, based on “need to know”, and a programmable level for each authorised user limited to their degree of authorization. By example, in Victoria, whilst Voice is recorded it is only accessible by ( authorised) Police or Taxi Commission personnel. And their access is recorded. It equally possible to permit Police to look through the external camera at live street activity. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The Driver, and the Passenger also, have a three stage Alarm sequence, which is effectively immediate.<b/>
                                    Green = All OK   //    Orange = Observe and Monitor   //   Red = Emergency
                                </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Activation is by way of two on-screen icons, a separate dashboard button, a separate mini transportable device, or by touching the screen with four fingers. For the Emergency function we can pre-set four call options simultaneously. Viz: 000 Police only // Network Manager // Booking Manager // etc Simply saying OK does not terminate an Emergency. And it’s all recorded to minimise mis-use. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">In the Orange and Red function, an authorised observer can look in, listen, and talk or mute. Re-recording of the view is not possible, and all access is recorded to identify the observer. We can, by area, programme the display on the screen of the Interior Images as a deterrent factor for Orange and or Red. </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </>
    )
}