import React, { useEffect, useState } from "react";
import { isElementAccessExpression } from "typescript";
import { DriverFormSection, DriverFormSectionProps } from "../Section";
import { FormUpload } from "../Upload";

export function DriverFormLicense(props: DriverFormSectionProps) {
  const { register, getValues } = props;
  const [licensefile, setLicenseFile] = useState();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onchange = () => {
    const data = getValues();
    setLicenseFile(data.newLicenseFiles);
    if (licensefile == undefined) {
      setErrorMessage('License scans are missing')
    }
    else {
      setErrorMessage(undefined);
    }
  }

  
  
  return (

    <DriverFormSection {...props}>
      <>
        <div>
          {licensefile == undefined && errorMessage && <div className="error">{errorMessage}</div>}
          <FormUpload
            label='License Scans'
            name="newLicenseFiles"
            onchange={onchange}
            originalName="licenseFiles"
            description='Scanned copies of your License'
            multiple={true}
            {...props}
          />
          <input
            autoFocus
            ref={register({ required: true })}
            name="licenseNumber"
            type="text"
            placeholder="License Number"
            className="register-input white-input"
          />
          <input
            ref={register({ required: true })}
            name="licenseExpiryDate"
            type="text"
            placeholder="License Expiry"
            onFocus={(e) => e.target.type = 'date'}
            className="register-input white-input"
          />
        </div>
        {licensefile == undefined ?
          <div
            onClick={onchange}
            className="register-submit"
          >Next</div>
          :
          <input
          style={{marginBottom:'50px'}}
            value={'next'}
            className="register-submit"
            type={'submit'}
          />
        }

      </>
    </DriverFormSection>

  );
}