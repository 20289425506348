import React from 'react';
import { Footer } from '../footer/Footer';
import img4 from './../../img/play-store.png';
import img5 from './../../img/app-store.png';

export function Passengers(props: any) {

    const login = () =>{
        props.history.push('/login/')
    }

    return (
        <>
            <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Passengers</h1>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The focus of TAXIS.PLUS is on Passenger satisfaction. Once registered you have immediate access to all your Taxi requirements.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">All you need to do is to tap to speak in your Destination. We know it's you, we assume the pick-up is from where you are, and you get an immediate Fare Estimate, Distance and Trip Time. One more tap  to book now, or forward book and enter a time / date, and that's it.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Immediately a Driver accepts the job, you are shown who he is, where he is, and how long to arrive.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">He and you are in direct voice or text contact. We have only one rule for our Drivers : "accept the job ; do the job"</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The variations  of type of Taxi are available on the screen and the different fares are immediately displayed. For security we require a name, mobile, e-mail and credit card, but for any trip you can pay in cash, with another card, or split the payment.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">You can pick a preferred Driver Group. The trip is tracked on your mobile, and you can even add a tip. You get an immediate receipt, an e-mail and fully detailed trip records and even a Map.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">There are no freebies, no specials, no discounts. The Fare is the metered Fare, or a Fixed Fare by agreement between you and the Driver. Full transparency and accountability for all. It took ten years to get a less than 5% increase in the Distance Rate, but we are prepared to maintain Standard Fare rates and increase Driver Earning by improved productivity from increased occupancy with the TAXIS.PLUS APP</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p>
                                <a className="store-btn android" target='_blank' href={`${process.env.REACT_APP_GOOGLE_PLAYSTORE_URL}`}><img src={img4} alt="play store" /></a>
                                <a className="store-btn" target='_blank' href={`${process.env.REACT_APP_APPLE_APPSTORE_URL}`}><img src={img5} alt="app store" /></a>
                                <input style = {{backgroundColor:'#000000', width:163, height:40, marginTop:10, marginLeft:15,color:'#ffffff',borderRadius:6}} value="Login" type="submit" onClick = {login} />
                            </p>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}