import React from 'react';
import { Footer } from '../../footer/Footer';

export function NovNews(){
    return(
        <>
            <section className="section-yellow section-bottom-border feature-section"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-bottom-10">
                                <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> November Newsletter </h1>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">At last we are on the road. This month we are starting to Register our Drivers, Operators and Passengers. </p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Everything is On-Line. As soon as your Registration is approved you can start using the system. To effectively use TAXIS.PLUS the Registration needs be complete and you will be prompted for missing fields. We are obliged by P2P to have and maintain file copies  of ID, Police Report and Driving History for our Drivers. We cannot pass on Bookings to Drivers unless they are authorized, we cannot authorize without an image of those essential records, just like every other Booking Service. Likewise for Operators, we need records of Insurances and Registration. Once accepted as an Operator, you have access to the Vehicle Dashboard to record all Vehicle details, and to maintain a functional base for P2P requirements.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Discussions with Taxi Service Providers over recent weeks have reaffirmed the need for the services we provide.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">The degree of information and control over their own Taxis is something far beyond the services of existing Networks. Only this week we heard of a Driver covering up the internal camera – and, worse, of having no visual record of his actions – just a GPS track of quite mysterious trips. TAXIS.PLUS is bringing back Transparency and Accountability, together with the assurance of a safe trip.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">To divert...</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Ion air purifiers have a proven and established function in reducing air-borne aerosol particles, odours, mould and bacteria. There is no direct proof of efficacy against Covid 19, but there is against SARS.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">We cannot therefore say it works, but it sounds like a very good idea. It does have a fan to induct air and expel ionised air up towards the mouth / nose when the micro device is worn around the neck. In the limited space of a Taxi, any and everything might help. Accordingly, we will support a campaign for the NSW Government to supply all Taxi Drivers with such a device. The Cost?</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">$250... This is a product from a US Manufacturer, Plasma Air,  and according to their publications is a genuine bi-polar device which releases both Positive and Negative ions.  The  advice is that it is not to be compared with $25 devices from China. The fact that they are premium manufacturer of large scale in-built machines for Schools, Hospitals, Casinos and high-rise Buildings gives some credence to their claims. But, because it is un-tested it is un-proven to work against Covid.  Whatever. As a possible means of preventing the spread of Covid 19, and to assist in making Taxi Travel safer, it has been suggested to use a bi-polar mobile micro ion generator, to be worn by the Taxi Driver.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">At the bottom of this page we will have a link to their website information, and for direct purchase.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">First things first... Register with TAXIS.PLUS</p>
                            </div>
                        </div>
                    </div>
            </section>
            <Footer />
        </>
    )
}