import React, { useEffect } from "react";
import { DriverFormSection, DriverFormSectionProps } from "../Section";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Controller, useForm } from "react-hook-form";

export function DriverFormBasic(props: DriverFormSectionProps) {

  const { register,control } = props;

  return (

    <DriverFormSection {...props}>
      <>
        <div>
          <input
            autoFocus
            ref={register({ required: true })}
            name="firstName"
            placeholder="First Name"
            type="text"
            className="register-input white-input"
            required
          />
        </div>

        <div>
          <input
            ref={register({ required: true })}
            name="lastName"
            type="text"
            placeholder="Last Name"
            className="register-input white-input"
            required
          />
        </div>

        <div>
          <input
            ref={register({ required: true })}
            name="email"
            type="email"
            placeholder="Email"
            className="register-input white-input"
            required
          />
        </div>

        <div className="form-group">
          <Controller
          name="phone"
          control={control}
          rules={{ required: true, validate: (value) => isValidPhoneNumber(value) }}
          render={( props:any) => (
            <PhoneInput
              value={props.value}
              onChange={props.onChange}
              id="phone"
              required
              defaultCountry="AU"
              countries={["AU"]}
              international={true}
              useNationalFormatForDefaultCountryValue={true}
            />
          )}
        />
        </div>
        <input
        style={{marginBottom:'50px'}}
          value='Start Registration'
          className="register-submit"
          type="submit"
        />
      </>
    </DriverFormSection>

  );
}