import React, { useCallback, useEffect, useState } from 'react';
import { ApiService } from '../../service/api.service';
import { authentication } from '../../service/auth.service';
import { Booking } from '../entities/booking.entity';
import { Fare } from '../entities/fare.entity';
import { Service } from '../entities/service.entity';
import { Zone } from '../entities/zone.entity';
import { List } from './paginatedList';
import { Pagination } from './pagination';
import { Gender, Passenger, passenger } from './passengerLogin';

export enum TripStatus {
    Enroute = "enroute",
    Metering = "metering",
    Completed = "completed",
    Cancelled = "cancelled"
}

export enum TripType {
    Hail = "hail",
    App = "app",
    Dispatch = "dispatch"
}

export enum paidBy {
    CreditCard = 'credit-card',
    Cash = 'cash',
  }

export interface Driver  {
  id:string ;
  code: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  email: string;
  phone: string; 
  businessRegistration: string;
}

export interface Vehicle {
    id:string; 
    vin: string;
    plate: string;
    year: number;
    make: string;
    model: string;
    color: string;
    registrationExpiryDate: Date | string;
    numPassengers: number;
    numLuggage: number; 
  }

export interface Session {
    id: string;   
    driver: Driver | string;
    vehicle: Vehicle | string; 
  }

export interface Trip {
    id: string;
    code: string;
    totalFare?: number;
    distanceCharge?: number;
    waitCharge?: number;
    bookingCharge?: number;
    tax?: number;
    taxLabel: string;
    levy?: number;
    tolls?: number;
    rideTime?: number;
    ccCharge?: number;
    extraCharge?: number;
    startCharge?: number;
    tip?: number;
    fare: Fare | string;
    zone: Zone | string;
    service: Service | string;
    passenger?: Passenger | string;
    startTime: Date;
    endTime?: Date;
    startLatitude: number;
    startLongitude: number;
    stopLatitude?: number;
    stopLongitude?: number;
    session: Session | string;
    booking: Booking | string;
    distance?: number;
    paidBy?: paidBy;
    waitTime?: number;
    fuel?: number;
    createTime: Date;
    updateTime: Date;
    deletedTime: Date;
    type: TripType;
    status: TripStatus;
}

const FareStyle = {
    Cell: { border: '2px solid #000000' },
    HeaderRow: { backgroundColor: "#000", color: "#e8f71c", border: "2px solid #000" },
    CellText: { color: "#292929", fontSize: 18 },
}

export let tripIds:string = ''

export const PassengerDetail = (props:any) => {
    const [passengerDetails, setPassengerDetails] = useState<Passenger>()
    const [trips, setTrips] = useState<Trip[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [postPerPage, setPostPerPage] = useState(3)

    useEffect(() => {
        const passengerData =authentication.getPassengerUser()
        setPassengerDetails(passengerData)
    }, [])

    const TripsDetail = useCallback(async () => {
        if (passengerDetails) {
            setLoading(true) 
            const trip = await ApiService.get(`trip/id?id=${passengerDetails.id}`)
            setTrips(trip.data)
            setLoading(false)
        }
    }, [passengerDetails])

    useEffect(() => {
        TripsDetail()
    }, [TripsDetail])

    const onclick = (tripId: string) => {
        tripIds = tripId
        props.history.push(`/trip-details/${tripId}`);
    }

    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPost = trips.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

    return (
        <section style={{ paddingBottom: 160 }} className="section-yellow section-bottom-border feature-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 padding-bottom-10">
                        <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Passenger Details</h1>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr style={FareStyle.HeaderRow}>
                                <th style={FareStyle.Cell} scope="col">First Name</th>
                                <th style={FareStyle.Cell} scope="col">Last Name</th>
                                <th style={FareStyle.Cell} scope="col">Phone Number</th>
                                <th style={FareStyle.Cell} scope="col">Email Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={FareStyle.Cell}>
                                <td style={{ border: "2px solid #000", }}>{passengerDetails?.firstName}</td>
                                <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{passengerDetails?.lastName}</td>
                                <td style={FareStyle.Cell}>{passengerDetails?.phone}</td>
                                <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{passengerDetails?.email}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-12 padding-bottom-10">
                        <h2 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Trips of {passengerDetails?.firstName} {passengerDetails?.lastName}</h2>
                    </div>
                </div>
                {trips.length > 0 ?
                    <div>
                        <List onclick = {onclick} trips={currentPost} loading={loading} />
                        <Pagination postPerPage={postPerPage} totalPosts={trips.length} paginate={paginate} />
                    </div> :
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h5 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>{passengerDetails?.firstName} {passengerDetails?.lastName} have no trips</h5>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}