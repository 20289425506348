import React from "react";
import { DriverFormSection, DriverFormSectionProps } from "../Section";

export function DriverFormEmergency(props: DriverFormSectionProps) {

  const { register } = props;

  return (

    <DriverFormSection { ...props }>
      <>
        <div>
          <input
            autoFocus
            ref={ register({ required: true }) }
            name="emergencyName"
            type="text"
            placeholder="Emergency Contact Full Name"
            className="register-input white-input"
          />
          <input
            ref={ register({ required: true }) }
            name="emergencyEmail"
            type="email"
            placeholder="Emergency Contact Email Address"
            className="register-input white-input"
          />
          <input
            ref={ register({ required: true }) }
            name="emergencyPhone"
            type="phone"
            placeholder="Emergency Contact Phone Number"
            className="register-input white-input"
          />
        </div>

        <input
        style={{marginBottom:'50px'}}
          value='Next'
          className="register-submit"
          type="submit"
        />
      </>
    </DriverFormSection>

  );
}