import React from "react";
import { DriverFormSection, DriverFormSectionProps } from "../Section";

export function DriverFormBank(props: DriverFormSectionProps) {

  const { register } = props;

  return (

    <DriverFormSection {...props}>
      <>
        <div>
          <input
            autoFocus
            ref={register({ required: true })}
            name="bankName"
            type="text"
            placeholder="Name of Bank"
            className="register-input white-input"
          />
          <input
            ref={register({ required: true })}
            name="bankAccountNumber"
            type="text"
            placeholder="Account Number"
            className="register-input white-input"
          />
          <input
            ref={register({ required: true })}
            name="bankAccountTitle"
            type="text"
            placeholder="Account Title"
            className="register-input white-input"
          />
          <input
            ref={register({ required: true })}
            name="bankAccountRouting"
            type="text"
            placeholder="Routing / BSB"
            className="register-input white-input"
          />
        </div>

        <input
        style={{marginBottom:'50px'}}
          value='Next'
          className="register-submit"
          type="submit"
        />
      </>

    </DriverFormSection>

  );
}