import React from 'react';
import { Footer } from '../../footer/Footer';

const FareStyle = {
    Cell: { border: '2px solid #000000' },
    HeaderRow: { backgroundColor: "#000", color: "#e8f71c", border: "2px solid #000" },
    CellText: { color: "#292929", fontSize: 18 },
}

export function TaxiDrivers(){
    return(
        <>
           <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>To My Fellow Sydney Taxi Drivers</h1>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle"> I have been driving Taxis for more than 25 years, and I used to make a decent living out of five shifts a week.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Night Shifts. Monday to Friday. Not any more. </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Not for the last three years. Uber, the Networks, the System have killed us. BUT…. I have been developing a totally new System, and it is ready to go. We now have a new way to keep on Driving, to make Money, to be Safe. TAXIS.PLUS</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">For many years I have run the Australian Taxi Drivers Association, which helps Drivers and represents their interests in all the totally useless bureaucratic ways that the system exploits Drivers. We have helped many, won a few times, and to be honest, lost in most of the battles. But we fight on. With the new regime of absolute Network Power under P2P there is very little we can do. Networks now can do as they please.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">However. We are now also our own Network and Authorised Booking Service. We are using a totally complete and innovative system developed as a brand new Disruption Force.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">We will rely on word of mouth, and perhaps naively, on my belief that there are no bad taxi drivers, only exhausted ones. As we get back to enough jobs, we can cut back on hours, and work more efficiently with a better and safer system. And the best Tablet available.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">We cannot afford the thousands of dollars that everybody else is ready to throw at the P2P business. All we wish to do is to provide an efficient Taxi Service.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">TAXIS.PLUS is the new way forward. We have the Drivers own Booking , Despatch and Payment System. We have a 24 / 7 Security System. We have APPS for Drivers and Passengers which work to the total advantage of both. Once you accept the job, you are in on-line contact with the Passenger. And he is in direct contact with you. No more M3’s. Google live mapping to Pick-up and Destination. A meter that prompts for tips.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">You can operate either on your own mobile, or on the Tablet we supply for $100, as an Independent Driver on the existing Network, or use the Tablet provided to your Operator when he joins the new Network. Fares are paid into your nominated Bank Account at end of Shift, and it all works off the Passenger payment for a Booking and the Credit Card Fee.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The Operator not only has a low cost Network ($450 pm) but he also gets a $1.00 credit off each Booking. With about 10 bookings a shift, including e-hails, we can eliminate Network Fees. No doubt the pay-ins will also drop. And you both have full audio video records of everything, accessible for an authorised purpose.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">There is no such thing as a free lunch. This is not a free lunch. There are no freebies, no specials, no discounts, just old-fashioned service and values.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">You, as the Driver, need to join TAXIS.PLUS, and get the passenger to pay through the App on your Tablet, and get his or her e-mail and details. We can then do the rest, with QR codes and sign him up for easy future trips.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">It’s all about critical mass. We need both Drivers and Passengers. We need Passengers to book a Taxi with us, and to use our system to pay for the ride. We need you, the Taxi Driver, to be able to take the payment and to be on-line for the next job. You, the taxi driver, are the key to the whole operation.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Why ? Because unless you as the Driver, at the very bottom of the food chain, take responsibility and control back into your own hands we are all stuffed. In all my years of driving, almost no one else has lasted the distance. If you want to make a difference … step forward. All that I want is to make sure you come home safe at the end of your shift and have earned a “ fair share of a fair fare”.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">That’s the motto of Taxi Driver Action Inc. and of the Australian Taxi Drivers Association.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Under the new NSW Regulations it is sufficient for Taxis to be connected to an Authorised Booking Service, provided there is connection also to a Taxi Service providing a Security / Alarm system.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">We wish to make TAXIS.PLUS available around NSW, and around Australia either as a Taxi Network Brand in its own right, or by facilitating existing Networks to provide a better, cheaper operational system. </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">If a regional network wishes to maintain offices, shift managers and a 24/7 physical presence, it can do so and be funded out of its share of Booking Fees. De-regulation of Booked work makes that an easy, local, decision. </p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">It can also set its own additional fees.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">We are proposing that www.taxis.plus operates the Authorised Booking Service and that the various groups of current Networks, Operators, or even Driver Groups maintain their own, current, web-based Booking Identity and simply link their page into the generic Booking Page of TAXIS.PLUS We start in Sydney.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The immediate Passenger Booking is retained within that Identity, and its associated taxis for a minute, for acceptance by your Driver, on-line, after which time it goes out to all taxis, serviced and on-line. For forward bookings the job stays with your Group until twenty minutes before the job is due.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Where the Taxi Driver is using TAXIS.PLUS on his mobile, and the vehicle has not switched to the full tablet based system either on the new TAXIS.PLUS Network, or on an existing franchised Network, the security related issues remain a responsibility of the original network, still receiving Network Fees. The existing Network’s Booking and Service Regulatory requirements also remain in place.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Where the vehicle has switched Networks and signed up with TAXIS.PLUS, or stays with a newly licensed existing Network, operating on our equipment, then all those security related issues become the responsibility of TAXIS.PLUS as a taxi network service provider, charging its own network fees. We have no compromises on Safety.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">TAXIS.PLUS fees are an all-inclusive $450 per month, payable by the Taxi Service Provider / Operator.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The Taxi Fares go to the Driver. In cash or to his Bank Account if payment is by account / credit card.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The 4% Credit Card Surcharge goes to cover merchant fees and transfer fees, sending Fares direct to your Bank Account at end of shift. ACCC guidelines permit Taxis to charge a maximum of 5% on all cards. We charge 4%. The Booking Fee which is retained by TAXIS.PLUS to cover costs, gets split as detailed below. With the new Regulations we will charge $2.50 per booking or e-hail. A Booking Service is now able to set its own rates and extra charges. We will be charging the maximum “rank and hail” fares set by the NSW Government, plus an occasional “premium” fee to reflect market conditions, and as accepted by the passenger prior to despatch. That’s our version of “surge pricing” but all to the Driver. Not to the “facilitator”</p>
                        </div> 
                        <table className="table table-bordered">
                            <thead>
                                <tr style={ FareStyle.HeaderRow }>
                                    <th style={ FareStyle.Cell } scope="col">BOOKING FEE SPLIT</th>
                                    <th style={ FareStyle.Cell } scope="col">User in an Existing Network $2.50</th>
                                    <th style={ FareStyle.Cell } scope="col">TAXIS.PLUS or New Network $2.50</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>Vendor</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$1.00</td>
                                    <td style={ FareStyle.Cell }>-</td> 
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>TAXIS.PLUS or NN</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$0.50</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$0.50</td> 
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Driver</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$0.50</td>
                                    <td style={ FareStyle.Cell }>$0.50 </td> 
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Driver Group</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$0.50</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>$0.50</td> 
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>Taxi Operator</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>-</td>
                                    <td style={ FareStyle.Cell }>$1.00 </td> 
                                </tr> 
                            </tbody>
                        </table>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The Taxi Driver gets the Fares on jobs paid through the Tablet, into his Bank Account at the end of the shift, less Booking Fees, and the Levy, and he can then direct shift payments to the Operator. He gets a new Samsung Tablet for $100.00 deposit and $400 payable from the 50c per Booked job in his Booking Service Account. Where Drivers have set up a Group of however many taxis, that Group will get first preference on all bookings from their collective passengers plus $0.50 into their Group Account, managed by their leader. In any Network.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The Taxi Operator who has joined TAXIS.PLUS or a new Franchised Network will get $1.00 of the Booking Fee credited to his Account and paid monthly directly to his nominated Bank Account. We suggest that Operators may pass some of those cost savings to their Drivers, by way of lower Shift Fees. If his taxi does 450 jobs a month that will cover all network fees. About 10 jobs a shift. That includes the instant e-hails. Not too difficult. The objective is complete elimination of Network Fees within a year, and a massive reduction from the start. Insurance and Lease Fees are the next target. Make our own Level Playing Field.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The Network Fee of $450 per month is all-inclusive. That means we supply a Tablet, Cameras, interior and 360o Camera on top of a new Roof Bar. Safety Plus.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">It’s all WiFi , so minimal delay or cost of installation. You, as Network, Operator or Driver have access to what you are authorised to see. No download charges. The rooftop camera will bring with it a discount on Insurances – Comprehensive and eventually CTP Greenslip. A wholly new way to ensure Passenger and Driver Safety.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">This is the way to revitalise our Industry. The Tablet can link to your smartphone as well, with your own mobile number, to be easily hands free. A new level of Security for all. Plus the best available Tablet.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Does TAXIS.PLUS, have jobs ? NO !! NOT YET !! That’s why we want you to start using our system on your new Tablet in your existing Network. And to use the TAXIS.PLUS system for payment. That’s how we will get committed users. Join them up !</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Will you lose your Plate if I use the new system ? No. It’s just another device in the taxi. One more piece of high tech junk. If I switch Networks ? Maybe, maybe not.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">Can the Plate owner or his Broker afford to reduce his Monthly Plate Fees ? That’s his decision !!</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">When you and your Drivers are happy with the jobs available, and when you are sure that your Plate is secure – then make a switch. In the meantime, just use the App on the Driver’s Tablet.</p>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">I have waited years already. I am in no hurry. Because I know that this is the best and only way forward. Our Industry is at, or maybe, even past, the crossroads. Time now to make a move.</p>
                        </div> 
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Michael Jools</th>
                                    <th scope="col">TAXIS.PLUS</th>
                                    <th scope="col"><a href="!#">www.taxis.net.au</a></th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href="!#">www.taxis.plus</a></td>
                                    <td>04 1927 2744</td>
                                    <td></td> 
                                </tr> 
                            </tbody>
                        </table>
                        <div className="col-md-12 padding-bottom-10">
                            <p className="section-subtitle">The New Meter - Everything on one Device</p>
                        </div>
                    </div>
                     
                </div>
            </section>
            <Footer />
        </>
    )
}