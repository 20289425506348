import React, { useCallback, useEffect, useState } from "react";
import { BookingFilter } from "../enums/core.enum";
import { Footer } from "../footer/Footer";
import { RideReport } from "./report/rides.component";

interface props {

}


export function BookingReport() {

    const columns = ["Company Name","Booking Status","CreateTime","Booking Code"]

    return (
        <>
            <section className="section-yellow home-section" id="report">
                <RideReport
                    bookingFilter={BookingFilter.OnGoingBooking}
                    corporateColumn={columns}
                />
                <Footer />
            </section>
        </>
    );

}
