import React, { FormEvent, useState } from 'react'
import { ApiService } from '../../../service/api.service';


export function Newsletter() {
    const [email,setEmail]=useState();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        setError(undefined);
        setLoading(true);

        try {
            await ApiService.post('newsletter/register', {email});
            setRegistered(true);
        } catch (err:any) {
            if(err.response.data.statusCode==500){
            setError('Already subscribed with this email!');
            }
            else if(err.response.data.statusCode==400){
                setError('Invalid Email Address');
                }
                else
            setError('Something went wrong please try again letter');
        }
        setLoading(false);

        return false;
    }
    return (
        <section className="section-yellow small-paddings" id="newsletter-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center padding-top-50 padding-bottom-20">
                        <h3>Stay updated with news and information about taxis in Sydney. Sign up for our newsletter.</h3>
                    </div>
                    <div className="col-md-12 padding-bottom-40">
                        <div className="newsletter_form_wrapper" data-wow-delay="0.25s">
                            <div className="newsletter_form_box">
            { error && <div className="alert alert-danger">{ error }</div> }
                                <p className="newsletter_success_box" style={{ color:'green',display: registered?'block':'none' }}>Thank you for subscribing to our newsletter. You will be kept up to date with our latest developments.</p>
                               {!registered? <form id="newsletter-form" className="newsletter-form" onSubmit={handleSubmit}>
                                    <input id="email_newsletter" type="email" onChange={(e:any)=>setEmail(e.target.value)} name="email" placeholder="Enter Your Email Address" />
                                    <input type="submit" value={!loading ?"SIGN UP!":"SIGNING UP..."} id="submit-button-newsletter" />
                                </form>:null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
