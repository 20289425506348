import { LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { AxiosResponse } from 'axios';
import React, { BaseSyntheticEvent, FormEvent, ReactElement, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { ApiService } from '../../service/api.service';
import { Footer } from '../footer/Footer';
import { OperatorFormAgreement } from './sections/Agreement';
import { OperatorFormDone } from './sections/Done';
import { OperatorFormError } from './sections/Error';
import { Form } from './sections/Form';
import { OperatorFormSaving } from './sections/Saving';
import { OperatorFormConsent } from './sections/UseOfInformation';
import { OperatorSteps } from './Steps';
const MAP_LIBS: Libraries = ['places'];
export interface OperatorStep {
    title: string;
    view: OperatorViewState;
    description: string;
    fields: string[];
    completed?: boolean;
}

export enum OperatorViewState {
    Basic,
    Agreement,
    UseOfInformation,
    Saving,
    Done,
    Error,
}

const STEPS: OperatorStep[] = [
    {
        title: 'Basic',
        view: OperatorViewState.Basic,
        description: 'Enter your basic details to start the registration processs',
        fields: ['contactEmail', 'businessRegistration', 'accreditionNumber', 'bankSwiftCode', 'password', 'title', 'contactPerson', 'contactPhone', 'contactAddress'],
    },
    {
        title: 'Agreement',
        view: OperatorViewState.Agreement,
        description: 'Review and consent to the operator agreement',
        fields: [],
    },
    {
        title: 'Use of Information',
        view: OperatorViewState.UseOfInformation,
        description: 'Review and consent to the operator use of information statement',
        fields: [],
    },
];

export function OperatorRegister() {

    const formProps = useForm();

    const [view, setView] = useState(OperatorViewState.Basic);
    const [steps, setSteps] = useState(STEPS);
    const [errorMessage, setErrorMessage] = useState<string>();


    const renderForm = (): ReactElement =>
        <>
            <Form show={view === OperatorViewState.Basic} {...formProps} />
            <OperatorFormAgreement show={view === OperatorViewState.Agreement} {...formProps} />
            <OperatorFormConsent show={view === OperatorViewState.UseOfInformation} {...formProps} />
            <OperatorFormSaving show={view === OperatorViewState.Saving} {...formProps} />
            <OperatorFormDone show={view === OperatorViewState.Done} {...formProps} />
            <OperatorFormError show={view === OperatorViewState.Error} {...formProps} />
        </>

    const handleNext = () => {
        nextStep();
    }

    const handleError = (errors: FieldErrors, e?: BaseSyntheticEvent) => {
        const currentStep = steps.find(step => step.view === view);
        const errorMessages: string[] = [];
        for (let [key, value] of Object.entries(errors)) {
            const { ref } = value;
            // Only show errors for the current step's fields
            if (currentStep?.fields.includes(key)) {
                if (ref.placeholder === undefined) { errorMessages.push(`Following Field is ${value.type}`); }
                else { errorMessages.push(`${ref.placeholder} ${value.type}`); }
            }
        }
        if (errorMessages.length > 0) {
            setErrorMessage(errorMessages.join(', '))
            return;
        } else {
            setErrorMessage(undefined);
            handleNext();
        }
    }

    const nextStep = (e?: FormEvent<HTMLFormElement>) => {
        const currentStepIndex = steps.findIndex(step => step.view === view);
        if (currentStepIndex === -1) {
            throw new Error('While next stepping operator form, step missing for view');
        }

        // Mark current step as completed
        const currentStep = steps[currentStepIndex];
        currentStep.completed = true;
        const updatedSteps = [...steps];
        updatedSteps[currentStepIndex] = currentStep;
        setSteps(updatedSteps);

        const nextStep = steps[currentStepIndex + 1];
        // If we're on the last step, submit the form
        if (!nextStep) {
            submit();
        } else {
            setErrorMessage(undefined);
            setView(nextStep.view);
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        e && e.preventDefault()

        return false;
    }

    const setStep = (step: OperatorStep) => {
        setView(step.view);
    }

    const submit = async () => {
        setView(OperatorViewState.Saving)
        setErrorMessage(undefined);
        try {
            const values = formProps.getValues();
            const final = { ...values }

            const res = await ApiService.post('operator/register', {
                ...values,status:"blocked"
            });
            setView(OperatorViewState.Done)
        } catch (err: any) {
            setErrorMessage(err?.message);
            setView(OperatorViewState.Error);
        }
    }

    return (
        <>
            <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
                <section className="section-yellow home-section" id="home">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 padding-top-20">
                                <h1 className="margin-top-10">Register as a Operator</h1>
                                <form onSubmit={formProps.handleSubmit(handleNext, handleError)}>
                                    {errorMessage && <div className="error">{errorMessage}</div>}
                                    {renderForm()}
                                </form>
                            </div>
                            <div className="col-md-7">
                                <OperatorSteps steps={steps} currentView={view} setStep={setStep} />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </LoadScript>
        </>
    )
} 