import React, { useEffect, useState } from 'react';
import { Driver, Session, Trip, Vehicle } from './passengerDetails';

interface props {
    trips: Trip[],
    loading: boolean,
    onclick: any
}

const FareStyle = {
    Cell: { border: '2px solid #000000' },
    HeaderRow: { backgroundColor: "#000", color: "#e8f71c", border: "2px solid #000" },
    CellText: { color: "#292929", fontSize: 18 },
}

export const List = ({ trips, loading, onclick }: props) => {

    const onClick = (tripId: string) => {
        onclick(tripId);
    }

    if (loading) {
        return <h2>Loading...</h2>
    } 

    return (
        <div className="container">
            <div className='row'> 
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr style={FareStyle.HeaderRow}>
                                    <th style={FareStyle.Cell} scope="col">Start Time</th>
                                    <th style={FareStyle.Cell} scope="col">Trip Type</th>
                                    <th style={FareStyle.Cell} scope="col">Trip Code</th>
                                    <th style={FareStyle.Cell} scope="col">Total Fare</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trips.map((trip: Trip) => {
                                    return (
                                        <tr style={{...FareStyle.Cell, cursor:'pointer'}} role='button' onClick={() => onClick(trip.id)} key={trip.id}>
                                            <td style={{ border: "2px solid #000", color: '#000000' }}>{trip.startTime}</td>
                                            <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{trip.type}</td>
                                            <td style={{ ...FareStyle.Cell, color: '#000000' }}>{trip.code}</td>
                                            <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{trip.totalFare?(trip.totalFare/100):'N/A'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div> 
        </div>
    )
}