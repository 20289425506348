import React, { useCallback, useEffect, useState } from "react";
import { BookingFilter } from "../enums/core.enum";
import { Footer } from "../footer/Footer";
import { RideReport } from "./report/rides.component";

interface props {

}


export function ScheduleBookingReport() {

    const columns = [
        "Booking Code",
        "Driver ID",
        "Passenger",
        "Booking Status",
        "Scheduled Time",
        "Repeat Days",
        "Create Time",
    ]

    return (
        <>
            <section className="section-yellow home-section" id="report">
                <RideReport
                    bookingFilter={BookingFilter.ScheduledBooking}
                    corporateColumn={columns}
                />
                <Footer />
            </section>
        </>
    );

}
