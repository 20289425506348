import React from 'react';
import { OperatorFormSection, OperatorFormSectionProps } from '../Section';

export function OperatorFormError(props: OperatorFormSectionProps) {
  return (
    <OperatorFormSection {...props}>
      <div className="registeredfail">
        <p>Failed To register operator</p>
        <p>Please try again later.</p>
      </div>
    </OperatorFormSection>
  );
}