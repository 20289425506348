import React, { useEffect } from 'react';
import { OperatorFormSection, OperatorFormSectionProps } from '../Section';

export function OperatorFormSaving(props: OperatorFormSectionProps) {

  useEffect(() => {
    if (!props.show) {
      return
    }
  }, [props.show]);

  return (
    <OperatorFormSection {...props}>
      <div className="registering">
        <p>Please wait...</p>
        <p>Your profile is being submitted for approval.</p>
      </div>
    </OperatorFormSection>
  );
}