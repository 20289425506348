import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ApiService, PaxApiService } from '../../service/api.service';
import { authentication } from '../../service/auth.service';
import { Footer } from '../footer/Footer';
import { CorporatePayment } from './corporatePayment';


export const CorporateLogin = (props: any) => {

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [message, setMessage] = useState<string>("");
  const [isReset, setIsReset] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false)
  const [setupStatus, setSetupStatus] = useState<boolean>(false)
  const [corporateData, setCorporateData] = useState<any>()
  
  const onHandleClick = async (e: any) => {
    e.preventDefault();
    setMessage("")
    setLoading(true);
    try {
      const response = await ApiService.post('corporateUser/login', {
        email,
        password
      })

      if (response) {
        if (response.data.corporate.corporateAccount.paymentMethods?.length == 0 || response.data.corporate.corporateAccount.paymentMethods == null || !response.data.corporate.corporateAccount.defaultCard) {
          setSetupStatus(true);
          authentication.onAuthenticationCorporate(response.data.corporate)
          setCorporateData(response.data.corporate.corporateAccount)
        }
        else {
          authentication.onAuthenticationCorporate(response.data.corporate)
          window.location.reload()
        }
      }
      setLoading(false);

    } catch (err:any) {
      console.log('Login Error:',err?.response?.data?.message);
      setLoading(false);
      setMessage(`${err?.response?.data?.message}`);

    }
  }

  const resetPassword = async (event: any) => {
    event.preventDefault()
    setMessage("")
    setIsReset(true)


  }

  const onHandleReset = async (e: any) => {
    e.preventDefault()
    try {
      const response = await ApiService.post('corporateUser/forgotPassword', {
        email
      })
      if (response) {
        setMessage("Email has been sent")
      }
    } catch (err) {
      console.error(err);
    }

  }

  return (
    <>
      <section style={{ paddingBottom: 160 }} className="section-yellow section-bottom-border feature-section" id="features">
        <div className="container">
          {setupStatus && corporateData ? <>
      <h1 style={{ textAlign: 'center' }}>{corporateData.companyName}</h1>
            <CorporatePayment id={corporateData?.id} corporateUser={authentication.getCorporateUser()}/>
          </> :
            <>
              {!isReset ? <div className="row" style={{display:"block"}}>  <h1>The Corporate Account Customer in Taxis.Plus</h1>
                <p>You can create your own Corporate Account which enables you, as Account Manager, to make Taxi Bookings for your Staff, Customer, Guests or Clients, and to monitor the Progress and History of those Bookings. We need your Corporate Credit Card, and it will be used as the default Credit Card. The Passenger can always choose to pay with his or her own card, and treat your assistance as just for the Booking, or to accept your offer to pay. This means that the Driver gets paid in all cases.</p>
                <p>There are no Charges, Fees or costs to you other than the 4% Transaction Fee. </p>
                
                <p>Less than any other Taxi Network ! </p>
                <p>No Account Keeping Fees or Charges but total access to records on your Admin Screen. As ‘admin’ you are in charge of the system and able to use it as a once-off Booking, for recurring trips, or for a Function “general call”. When you register a Passenger as “staff”, he or she, gets access from their registered e-mail and password.</p>
                
                  
                  <p>When you set-up or make the Booking, you will need to Register the Passenger’s  Name, Mobile and email.  From then on it all runs on that e-mail.</p>
                  
                  <p>If it is going to be a School Pick-up and Drop, an NDIS job , or something complicated, just call <a href="tel:019 272 744">019 272 744</a> and talk to Michael Jools</p>
                  
                  
                 
                  
                <div style={{ margin: 'auto', width: '40%' }}>
                  <div className="col-md-12 padding-top-20 text-center">
                    <h1>Corporate Account Login</h1>
                    <div className="register-form-wrapper wow bounceIn" data-wow-delay="0.5s">
                      <div>
                        <p className="register_success_box" style={{ display: "none" }}>We received your message and you'll hear from us soon. Thank You!</p>
                        <form id="register-form" className="register-form register" method="post" onSubmit={onHandleClick}>
                          <input autoFocus className="register-input white-input" required placeholder="Email" type="email" onChange={(e: any) => { setMessage("");setEmail(e.target.value)}} />
                          <input className="register-input white-input" required placeholder="Password" type="password" onChange={(e: any) => {setMessage("");;setPassword(e.target.value)}} />
                          <div style={{ textAlign: "end" }}>
                            <a href="#" onClick={resetPassword}>Reset Password</a>
                          </div>
                          {!loading?<input style={{ marginTop: "13px" }} value="Login" className="register-submit" type="submit" />:
                          <input style={{ marginTop: "13px" }} disabled value="Processing..." className="register-submit" type="submit" />}
                        </form>
                        {message&&<h3 style={{color:'red'}}>{message}</h3>}
                        <p className="register-form-terms"></p>
                      </div>
                    </div>
                  </div></div>
              </div> :
                <div className="row">
                  <div className="col-md-5 padding-top-20">
                    <form id="register-form" className="register-form register" method="post" onSubmit={onHandleReset}>
                      <h1>Enter Email to Reset</h1>
                      <input autoFocus className="register-input white-input" required placeholder="Email" type="email" onChange={(e: any) => setEmail(e.target.value)} />
                      <input style={{ marginTop: "13px" }} value="Reset" className="register-submit" type="submit" />
                    </form>
                    {message&&<h3 style={{color:'green',textAlign:'center',marginTop:'10px'}}>{message}</h3>}
                  </div>
                </div>}
            </>}
        </div>

      </section>
      <Footer />
    </>
  )
}