import React, { useCallback, useEffect, useRef, useState } from "react";
import { ApiService } from "../../service/api.service";
import { authentication } from "../../service/auth.service";
import { BookingFilter } from "../enums/core.enum";
import { Footer } from "../footer/Footer";
import { RideReport } from "./report/rides.component";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'


export enum Action {
    Add = 'add',
    Edit = 'edit',
    Delete = "delete"
}

export function CorporateUser() {

    const columns = ["Name", "Email", "Phone", "Status", "Action"]
    const { corporateAccount } = authentication.getCorporateUser()
    const [loading, setLoading] = useState(false)
    const [addState, setAddState] = useState(false)
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [password, setPassword] = useState();
    const [role, setRole] = useState("admin");
    const [step, setStep] = useState<Action>(Action.Delete)
    const [corporateUserId,setCorporateUserId] = useState("")

    const handleEvent = async (action: Action, id?: string) => {

        setPhone(undefined)
        setName(undefined)
        setRole("admin")
        setEmail(undefined)   

        switch (action) {
            case Action.Delete:
                var { data } = await ApiService.patch('corporateUser/delete', {
                    id
                })
                break
            case Action.Add:
                setStep(Action.Add) 
                break
            case Action.Edit:
                var { data } = await ApiService.get(`corporateUser/account/${id}`)
                setName(data.name)
                setRole(data.role)
                setStep(Action.Edit)
                setCorporateUserId(id as string)
        }

          setLoading(prevValue => !prevValue)

    }

    const handleSubmit = async(e:any)=>{
        e.preventDefault()
        try{
        switch(step){
            case Action.Add:
                let randPass = (Math.random() + 1).toString(36).substring(7);
                var { data } = await ApiService.post('corporateUser/create', {
                    name,
                    email,
                    phone,
                    password:randPass,
                    role,
                    corporateAccount:corporateAccount.id
                 })
                
                break;
            case Action.Edit:
                var { data } = await ApiService.patch('corporateUser/update', {
                    name,
                    role,
                    id: corporateUserId
                 })
                break;


        }
        if (data?.error) {

                  alert(`Error: ${data?.error?.message}`)
            }

         
        setStep(Action.Delete)
        setLoading(prevValue => !prevValue)
    }
        catch(error:any){
            alert(`Error: ${error?.message}`)
        }
        
    }

   
    return (
        <>
            <section className="section-yellow home-section" id="report">
                {step === Action.Delete ? <RideReport
                    bookingFilter={BookingFilter.CorporateUser}
                    corporateColumn={columns}
                    searchDisplay={"hide"}
                    endpointForRowData={`corporateUser/list/${corporateAccount.id}`}
                    csvDisplay={"hide"}
                    paginationDisplay={"hide"}
                    callBackMethod={handleEvent}
                    loading={loading}
                /> : null}
                {(step === Action.Add) || (step === Action.Edit) ?
                   

                        <div className="register-form-wrapper wow bounceIn" data-wow-delay="0.5s">
                            <div className="container">
                                <h1 className="register_success_box">{step === Action.Add?"Please Enter User Information":"Please Update User Information"}</h1>
                                <form id="register-form" className="register-form register" style={{width:"40%"}} method="post" onSubmit={handleSubmit}>
                                    <input autoFocus value={name} className="register-input white-input" required placeholder="Enter Your Name" type="text" onChange={(e: any) => { setName(e.target.value) }} />
                                    {step === Action.Add?<input  className="register-input white-input" required placeholder="Email" type="email" onChange={(e: any) => { setEmail(e.target.value) }} />
                                    :null}
                                    {/* <input className="register-input white-input" required placeholder="Password" type="password" onChange={(e: any) => { setPassword(e.target.value) }} /> */}
                                    {step === Action.Add?<PhoneInput
                                        value={phone}
                                        onChange={(e: any) => { setPhone(e) }}
                                        id="phone"
                                        required
                                        defaultCountry="AU"
                                        countries={["AU"]}
                                        international={true}
                                    />:null}

                                    <select className="register-input white-input" onChange={(e)=>setRole(e.target.value)} defaultValue={role}>
                                          <option  className="register-input white-input" value={"admin"}>Admin</option>
                                          <option className="register-input white-input" value={"staff"}>Staff</option>
                                      
                                    </select>
                                       
                                        <input style={{ marginTop: "13px" }}  value={step == Action.Add ? "Submit":"Update"} className="register-submit" type="submit" />
                                </form>
                                {/* {message && <h3 style={{ color: 'red' }}>{message}</h3>} */}
                                <p className="register-form-terms"></p>
                            </div>
                        </div>

                   
                    : ""}


                <Footer />
            </section>
        </>
    );

}
