import React from "react";
import { Footer } from '../footer/Footer';

export function PrivacyPolicy(){
    return(
    <>
      <section id="main-content" className="about-section">
        <div className="container text-justify">
          <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>PRIVACY POLICY</h1>
          <p className="mb-30">
            TAXIS.PLUS (ABN: 61536729945) (“TAXIS.PLUS”) is subject to the Australian Privacy Principles pursuant to the Privacy Act 1988 as amended by the Privacy Amendment (Enhancing Privacy Protection) Act 2012. Our Privacy Statement describes how Taxis.Plus protects the privacy of any of your personal information in accordance with the Australian Privacy Principles.
          </p>

          <ol>
            <li className="mb-20 font-weight-bold" >
              <h3 className="h3 mb-20 font-weight-bold section-title ">Collection</h3>
              <p className="mb-20">
                TAXIS.PLUS will only collect personal information which is necessary for the operation of its business. TAXIS.PLUS will only collect such information by lawful and fair means and not in an unreasonably intrusive way. 
              </p>
              <p className="mb-20">
                At or before the time (or, if that is not practicable, as soon as practicable after) TAXIS.PLUS collects personal information about an individual from the individual, TAXIS.PLUS will take reasonable steps to ensure that the individual is aware of: 
              </p>
              <ol type="a" className="mb-30" style={{fontSize:"18px"}}>
                <li>
                  the identity of the organisation and how to contact it; and
                </li>
                <li>
                  the fact that he or she is able to gain access to the information; and
                </li>
                <li>
                  the purposes for which the information is collected; and
                </li>
                <li>
                  the organisations (or the types of organisations) to which TAXIS.PLUS usually discloses information of that kind; and
                </li>
                <li>
                  any law that requires the particular information to be collected; and
                </li>
                <li>
                  the main consequences (if any) for the individual if all or part of the information is not provided.
                </li>
              </ol>
              <br/>
              <p className="mb-20">
                If it is reasonable and practicable to do so, TAXIS.PLUS will only collect personal information about an individual from that individual. If TAXIS.PLUS collects personal information about an individual from someone else, it will take reasonable steps to ensure that the individual is or has been made aware of the matters listed above except to the extent that making the individual aware of the matters would pose a serious threat to the life or health of any individual. 
              </p>
            </li>

            <li className="mb-20">
              <h3 className="h3 mb-20 font-weight-bold section-title">Purpose of Information</h3>
              <p>
                TAXIS.PLUS will collect and use personal information to provide services to you and to administer those services. TAXIS.PLUS may also use information to develop new products and services, attend to your enquiries, seek feedback, or tell you about other products or services offered by TAXIS.PLUS and its related companies. TAXIS.PLUS may also seek personal information for legal reasons such as compliance with legislation (for example Anti-Money Laundering legislation). 
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold section-title">Use &amp; Disclosure</h3>
              <p className="mb-20">
                TAXIS.PLUS will not use or disclose personal information about an individual for a purpose (the secondary purpose) other than the primary purpose of collection unless: 
              </p>
              <ol type="a" className="mb-30" style={{fontSize:"18px"}}>
                <li>
                  both of the following apply:
                  
                  <ol style={{listStyleType:"upper-roman"}}  className="mt-10 mb-20">
                    <li className="mb-10 " style={{listStyleType:"upper-roman"}}>
                      the secondary purpose is related to the primary purpose of collection and, if the personal information is sensitive information, directly related to the primary purpose of collection;
                    </li>
                    <li className="mb-10" style={{listStyleType:"upper-roman"}}>
                      the individual would reasonably expect TAXIS.PLUS to use or disclose the information for the secondary purpose;
                    </li>
                  </ol>
                </li>
                <li>
                  the individual has consented to the use or disclosure;
                </li>

                <li>
                  if the information is not sensitive information and the use of the information is for the secondary purpose of direct marketing:
                  <ol type="I" className="mt-10 mb-20">
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      it is impractical for TAXIS.PLUS to seek the individual’s consent before that particular use;
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      TAXIS.PLUS will not charge the individual for giving effect to a request by the individual to TAXIS.PLUS not to receive direct marketing communications;
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      the individual has not made a request to TAXIS.PLUS not to receive direct marketing communications;
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                    in each direct marketing communication with the individual, TAXIS.PLUS draws to the individual’s attention, or prominently displays a notice, that he or she may express a wish not to receive any further direct marketing communications; and
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      each written direct marketing communication by TAXIS.PLUS with the individual (up to and including the communication that involves the use) sets out TAXIS.PLUS’s business address and telephone number and, if the communication with the individual is made by fax, telex or other electronic means, a number or address which TAXIS.PLUS can be directly contacted electronically;
                    </li>
                  </ol>
                </li>

                <li>
                  if the information is health information and the use or disclosure is necessary for research, or the compilation or analysis of statistics, relevant to public health or public safety:
                  <ol type="I" className="mt-10 mb-20">
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      it is impractical for TAXIS.PLUS to seek the individual’s consent before the use or disclosure;
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      the use or disclosure is conducted in accordance with guidelines approved by the Commissioner under section 95A for the purposes of this subparagraph; or
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      in the case of disclosure – TAXIS.PLUS reasonably believes that the recipient of the health information will not disclose the health information, or personal information derived from the health information;
                    </li>
                  </ol>
                </li>

                <li>
                  TAXIS.PLUS reasonably believes that the use or disclosure is necessary to lessen or prevent:
                  <ol type="I" className="mt-10 mb-20">
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      a serious and imminent threat to an individual’s life, health or safety; or
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      a serious threat to public health or public safety;
                    </li>
                  </ol>
                </li>

                <li>
                  TAXIS.PLUS has reason to suspect that unlawful activity has been, is being or may be engaged in, and uses or discloses the personal information as a necessary part of its investigation of the matter or in reporting its concerns to relevant persons or authorities;
                </li>
                <li>
                  the use or disclosure is required or authorised by or under law; or
                </li>

                <li>
                  TAXIS.PLUS reasonably believes that the use or disclosure is reasonably necessary for one or more of the following by or on behalf of an enforcement body:
                  <ol type="I" className="mt-10 mb-20">
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      the prevention, detection, investigation, prosecution or punishment of criminal offences, breaches of a law imposing a penalty or sanction or breaches of a prescribed law;
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      the enforcement of laws relating to the confiscation of the proceeds of crime;
                    </li>
                  <li className="mb-10" style={{fontSize:"18px"}}>
                      the protection of the public revenue;
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      the prevention, detection, investigation or remedying of seriously improper conduct or prescribed conduct; or
                    </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                      the preparation for, or conduct of, proceedings before any court or tribunal, or implementation of the orders of a court or tribunal.
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                If TAXIS.PLUS uses or discloses personal information under paragraph (h), it must make a written note of the use or disclosure. The above operates in relation to personal information that TAXIS.PLUS has collected from a related body corporate as if TAXIS.PLUS’s primary purpose of collection of the information were the primary purpose for which the related body corporate collected the information.
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Kind of Information</h3>
              <p>
                TAXIS.PLUS will only seek personal information relevant to its business relationship with you. When you apply for TAXIS.PLUS, TAXIS.PLUS may request information that identifies you and information about your financial position and history. In most cases and where possible personal information about an individual will be collected directly from that individual. The most common way TAXIS.PLUS collects personal information is from TAXIS.PLUS application forms. 
              </p>
              <p>TAXIS.PLUS collects location data from "Taxis.Plus Driver" and "Taxis.Plus" (for Passenger). The Driver data is collected for as long as the driver is logged in and is used for security and trip tracking, as well as determination of fares. Taxis.Plus (for Passengers) only collects location data when a trip, estimate or other functions are requested that require accurate location.</p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Data Quality</h3>
              <p>
                TAXIS.PLUS will take reasonable steps to make sure that the personal information it collects uses or discloses is accurate, complete and up-to-date. 
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Data Security</h3>
              <p className="mb-20">
                TAXIS.PLUS will take reasonable steps to protect the personal information it holds from misuse and loss and from unauthorised access, modification or disclosure. 
              </p>
              <p>
                TAXIS.PLUS will take reasonable steps to destroy or permanently de-identify personal information if it is no longer needed for any purpose for which the information may be used or disclosed under Australian Privacy Principles. 
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Openness</h3>
              <p>
                On request by a person, TAXIS.PLUS will take reasonable steps to let the person know, generally, what sort of personal information it holds, for what purposes, and how it collects, holds, uses and discloses that information. 
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Access &amp; Correction</h3>
              <p className="mb-20">
                If TAXIS.PLUS holds personal information about an individual, it will provide the individual with access to the information on request by the individual, except to the extent that:

                </p><ol className="mb-30" style={{fontSize:"20px"}} type="a">
                  <li>
                    in the case of personal information other than health information – providing access would pose a serious and imminent threat to the life or health of any individual;
                  </li>
                  <li>
                    in the case of health information – providing access would pose a serious threat to the life or health of any individual;
                  </li>
                  <li>
                    providing access would have an unreasonable impact upon the privacy of other individuals;
                  </li>
                  <li>
                    the request for access is frivolous or vexatious;
                  </li>
                  <li>
                    the information relates to existing or anticipated legal proceedings between TAXIS.PLUS and the individual, and the information would not be accessible by the process of discovery in those proceedings;
                  </li>
                  <li>
                    providing access would reveal the intentions of TAXIS.PLUS in relation to negotiations with the individual in such a way as to prejudice those negotiations;
                  </li>
                  <li>
                    providing access would be unlawful;
                  </li>
                  <li>
                    denying access is required or authorised by or under law;
                  </li>
                  <li>
                    providing access would be likely to prejudice an investigation of possible unlawful activity;
                  </li>
                  <li>
                    providing access would be likely to prejudice:
                  <ol type="I" className="mt-10 mb-20">
                      <li className="mb-10" style={{fontSize:"18px"}}>
                        the prevention, detection, investigation, prosecution or punishment of criminal offences, breaches of a law imposing a penalty or sanction or breaches of a prescribed law;
                      </li>
                    <li className="mb-10" style={{fontSize:"18px"}}>
                        the enforcement of laws relating to the confiscation of the proceeds of crime;
                      </li>
                      <li className="mb-10" style={{fontSize:"18px"}}>
                        the protection of the public revenue;
                      </li>
                      <li className="mb-10" style={{fontSize:"18px"}}>
                        the prevention, detection, investigation or remedying of seriously improper conduct or prescribed conduct;
                      </li>
                      <li className="mb-10" style={{fontSize:"18px"}}>
                        the preparation for, or conduct of, proceedings before any court or tribunal, or implementation of its orders; or
                      </li>
                      <li className="mb-10" style={{fontSize:"18px"}}>
                        by or on behalf of an enforcement body; or
                      </li>
                    </ol>
                  </li>
                  <li>
                    an enforcement body performing a lawful security function asks TAXIS.PLUS not to provide access to the information on the basis that providing access would be likely to cause damage to the security of Australia.
                  </li>
                </ol>
              <p></p>
              <p className="mb-20">
                However, where providing access would reveal evaluative information generated within TAXIS.PLUS in connection with a commercially sensitive decision-making process, TAXIS.PLUS may give the individual an explanation for the commercially sensitive decision rather than direct access to the information.
              </p>
              <p className="mb-20">
                If TAXIS.PLUS is not required to provide the individual with access to the information because of one or more of paragraphs above, TAXIS.PLUS must, if reasonable, consider whether the use of mutually agreed intermediaries would allow sufficient access to meet the needs of both parties.
              </p>
              <p className="mb-20">
                If TAXIS.PLUS charges for providing access to personal information, those charges:
                </p><ol type="a" className="mb-30 font-weight-bold" style={{fontSize:"20px"}}>
                  <li>must not be excessive; and</li>
                  <li>must not apply to lodging a request for access.</li>
                </ol>
              <p></p>
              <p className="mb-20">
                If TAXIS.PLUS holds personal information about an individual and the individual is able to establish that the information is not accurate, complete and up-to-date, TAXIS.PLUS will take reasonable steps to correct the information so that it is accurate, complete and up-to-date. If the individual and TAXIS.PLUS disagree about whether the information is accurate, complete and up-to-date, and the individual asks TAXIS.PLUS to associate with the information a statement claiming that the information is not accurate, complete or up-to-date, TAXIS.PLUS will take reasonable steps to do so. TAXIS.PLUS will provide reasons for denial of access or a refusal to correct personal information.
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Identifiers</h3>
              <p className="mb-20">
                TAXIS.PLUS will not adopt as its own identifier of an individual an identifier of the individual that has been assigned by:
                </p><ol type="a" className="mb-30 font-weight-bold" style={{fontSize:"20px"}}>
                  <li>
                    an agency; or
                  </li>
                  <li>
                    an agent of an agency acting in its capacity as agent; or
                  </li>
                  <li>
                    a contracted service provider for the Commonwealth contract acting in its capacity as contracted service provider for that contract.
                  </li>
                </ol>
              <p></p>
              <p className="mb-20">
                However, the above does not apply to the adoption by a prescribed organisation of a prescribed identifier in prescribed circumstances. TAXIS.PLUS will not use or disclose an identifier assigned to an individual by an agency, or by an agent or contracted service provider mentioned above, unless:
                </p><ol type="a" className="mb-30 font-weight-bold" style={{fontSize:"20px"}}>
                  <li>
                    the use or disclosure is necessary for the organisation to fulfil its obligations to the agency; or
                  </li>
                  <li>
                    one or more of paragraphs under heading 3, numbers e) to h) (inclusive) apply to the use or disclosure; or
                  </li>
                  <li>
                    the use or disclosure is by a prescribed organisation of a prescribed identifier in prescribed circumstances.
                  </li>
                </ol>
              <p></p>
              <p className="mb-20">
                In this clause: “identifier” includes a number assigned by an organisation to an individual to identify uniquely the individual for the purposes of the organisation’s operations. However, an individual’s name or ABN is not an “identifier”.
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Anonymity</h3>
              <p className="mb-20">
                Wherever it is lawful and practicable, individuals will have the option of not identifying themselves when entering into transactions with TAXIS.PLUS.
              </p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Transborder Data Flows</h3>
              <p className="mb-30">
                TAXIS.PLUS is not likely to disclose personal information to overseas recipients. TAXIS.PLUS may transfer personal information about an individual to someone (other than to TAXIS.PLUS or the individual) who is in a foreign country only if:
                </p><ol type="a" className="mb-30" style={{fontSize:"20px"}}>
                  <li>
                    TAXIS.PLUS reasonably believes that the recipient of the information is subject to a law, binding scheme or contract which effectively upholds principles for fair handling of the information that are substantially similar to the Australian Privacy Principles; or
                  </li>
                  <li>
                    the individual consents to the transfer; or
                  </li>
                  <li>
                    the transfer is necessary for the performance of a contract between the individual and TAXIS.PLUS, or for the implementation of pre-contractual measures taken in response to the individual’s request; or
                  </li>
                  <li>
                    the transfer is necessary for the conclusion or performance of a contract concluded in the interest of the individual between TAXIS.PLUS and a third party; or
                  </li>
                  <li>
                    all of the following apply:
                    <ol type="I" className="mt-10 mb-30" style={{fontSize:"18px"}}>
                      <li>
                        the transfer is for the benefit of the individual;
                      </li>
                      <li>
                        it is impracticable to obtain the consent of the individual to that transfer;
                      </li>
                      <li>
                        if it were practicable to obtain such consent, the individual would be likely to give it; or
                      </li>
                    </ol>
                  </li>
                  <li>
                    TAXIS.PLUS has taken reasonable steps to ensure that the information which it has transferred will not be held, used or disclosed by the recipient of the information inconsistently with the Australian Privacy Principles.
                  </li>
                </ol>
              <p></p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Sensitive Information</h3>
              <p className="mb-20">
                TAXIS.PLUS will not collect sensitive information about an individual unless:
                </p><ol type="a" className="mb-30" style={{fontSize:"20px"}}>
                  <li>
                    the individual has consented; or
                  </li>
                  <li>
                    the collection is required by law; or
                  </li>
                  <li>
                    the collection is necessary to prevent or lessen a serious and imminent threat to the life or health of any individual, where the individual whom the information concerns:
                    <ol type="I" className="mt-10 mb-30" style={{fontSize:"18px"}}>
                      <li>
                        is physically or legally incapable of giving consent to the collection; or
                      </li>
                      <li>
                        physically cannot communicate consent to the collection.
                      </li>
                    </ol>
                  </li>
                </ol>
              <p></p>
            </li>

            <li className="mb-20" >
              <h3 className="h3 mb-20 font-weight-bold">Complaints</h3>
              <p className="mb-20">
                If you believe that TAXIS.PLUS has not complied with its obligations concerning your personal information, you should contact TAXIS.PLUS and lodge a complaint. TAXIS.PLUS will investigate your complaint and attempt to resolve any breach that may have occurred in relation to the collection, use or destruction of your personal information held by TAXIS.PLUS in accordance with the Privacy Act 1988 and related legislation.
              </p>
              <p className="mb-20">
                If you are not satisfied with the outcome of the investigation you can lodge a privacy complaint externally by contacting the Office of the Australian Information Commissioner on 1300 363 992.
              </p>
            </li>
          </ol>
        </div>
    </section>
    <Footer/>
  </>

    )}


 