import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../footer/Footer';
// import { Form } from './sections/Form';

export function Operators() {
    return (
      <>
        <section className="section-yellow home-section" id="home">
          <div className="container">
            <h1 className="margin-top-30">Welcome to Operators - Taxi Service Providers</h1>
            <p>Being an Operator, now known as a Taxi Service Provider, used to have certainty and profitability. Fares went up each year by the Consumer Price Index ; Plates were limited and increased in value - up to $400,000 in Sydney ; You could get 12 Shifts a week, with experienced Drivers ; Expenses were manageable. A great business that has been lost in the "reforms" intended to provide a Level Playing Field and as a welcome to Rideshare.</p>
            <p>In 2023 the NSW State Government  "assisted" Taxi Plate Licensees / Owners with a $150,000 package for each of up to six Taxis. If they wanted to continue in the Industry, then they got a new License to operate the business. They can't "lease" it out.</p>
            <p>Since August 2023 anyone is able to get a Plate for $200 a year, and to operate as a Taxi. Being a member of the existing Networks can cost up to $10,000 a year. Taxis.Plus has Zero Network Fees.</p>            <p>Now it's an uncertain Industry, and to make a profit you must explore every possible cost saving.  TAXIS.PLUS is a major change, and it can provide certainty and profitability. But you need be careful. That's why we are releasing a version that enables you and your Driver's participation before you, as the Operator, changes Networks @ ZERO COST.</p>
            <p>Now it's an uncertain Industry, and to make a profit you must explore every possible cost saving. TAXIS.PLUS is a major change, and it can provide certainty and profitability. But you need be careful. That's why we are releasing a version that enables you and your Driver's participation before you, as the Operator, changes Networks @ ZERO COST.</p>
            <p><u>What we offer:</u></p> 
            <ul>
              <li>An Android Tablet that performs all functions ; Meter, Mapping, Dispatch, Payment, Security. One device to replace all the junk in your cab.</li>
              <li>Prepaid SIM for data.</li>
              <li>Central and Secure Mounting for the Tablet.</li>
              <li>A NFC Card Reader built -in for use by unregistered passengers</li>
              <li>A new Roof Sign, 360° external camera coverage and Vacant Light</li>
              <li>Internal Camera coverage at P2P standards.</li>
              <li>QR Codes to provide easy Passenger Link-up</li>
            </ul>
            <p><u>What we Charge:</u></p>
            <ul>
              <li>$3000 once off, per Registered Vehicle</li>
              <li>Less if you convert your compatible Cameras and Roof Bar</li>
              <li>NO NETWORK FEES FOREVER.</li>
              <li></li>
            </ul>
            <p><u>You also get:</u></p>
            <ul>
              <li>Probable Reductions on Comprehensive and CTP Insurance.</li>
              <li>Full Reporting on all jobs.</li>
              <li>24/7 Duress and Alarm Monitoring</li>
              <li>Vehicle and Driver Management.</li>
              <li>Notifiable Accident Reporting with access to external Camera footage.</li>
            </ul>
            
            <p>But for starters - just install TAXIS.PLUS on a Smartphone or your own Tablet and run with your existing Network until you are ready and certain to change. It's free. The breakpoint is when the taxi gets more work through TAXIS.PLUS than from its registered Network. That's when you can really start saving on "radio fees".</p>
            <p>A bit more explanation. Our TAXI.PLUS system is designed afresh from the roadway up. It's user friendly for the three users - Passengers, Drivers and Operators. It's designed for optimal Safety for all three.  It means that the Operator can be more sure of the roadworthiness of his Taxi. The issue at first is that Drivers want to drive a taxi that gets booked jobs. That tends to give the existing Networks an advantage, but in a short time, that will change as Passengers, always a fickle lot, vote with their Mobiles to use TAXIS.PLUS. That's when you change Networks.</p>
            <p>Security is the other issue. TAXIS.PLUS gives a new approach for Drivers and Passengers. It's live and accessible, and can be directed to multiple recipients. It also can display the internal view on the screen as a deterrent. That was a recommendation of a now long-ago Safety Inquiry, which until now has never been implemented. There is no cost to get an Authorised Download, and no down-time. The 360° rooftop cameras assist with Insurance Claims and can also assist with Traffic Infringement Disputes. Our QR Code, and your Driver's involvement helps make it easy to link up Passengers. And if Covid returns we are ready to track.</p>
            <p>The query most Operators have is why can't they use their existing and expensive Camera system. They can - provided that it is compatible and connected to our system. You can reduce the cost by $1500. In a new vehicle you will also recover costs through Comprehensive Insurance discounts on having accessible video reports in an accident. Taxis.Plus lets you track your Taxi at any time.</p>
            <p>If you already own a compatible dual SIM Tablet, you can use it. We use our own Tablet to get the data going to where we have access and control, but you can still use your own Device and Camera set-up. If it works.</p>
            <p>If you have an existing Camera set-up, you can get Marewan in Fivedock to install and connect our Tablet to the Cameras, for $1500</p>
            <p>Fill in the Registration Form to become a Taxis.Plus Operator, or even set up your own Fleet under our Network. Then you are ready to Register your Vehicles with all their details ; and again, when you are ready to change Networks and start big savings on Fees, you will be able to go fully live. The only cost is $100 for a set of Decals / Signage</p>
            {/* <p>Just go to <a href="https://admin.taxis.plus" target="_blank" rel="noopener noreferrer">admin.taxis.plus</a>, enter your e-mail and password and you are in charge.</p> */}

            <Link to="/operators/register/" className="register-submit" type="submit">Register</Link>
          </div>
        </section>
        <Footer />
      </>
    );
}
