import React from 'react';
import ReactDOM from 'react-dom';
// import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { ApiService } from '../../../service/api.service';
import { useHistory } from 'react-router-dom';
import { authentication } from '../../../service/auth.service';
import { useState } from 'react';
import { CorporateUser } from '../../entities/corporate-user.entity';

interface props {
  passengerId?: string,
  corporateUser?: CorporateUser,
  bookingCode?: string,
  clientSecret: string,
  createBooking?: Function
}

export const CheckoutForm = ({ passengerId, bookingCode, clientSecret, createBooking, corporateUser }: props) => {

  // Pass the appearance object to the Elements instance
  //   const elements = stripe.elements({clientSecret, appearance});
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();
  const [cardholderName, setCardholderName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  }


  const appearance = {
    theme: 'night',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: '#0A2540',
      colorPrimary: '#EFC078',
      colorPrimaryText: '#1A1B25',
      colorText: 'white',
      colorTextSecondary: 'white',
      colorTextPlaceholder: '#727F96',
      colorIconTab: 'white',
      colorLogo: 'dark'
    },
    rules: {
      '.Input, .Block': {
        backgroundColor: 'transparent',
        border: '1.5px solid var(--colorPrimary)'
      }
    }
  };

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      setSuccessMessage(""); setLoading(true);
      setErrorMessage("");
      if (elements == null) {
        setLoading(false);
        return;
      }
      if (cardholderName == "") {
        setErrorMessage("Please enter the name of card holder")
        setLoading(false);
        return;
      }
      const cardElement = elements.getElement(CardElement);
      if (stripe && cardElement && elements) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: cardholderName,
          },
        });

        if (error) {
          console.log("error", error)
          setErrorMessage(error.message as string);
          setLoading(false);
          return;
        }

        const stripeResponse = await stripe
          .confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })
          if(stripeResponse?.error){
            setErrorMessage(stripeResponse?.error.message as string)
            setLoading(false);
            return 
          }

        let corporateAccountId = corporateUser?.corporateAccount?.id;
        switch (true) {
          case passengerId !== undefined:
            const response = await ApiService.post('passenger/addStripeCard', {
              passengerId,
              paymentId: stripeResponse.setupIntent?.payment_method
            });

            if (response.status == 201) {
              createBooking && createBooking();
              setLoading(false);
            }
            else {
              setLoading(false);
              setErrorMessage("Some issue in adding stripe card");
            }
            break
          case corporateAccountId !== undefined:

            const responseData = await ApiService.post('corporateAccount/addStripeCard', {
              corporateAccountId,
              paymentId: stripeResponse.setupIntent?.payment_method
            });

            if (responseData?.data?.error) {
              setLoading(false);
              setErrorMessage(responseData.data.error);
              return;
            }
            if (responseData.status == 201) {
              const corporate = authentication.getCorporateUser()
              corporate.corporateAccount = responseData.data;
              authentication.onAuthenticationCorporate(corporate);
              setLoading(false);
              setSuccessMessage("Card Added SuccessFully");
              setTimeout(() => {
                window.location.reload();
              }, 500)
            }
            else {
              setLoading(false);
              setErrorMessage("Some issue in adding stripe card")
            }
            break;
        }
      }
    }
    catch (error: any) {
      setLoading(false);
      console.log('Card ERROR:', error);
      console.log('Payment Error:', error?.response?.data?.message);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    // <form onSubmit={handleSubmit}>
    <form onSubmit={handleSubmit}>
      <input className="form-control bg-white inputshow" autoFocus placeholder="Name on Card" type="text" name='name' onChange={(e) => setCardholderName(e.target.value)} />
      <fieldset className="FormGroup inputshow">
        <div className="FormRow">
          <CardElement options={OPTIONS} className="form-control bg-white" />
        </div>
      </fieldset>
      {!loading ? <button disabled={!stripe || !elements} className='texisPlusBtn '>Add Card</button> :
        <button disabled className='texisPlusBtn '>Processing...</button>}
      {successMessage && <h4 style={{ color: 'green' }}>{successMessage}</h4>}
      {errorMessage && <h4 style={{ color: 'red' }}>{errorMessage}</h4>}
    </form>
    /* <CardElement options={OPTIONS} className="form-control bg-white" />
    <button type="submit" >
      Pay
    </button> */
    // </form>
  );
};