import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Column, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { ApiService } from '../../../service/api.service';
import { authentication } from '../../../service/auth.service';
import { Currency } from '../../entities/currency.entity';
import { BookingFilter } from '../../enums/core.enum';
import { debounce } from '../../util/function.util';
import './rides.css';
// import { Loading } from '../../../components/alerts/loading.component';
// import { Currency } from '../../../entities/currency.entity';
// import { Settings } from '../../../entities/settings.entity';
// import { Api, Endpoint } from '../../../services/api.service';
// import { AuthService } from '../../../services/auth.service';
// import { SettingsService } from '../../../services/settings.service';
// import { transactionReport } from './list.component';

interface Sort {
  sortDirection: string | undefined,
  accessor: string | undefined
}


interface props {
  isCorporate?: boolean | undefined,
  corporateColumn?: string[]
  bookingFilter: BookingFilter
  searchDisplay?: "show" | "hide" | undefined
  endpointForRowData?: string
  csvDisplay?: "show" | "hide" | undefined
  paginationDisplay?: "show" | "hide" | undefined,
  callBackMethod?: any,
  loading?: boolean

}

export function RideReport(props: props) {
  let history = useHistory();
  const [transaction, setTransaction] = useState<any[]>([])
  const [pageCounts, setPageCounts] = useState<number>(1);
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState<string>('')
  const [searchDisplay] = useState<string | undefined>(props?.searchDisplay)
  const [csvDisplay] = useState<string | undefined>(props?.csvDisplay)
  const [paginationDisplay] = useState<string | undefined>(props?.paginationDisplay)
  // const [_, setItemCount] = useState<number>(0)
  const [columnData, setColumnData] = useState<any>([]);
  const [sort, setSort] = useState<Sort>({ sortDirection: undefined, accessor: undefined });
  const [isCorporate] = useState(true)
  const [currencyData, setCurrencyData] = useState<Currency>();
  const [loading, setLoading] = useState<boolean>(false)
  const [intervalId, setIntervalId] = useState<any>();
  const [leftScrollIntervalId, setLeftScrollIntervalId] = useState<any>();
  const [bookingFilter] = useState<BookingFilter>(props.bookingFilter)
  const ref = useRef<any>(null);


  const toSubUnit = (value: number, currency: Currency): any => {
    const unit = currency ? currency.subunits : 100;
    const fixed = Math.round((Math.log(unit) / Math.log(10)));
    const result = (value / unit).toFixed(fixed)
    return result;
  }

  const dateFormate = (date: any) => {
    let d: any = new Date(date)
    return d.toLocaleString(undefined, { timeZone: "Australia/Sydney" });

  }

  let sum = 0;
  let sum1 = 0;
  let sum2 = 0;
  let sum3 = 0;
  let sum4 = 0;
  let sum5 = 0;
  let sum6 = 0;
  let sum7 = 0;
  let sum8 = 0;
  let sum9 = 0;
  let sum10 = 0;
  let sum11 = 0;
  let sum12 = 0;
  let sum13 = 0;
  let sum14 = 0;


  const refresh = useCallback(async (limit: number, offset: number, search?: string, direction?: string, column?: string, total?: any, sumVal?: any) => {
    setLoading(true);
    if (column && search) {
      let number;
      const unit = currencyData ? currencyData?.subunits : 100;
      // for distance
      if (column === 'TotalCCFee' || column === "StartCharge" || column === 'TotalFare' || column === 'GST' || column === 'Extras' || column === 'Levy' || column === 'Tolls' || column === 'BookingFee' || column === 'DiscountValue' || column === 'Tips' || column === 'ccCharge' || column === 'WaitTimeFare' || column === 'DistanceFare') {
        number = search.split(" ", 2)
        const cashValue = parseInt(number[1]) * unit
        search = `${number[0]} ${cashValue}`
      }
    }
    const corporateUser = authentication.getCorporateUser()
    let trans: any

    // if external endpoint is given
    if (props.endpointForRowData) {
      const { data } = await ApiService.get(`${[props.endpointForRowData]}`);
      trans = data
    }
    else {
      trans = await ApiService.get(`transaction/listAU/ride/?limit=${limit}&offset=${offset}&search=${search}&column=${column}&direction=${direction}&isCorporate=${isCorporate}&corporateAccount=${corporateUser.corporateAccount.id}&corporateUser=${corporateUser.id}&bookingFilter=${bookingFilter}`);
      trans = trans?.data
      setCurrencyData(trans.currency)
      // setItemCount(trans.count)
      const temp = Math.ceil(trans?.count / pageSize)
      // const tempOffset = count * pageSize
      setPageCounts(temp)

    }
    const currency = trans?.currency?.symbol ? trans?.currency?.symbol : null;
    let ro: any = [];
    switch (bookingFilter) {
      case BookingFilter.CorporateUser:
        if (trans?.length > 0) {
          (trans.forEach((key: any) => {
            ro.push({
              'Name': key?.name,
              "Email": key?.email,
              "Status": key?.status,
              "Phone": key?.phone,
              "Action": <div key={key.id}>
                <button className='btn btn-success' onClick={() => props.callBackMethod("edit", key.id)} style={{ marginRight: "5px" }}>Edit</button>
                <button className='btn btn-danger' onClick={() => props.callBackMethod("delete", key.id)}>Delete</button>
              </div>
            });
          }))
        }
        break
      case BookingFilter.OnGoingBooking:
        if (trans?.transaction && trans?.transaction.length > 0) {
          (trans?.transaction as any).forEach((key: any) => {
            sum = sum + (key?.trip_distance ? key?.trip_distance : 0);
            sum1 = sum1 + (key?.trip_waitCharge ? key?.trip_waitCharge : 0);
            sum2 = sum2 + (key?.trip_totalFare ? key?.trip_totalFare : 0);
            sum3 = sum3 + (key?.fare_distanceChargePerThreshold ? key?.fare_distanceChargePerThreshold : 0);
            sum4 = sum4 + (key?.trip_tax ? key?.trip_tax : 0);
            sum5 = sum5 + (key?.trip_extraCharge ? key?.trip_extraCharge : 0);
            sum6 = sum6 + (key?.trip_tolls ? key?.trip_tolls : 0);
            sum7 = sum7 + (key?.trip_tip ? key?.trip_tip : 0);
            sum10 = sum10 + (key?.trip_ccCharge ? key?.trip_ccCharge : 0);
            sum8 = sum8 + (key?.trip_rideTime == null ? 0 : key?.trip_rideTime);
            sum9 = sum9 + (key.trip?.waitTime == null ? 0 : key?.trip_waitTime);
            sum11 = sum11 + (key?.trip_discountValue ? key?.trip_discountValue : 0);
            sum12 = sum12 + (key?.trip_levy ? key?.trip_levy : 0);
            sum13 = sum13 + (key?.trip_bookingCharge ? key?.trip_bookingCharge : 0);
            sum14 = sum14 + (key?.trip_startCharge ? key?.trip_startCharge : 0);

            ro.push({
              'BookingCode': key?.booking_code == null ? "" : key?.booking_code,
              'BookingStatus': key?.booking_status ? key?.booking_status : "N/A",
              'CreateTime': new Date(key?.booking_createTime).toLocaleString(),
              'TransactionID': key.shortId == null ? "" : key.shortId,
              'VehiclePlate': key?.vehicle_plate == null ? "N/A" : key?.vehicle_plate,   
              'Vehicle': key.vehicle_plate == null ? "N/A" : `${key.vehicle_color} ${key.vehicle_year} ${key.vehicle_make} ${key.vehicle_model}`,
              'StartTime': key?.trip_startTime == null ? "N/A" : dateFormate(key?.trip_startTime),
              'EndTime': `${key?.trip_endTime == null ? "N/A" : dateFormate(key?.trip_endTime)}`,
              'Distance': key?.trip_distance == null ? `N/A` : `${key?.trip_distance / 1000} km`,
              'WaitTime': `${key?.trip_waitTime == null ? "N/A" : new Date(key?.trip_waitTime * 1000).toISOString().substr(11, 8)}`,
              'StartCharge': key?.trip_startCharge == null ? "N/A" : `${currency} ${toSubUnit(key?.trip_startCharge, trans.currency)}`,
              'TotalCCFee': key?.trip_ccCharge == null ? "N/A" : `${currency} ${toSubUnit(key?.trip_ccCharge, trans.currency)}`,
              'TotalFare': key?.trip_totalFare == null ? "N/A" : `${currency} ${toSubUnit(key?.trip_totalFare, trans.currency)}`,
              'TotalTime': key.trip_rideTime == null ? "N/A" : new Date(key.trip_rideTime * 1000).toISOString().substr(11, 8),
              "GST": key.trip_tax == null ? "N/A" : `${currency} ${toSubUnit(key.trip_tax, trans.currency)}`,
              "Extras": key.trip_extraCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip_extraCharge, trans.currency)}`,
              "Levy": key.trip_levy == null ? "N/A" : `${currency} ${toSubUnit(key.trip_levy, trans.currency)}`,
              "Tolls": key.trip_tolls == null ? "N/A" : `${currency} ${toSubUnit(key.trip_tolls, trans.currency)}`,
              "BookingFee": key.trip_bookingCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip_bookingCharge, trans.currency)}`,
              "Type": key?.booking_type == null ? "N/A" : key?.booking_type,
              "RateType": key.trip_rateType == null ? "N/A" : key.trip_rateType,
              "DiscountType": key.trip_discountType == null ? "N/A" : key.trip_discountType,
              "DiscountValue": key.trip_discountValue == null ? "N/A" : `${currency} ${toSubUnit(key.trip_discountValue, trans.currency)}`,
              "Tips": key.trip_tip == null ? "N/A" : `${currency} ${toSubUnit(key.trip_tip, trans.currency)}`,
              "ccCharge": key.trip_ccCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip_ccCharge, trans.currency)}`,
              'WaitTimeFare': key.trip_waitCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip_waitCharge, trans.currency)}`,
              'DistanceFare': key?.fare_distanceChargePerThreshold == null ? "N/A" : `${currency} ${toSubUnit(key?.fare_distanceChargePerThreshold, trans.currency)}`,
              "Service": key?.service_title == null ? "N/A" : key?.service_title,
              'Zone': key?.zone_title == null ? "N/A" : key?.zone_title,
              'Fare': key?.fare_title == null ? "N/A" : key?.fare_title,
              'DriverID': key?.driver_firstName ? `${key?.driver_firstName} ${key?.driver_lastName}` : "N/A",
              'Passenger': key?.passenger_firstName ? `${key?.passenger_firstName} ${key?.passenger_lastName}` : key?.passenger_firstName == undefined ? "N/A" : `${key?.passenger_firstName} ${key?.passenger_lastName}`,
              'CompanyName': key?.corporateAccount_companyName == null ? "N/A" : `${key?.corporateAccount_companyName}`,
              'ScheduledTime': new Date(key?.booking_scheduledTimestamp).toLocaleString(),
              'Name': key?.name,
              "Email": key?.email,
              "status": key?.status,
              "Phone": key?.phone
            });
          })
          ro = [...ro, {

            'TripCode': "",
            'BookingStatus': "",
            'CreateTime': "",
            'TransactionID': "",
            'VehiclePlate': "",
            'StartTime': "",
            'EndTime': "",
            'DistanceInKilometers': <><strong> Sub Total : </strong>{new Intl.NumberFormat().format(sum / 1000)} Km</>,
            'WaitTime': <><strong> Sub Total : </strong> {new Date(sum9 * 1000).toISOString().substr(11, 8)}</>,
            'TotalFare': <><strong> Sub Total : </strong> {toSubUnit(sum2, trans.currency)}</>,
            'TotalTime': <><strong> Sub Total : </strong>{new Date(sum8 * 1000).toISOString().substr(11, 8)}</>,
            "GST": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum4, trans.currency)}</>,
            "Extras": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum5, trans.currency)}</>,
            "Levy": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum12, trans.currency)}</>,
            "Tolls": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum6, trans.currency)}</>,
            "BookingFee": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum13, trans.currency)}</>,
            "Type": "",
            "Tips": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum7, trans.currency)}</>,
            "TotalCCFee": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum10, trans.currency)}</>,
            'WaitTimeFare': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum1, trans.currency)}</>,
            'DistanceFare': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum3, trans.currency)}</>,
            'DiscountValue': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum11, trans.currency)}</>,
            "Service": "",
            'Zone': "",
            'Fare': "",
            'Driver': "",
            'Passenger': "",
          }]
        }
        break
      default:
        if (trans?.transaction && trans?.transaction.length > 0) {
          (trans?.transaction as any).map((key: any) => {
            sum = sum + (key.trip?.distance ? key.trip?.distance : 0);
            sum1 = sum1 + (key.trip?.waitCharge ? key.trip?.waitCharge : 0);
            sum2 = sum2 + (key.trip?.totalFare ? key.trip?.totalFare : 0);
            sum3 = sum3 + (key.trip?.fare.distanceChargePerThreshold ? key.trip?.fare.distanceChargePerThreshold : 0);
            sum4 = sum4 + (key.trip?.tax ? key.trip?.tax : 0);
            sum5 = sum5 + (key.trip?.extraCharge ? key.trip?.extraCharge : 0);
            sum6 = sum6 + (key.trip?.tolls ? key.trip?.tolls : 0);
            sum7 = sum7 + (key.trip?.tip ? key.trip?.tip : 0);
            sum10 = sum10 + (key.trip?.ccCharge ? key.trip?.ccCharge : 0);
            sum8 = sum8 + (key.trip?.rideTime == null ? 0 : key.trip.rideTime);
            sum9 = sum9 + (key.trip?.waitTime == null ? 0 : key.trip.waitTime);
            sum11 = sum11 + (key.trip?.discountValue ? key.trip?.discountValue : 0);
            sum12 = sum12 + (key.trip?.levy ? key.trip?.levy : 0);
            sum13 = sum13 + (key.trip?.bookingCharge ? key.trip?.bookingCharge : 0);
            sum14 = sum14 + (key.trip?.startCharge ? key.trip?.startCharge : 0);

            ro.push({
              'BookingCode': key?.code == null ? "N/A" : key.code,
              'TripCode': key.trip?.code == null ? "N/A" : key.trip.code,
              'BookingStatus': key?.status ? key.status : key.trip?.booking?.status == null ? "N/A" : key.trip?.booking?.status,
              'CreateTime': new Date(key.createTime).toLocaleString(),
              'TransactionID': key.shortId == null ? "N/A" : key.shortId,
              'VehiclePlate': key.trip?.session?.vehicle?.plate == null ? "N/A" : key.trip?.session?.vehicle?.plate,
            'StartTime': key?.trip?.startTime == null ? "N/A" : dateFormate(key.trip?.startTime),
              'EndTime': `${key.trip?.endTime == null ? "N/A" : dateFormate(key.trip.endTime)}`,
              'Distance': key.trip?.distance == null ? `N/A` : `${key.trip.distance / 1000} km`,
              'WaitTime': `${key.trip?.waitTime == null ? "N/A" : new Date(key.trip.waitTime * 1000).toISOString().substr(11, 8)}`,
              'StartCharge': key.trip?.startCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip.startCharge, trans.currency)}`,
              'TotalCCFee': key.trip?.ccCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip.ccCharge, trans.currency)}`,
              'TotalFare': key.trip?.totalFare == null ? "N/A" : `${currency} ${toSubUnit(key.trip.totalFare, trans.currency)}`,
              'TotalTime': key.trip?.rideTime == null ? "N/A" : new Date(key.trip.rideTime * 1000).toISOString().substr(11, 8),
              "GST": key.trip?.tax == null ? "N/A" : `${currency} ${toSubUnit(key.trip.tax, trans.currency)}`,
              "Extras": key.trip?.extraCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip.extraCharge, trans.currency)}`,
              "Levy": key.trip?.levy == null ? "N/A" : `${currency} ${toSubUnit(key.trip.levy, trans.currency)}`,
              "Tolls": key.trip?.tolls == null ? "N/A" : `${currency} ${toSubUnit(key.trip.tolls, trans.currency)}`,
              "BookingFee": key.trip?.bookingCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip.bookingCharge, trans.currency)}`,
              "Type": key.trip?.type == null ? "N/A" : key.trip.type,
              "RateType": key.trip?.rateType == null ? "N/A" : key.trip.rateType,
              "DiscountType": key.trip?.discountType == null ? "N/A" : key.trip.discountType,
              "DiscountValue": key.trip?.discountValue == null ? "N/A" : `${currency} ${toSubUnit(key.trip.discountValue, trans.currency)}`,
              "Tips": key.trip?.tip == null ? "N/A" : `${currency} ${toSubUnit(key.trip.tip, trans.currency)}`,
              "ccCharge": key.trip?.ccCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip.ccCharge, trans.currency)}`,
              'WaitTimeFare': key.trip?.waitCharge == null ? "N/A" : `${currency} ${toSubUnit(key.trip.waitCharge, trans.currency)}`,
              'DistanceFare': key.trip?.fare.distanceChargePerThreshold == null ? "N/A" : `${currency} ${toSubUnit(key.trip.fare.distanceChargePerThreshold, trans.currency)}`,
              "Service": key.trip?.service.title == null ? "N/A" : key.trip.service.title,
              'Zone': key.trip?.zone.title == null ? "N/A" : key.trip.zone.title,
              'Fare': key.trip?.fare.title == null ? "N/A" : key.trip.fare.title,
              'DriverID': key.driver ? `${key.driver.firstName} ${key.driver.lastName}` : key.trip?.session == null ? "N/A" : `${key.trip?.session.driver.firstName} ${key.trip.session.driver.lastName}`,
              'Passenger': key.passenger ? `${key.passenger?.firstName} ${key.passenger?.lastName}` : key.trip?.passenger?.firstName == undefined ? "N/A" : `${key.trip?.passenger?.firstName} ${key.trip?.passenger?.lastName}`,
              'CompanyName': key.trip?.booking?.corporateAccount?.companyName == null ? "N/A" : `${key.trip?.booking?.corporateAccount?.companyName}`,
              'ScheduledTime': new Date(key?.scheduledTimestamp).toLocaleString(),  
              'RepeatDays': key?.repeatBooking?.repeatDays.length>0?(key?.repeatBooking?.repeatDays.map((item:any,i:number)=>item.slice(0,3)+", ")):"N/A",
              'Name': key?.name,
              "Email": key?.email,
              "status": key?.status,
              "Phone": key?.phone
            });
          })
          ro = [...ro, {

            'TripCode': "",
            'BookingStatus': "",
            'CreateTime': "",
            'TransactionID': "",
            'VehiclePlate': "",
            'StartTime': "",
            'EndTime': "",
            'DistanceInKilometers': <><strong> Sub Total : </strong>{new Intl.NumberFormat().format(sum / 1000)} Km</>,
            'WaitTime': <><strong> Sub Total : </strong> {new Date(sum9 * 1000).toISOString().substr(11, 8)}</>,
            'TotalFare': <><strong> Sub Total : </strong> {toSubUnit(sum2, trans.currency)}</>,
            'TotalTime': <><strong> Sub Total : </strong>{new Date(sum8 * 1000).toISOString().substr(11, 8)}</>,
            "GST": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum4, trans.currency)}</>,
            "Extras": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum5, trans.currency)}</>,
            "Levy": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum12, trans.currency)}</>,
            "Tolls": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum6, trans.currency)}</>,
            "BookingFee": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum13, trans.currency)}</>,
            "Type": "",
            "Tips": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum7, trans.currency)}</>,
            "TotalCCFee": <><strong> Sub Total : </strong>{currency} {toSubUnit(sum10, trans.currency)}</>,
            'WaitTimeFare': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum1, trans.currency)}</>,
            'DistanceFare': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum3, trans.currency)}</>,
            'DiscountValue': <><strong> Sub Total : </strong>{currency} {toSubUnit(sum11, trans.currency)}</>,
            "Service": "",
            'Zone': "",
            'Fare': "",
            'RepeatDays': "",
            'Driver': "",
            'Passenger': "",

          }]
        }
    }
    setTransaction(ro)
    setLoading(false)
  }, [transaction])

  const data = React.useMemo(
    () => [...transaction],
    [transaction]
  )

  const scroll = (scrollOffset: any) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const handleRightScroll = () => {

    setIntervalId(
      setInterval(() => {
        scroll(200)
      }, 50)
    )
  }

  const handleLeftScroll = () => {

    setLeftScrollIntervalId(
      setInterval(() => {
        scroll(-200)
      }, 50)
    )
  }

  const columns: Array<Column<any>> = React.useMemo(
    () => {
      const col: any = [];
      if (columnData.length > 0) {
        let finalColumn = columnData;
        if(bookingFilter==BookingFilter.OnGoingBooking)
        // {col.push({
        //   // Make an expander cell
        //   Header: () => null, // No header
        //   id: 'expander', // It needs an ID
        //   Cell: (props: any): JSX.Element => (
        //     <span {...props.row.getToggleRowExpandedProps()}>
        //       {props.row.isExpanded ? '▼' : '▶'}
        //     </span>
        //   ),
        // })}
        finalColumn.map((column: any) => {
          col.push({
            Header: column,
            accessor: column?.replaceAll(" ", ""),
            sortType: 'basic',
            sortDirection: sort.accessor === column?.replaceAll(" ", "") ? sort.sortDirection : undefined
          })

        })
      }
      return col
    }

    , [columnData]
  )



  useEffect(() => {
    getColumnData()
  }, [])

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <div
      className='table-not-stripedd'
        style={{
          fontSize: '20px',  width: '100%'
        }}
      >
        <table className="table-dark table-not-striped" style={{ border: '0px',  width: '100%',  textAlign: 'left'}}>
          <tr style={{ border: '0px' }}>
            <td style={{ border: '0px' }}>Driver:</td>
            <td style={{ border: '0px' }}>{row.original.DriverID}</td>
            <td style={{ border: '0px' }}>Distance:</td>
            <td style={{ border: '0px' }}>{row.original.Distance}</td>
            <td style={{ border: '0px' }}>Start Charge:</td>
            <td style={{ border: '0px' }}>{row.original.StartCharge}</td>
          </tr>
          <tr style={{ border: '0px' }}>
          <td style={{ border: '0px' }}>Vehicle Plate:</td>
            <td style={{ border: '0px' }}>{row.original.VehiclePlate}</td>
            <td style={{ border: '0px' }}>Distance Charge:</td>
            <td style={{ border: '0px' }}>{row.original.DistanceFare}</td>
            <td style={{ border: '0px' }}>Booking Charge:</td>
            <td style={{ border: '0px' }}>{row.original.BookingFee}</td>
          </tr>
          <tr>
          <td style={{ border: '0px' }}>Vehicle:</td>
            <td style={{ border: '0px' }}>{row.original.Vehicle}</td>
            <td style={{ border: '0px' }}>Wait Time:</td>
            <td style={{ border: '0px' }}>{row.original.WaitTime}</td>
            <td style={{ border: '0px' }}>GST:</td>
            <td style={{ border: '0px' }}>{row.original.GST}</td>
          </tr>
          <tr style={{ border: '0px' }}>
          <td style={{ border: '0px' }}>Booking Time:</td>
            <td style={{ border: '0px' }}>{row.original.TotalTime}</td>
            <td style={{ border: '0px' }}>Wait Time Charge:</td>
            <td style={{ border: '0px' }}>{row.original.WaitTimeFare}</td>
            <td style={{ border: '0px' }}>Levy:</td>
            <td style={{ border: '0px' }}>{row.original.Levy}</td>
          </tr>
          <tr style={{ border: '0px' }}>
          <td style={{ border: '0px' }}>Trip Start Time:</td>
            <td style={{ border: '0px' }}>{row.original.StartTime}</td>
            <td style={{ border: '0px' }}>Rate Type:</td>
            <td style={{ border: '0px' }}>{row.original.RateType}</td>
            <td style={{ border: '0px' }}>Tip:</td>
            <td style={{ border: '0px' }}>{row.original.Tips}</td>
          </tr>
          <tr style={{ border: '0px' }}>
          <td style={{ border: '0px' }}>Trip Stop Time:</td>
            <td style={{ border: '0px' }}>{row.original.EndTime}</td>
            <td style={{ border: '0px' }}>Discount Type:</td>
            <td style={{ border: '0px' }}>{row.original.DiscountType}</td>
            <td style={{ border: '0px' }}>Tolls:</td>
            <td style={{ border: '0px' }}>{row.original.Tolls}</td>
          </tr>
          <tr style={{ border: '0px' }}>
          <td style={{ border: '0px' }}>Total Time:</td>
            <td style={{ border: '0px' }}>{row.original.TotalTime}</td>
            <td style={{ border: '0px' }}>Discount Value:</td>
            <td style={{ border: '0px' }}>{row.original.DiscountValue}</td>
            <td style={{ border: '0px' }}>Extras:</td>
            <td style={{ border: '0px' }}>{row.original.Extras}</td>
          </tr>
          <tr style={{ border: '0px' }}>
          <td style={{ border: '0px' }}></td>
            <td style={{ border: '0px' }}></td>
            <td style={{ border: '0px' }}>Fare:</td>
            <td style={{ border: '0px' }}>{row.original.DiscountType}</td>
            <td style={{ border: '0px' }}>CC Charge:</td>
            <td style={{ border: '0px' }}>{row.original.Tolls}</td>
          </tr>
         
        </table>
      </div>
    ),
    []
  )

  const getColumnData = async () => {

    let columnData: any

    switch (props.bookingFilter) {
      case BookingFilter.Booking:
        columnData = await ApiService.get("/report/rides/list");
        columnData = columnData?.data?.columns
        columnData = columnData.concat(["Company Name", "Booking Status"]);
        break
      case BookingFilter.OnGoingBooking:
        columnData=["Passenger","BookingCode","StartTime","Service","Zone","TotalFare"]
        break
      case BookingFilter.ScheduledBooking:
        columnData = props.corporateColumn;
        break
      case BookingFilter.CorporateUser:
        columnData = props.corporateColumn;
        break
    }
    setColumnData(columnData)
    setLoading(true)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    prepareRow,
    visibleColumns,
    state: { expanded },
  }: any = useTable({
    columns, data
  }, useFilters, useSortBy, useExpanded, usePagination)

  const { pageSize } = state

  const getPageCount = async () => {
    const tempOffset = count * pageSize
    refresh(pageSize, tempOffset, search, sort.sortDirection, sort.accessor)
  }
  const handler = useCallback(debounce((text: string) => {
    setSearch(text)
    setLoading(true)
  }, 2000), []);

  useEffect(() => {
    getPageCount()
  }, [search, pageSize, count, sort, props?.loading])

  const onNextPage = () => {
    setCount(prevCount => prevCount + 1)
  }

  const onPreviousPage = () => {
    setCount(prevCount => prevCount - 1)
  }

  const onNextNextPage = () => {
    setCount(pageCounts - 1)
  }

  const onPreviousPreviousPage = () => {
    setCount(0)
  }



  const onClickHeader = (column: any) => {
    switch (column.sortDirection) {
      case undefined:
        column.sortDirection = 'ASC'
        setSort({ sortDirection: 'ASC', accessor: column.id });
        break;
      case 'ASC':
        column.sortDirection = 'DESC'
        setSort({ sortDirection: 'DESC', accessor: column.id });
        break;
      case 'DESC':
        column.sortDirection = undefined
        setSort({ sortDirection: undefined, accessor: column.id });
        break;
    }
  }

  const onChangeSeaech = (e: any, column: any) => {
    handler(e.target.value)
  }
  const clickMethod = (column: any) => {
    history.push(`/track/${column.original.BookingCode ? column.original.BookingCode : column.original.TripCode}`);
  }

  return (
    <>
      {transaction === undefined ?
        <div className="mx-auto py-5 text-center">
          <p>Please Wait</p>
          {/* <Loading loading={true} /> */}
        </div> :
        <div>
          <div style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "baseline"
          }} className=" py text-center">
        
        <div style={{
              textAlign: "end",
              flexGrow: "0.6"
        }}>
        <h1 
    
           >{authentication.getCorporateUser()?.corporateAccount?.companyName}</h1>
        </div>

        <div style={{
          flexGrow: "0.4"
        }}>
        {transaction ?
              (csvDisplay == 'hide' ? "" : <CSVLink style={{ float: "right", marginTop: "26px", marginRight: "20px" }} filename={"rides-report.csv"} className="btn btn-primary" data={transaction}>Export CSV</CSVLink>) : null}
            {bookingFilter == BookingFilter.CorporateUser ?
              <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={() => props.callBackMethod("add")}>Add New User</button>
              : null}
            <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={() => { scroll(200) }} onMouseDown={handleRightScroll} onMouseUp={() => clearInterval(intervalId)} onMouseLeave={() => clearInterval(intervalId)}>{'>'}</button>
            <button style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className='btn btn-primary' onClick={() => { scroll(-200) }} onMouseDown={handleLeftScroll} onMouseUp={() => clearInterval(leftScrollIntervalId)} onMouseLeave={() => clearInterval(leftScrollIntervalId)}>{'<'}</button>
            {loading == true ? <button disabled onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary`}>
              Loading.....</button> : <button onClick={(e) => { e.preventDefault(); getPageCount() }} style={{ float: "right", marginTop: "26px", marginRight: "20px" }} className={`btn btn-primary os-icon os-icon-refresh-ccw`}></button>}

        </div>
            
            
          </div>
          <div ref={ref} style={{ marginLeft: "5px", marginRight: "5px", overflowX: "scroll", width: "100%" }} className=" py-5 text-center r2">
            <table {...getTableProps()} className="table table-striped table-Info table-hover table-bordered">
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr style={{ whiteSpace: 'nowrap' }} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => onClickHeader(column)} className=" text-white" style={{ backgroundColor: "black", whiteSpace: 'nowrap' }}>
                        {column.render('Header')}
                        <span>
                          {column.sortDirection === 'ASC' ? (
                            ' 🔽'
                          ) : column.sortDirection === 'DESC' ? (
                            ' 🔼'
                          ) : null}
                        </span>
                        <div><span>
                          {column?.id!=='expander' ?searchDisplay == "hide" ? "" : <input onChange={(e) => onChangeSeaech(e, column)}
                          />:null}
                        </span></div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {page.length>0?<tbody {...getTableBodyProps()}>
                {page.map((row: any,index:number) => {
                  prepareRow(row)
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ whiteSpace: 'nowrap' }} {...row.getRowProps()}
                      // onClick={() =>
                      //   bookingFilter === BookingFilter.CorporateUser ? "" : clickMethod(row)}
                      {...row.getToggleRowExpandedProps()}
                      >
                        {row.cells.map((cell: any) => {
                          return (
                            <td style={{ whiteSpace: 'nowrap' }}
                              {...cell.getCellProps()}
                            >
                              {(transaction.length-1==row.index&&cell.column.id=='expander')?null:cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                      {row.isExpanded ? (
                        <tr>
                          <td colSpan={8}>
                            {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                            {renderRowSubComponent({ row })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>)
                })}
              </tbody>:<tbody>
              <tr >
                <td colSpan={columnData.length}>
                No results to display
                </td>
              </tr>
            </tbody>}
            </table>
          </div>
          {paginationDisplay == 'hide' ? "" :
            <div className=" py-5 text-center">
              <span>
                Page{' '}
                <strong>
                  {count + 1} of {pageCounts}
                </strong>{' '}
              </span>
              <span>
                | Go to page:{''}
                <input
                  type='number'
                  defaultValue={count + 1}
                  onChange={(e: any) => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                    setCount(pageNumber)
                  }}
                  style={{ marginRight: "10px", width: "70px", marginLeft: "5px", borderRadius: "5px" }}
                />
              </span>
              <select
                value={pageSize}
                onChange={(e: any) => setPageSize(Number(e.target.value))}
                style={{ marginRight: "10px", width: "80px", marginLeft: "5px", borderRadius: "5px" }}
              >
                {[10, 25, 50, 100].map((pageSize: any) => {
                  return <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                })}
              </select>
              <button type="button" style={{ backgroundColor: "black" }} className="btn btn-primary" onClick={() => onPreviousPreviousPage()} disabled={count > 0 ? false : true}>{'<<'}</button>
              <button type="button" style={{ marginRight: "10px", marginLeft: "10px", backgroundColor: "black" }} className="btn btn-primary" onClick={() => onPreviousPage()} disabled={count > 0 ? false : true}>Previous</button>
              <button type="button" style={{ marginRight: "10px", backgroundColor: "black" }} className="btn btn-primary" onClick={() => onNextPage()} disabled={(count + 1) < pageCounts ? false : true}>Next</button>
              <button type="button" style={{ backgroundColor: "black" }} className="btn btn-primary" onClick={() => onNextNextPage()} disabled={(count + 1) < pageCounts ? false : true}>{'>>'}</button>
            </div>}
        </div>
      }
    </>
  )

}


