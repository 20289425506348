import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../footer/Footer'

export function Links() {
    return (
        <>
           <section className="section-yellow section-bottom-border feature-section" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Links</h1>
                        </div>
                    </div>
                    <div>
                        <div className="nav flex-column nav-pills margin-right-25">

                            <a className="nav-link" href={ process.env.PUBLIC_URL + '/docs/ion-generator.pdf' } target="_blank">
                                <div className="features-second">
                                    <h4 className="margin-bottom-5">Ion Generator</h4>
                                    <p>Cold Plasma. Biological Protection for Taxi Drivers.</p>
                                </div>
                            </a>

                            <Link to="/privacy-policy/" className="nav-link">
                                <div className="features-second">
                                    <h4 className="margin-bottom-5">Privacy Policy</h4>
                                    <p>Please read our privacy policy carefully before downloading our application and/or using our service.</p>
                                </div>
                            </Link>

                            <Link to="/terms-conditions/" className="nav-link">
                                <div className="features-second">
                                    <h4 className="margin-bottom-5">Terms &amp; Conditions</h4>
                                    <p>Please read our terms and conditions carefully before downloading our application and/or using our service.</p>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
} 