import React from 'react';

export function AboutContent() {
    return (
        <section id="about-content" className="about-section">
            
            <div className="container" >
                <h1 className="section-title margin-top-30 margin-bottom-20">TAXIS.PLUS
                    <span className="h4 d-block section-title">Sydney's Newest Taxi Network</span>
                </h1>
                <h5 className="section-subtitle">Our Expectation: That our Drivers are Professional Taxi Drivers.</h5>
                <h5 className="mb-30 section-subtitle">Our Rule: If they accept job, they do the job</h5>
            </div>

            <div className="container text-justify">
                <p className="mb-30 section-subtitle">Worldwide the Taxi Industry needs Change. Uber brought Disruption, the Pandemic brought Destruction, Taxis.Plus brings a way forward.</p>
                <p className="mb-30 section-subtitle">TAXIS.PLUS is about Innovation and a totally new, end-to-end, Operating and Security System.</p>
                <p className="mb-30 section-subtitle">TAXIS.PLUS in its Sydney, Australian start-up is proud to be part of the Optimal Point to Point Passenger Service.</p>
                <p className="mb-30 section-subtitle">As the World emerges from the COVID 19 Pandemic, and Global Economies re-start, so will Taxis as probably the safest form of Public Transport. Our new Operating and Security Systems will be the best way for all participants to maintain Standards of Health, Reliability, Security and above all, of Service.</p>
                <p className="mb-30 section-subtitle">The Drivers and Operators will have the most advanced Booking, Despatch, Payment and Security programs at their disposal. You, the Passenger, will have a universal APP, which, with one tap, will e-hail a Taxi. You can also Book a taxi and speak in your Pick-up and Destination and get a Fare Estimate. You can Book a Delivery Service, such as picking up a take away meal. You can talk to a real live person. You can Link a Rank or Hail pick-up to our system for Payment. Scan the QR Code and register the trip as a Registered Passenger, or to join-up.</p>
                <p className="mb-30 section-subtitle">All of this has taken a lot of time and a skilled team to create a system that, we believe, meets, covers and exceeds all needs and wishes for all of us. Not just seeing your Taxi on its way to you, but also being in direct contact with the Driver. Of having detailed e-mail receipt / invoices. Of a full Audio / Video recording of your trip securely stored for Authorised access if required.</p>
                <p className="section-subtitle">Costs and Expenses are reduced - immediately for Drivers and Operators who are in financial despair at this time, and as we expand coverage, their savings will be passed on by lower fares to Passengers.</p>
            </div>

        </section>
    )

} 
