import React from 'react';

export function Features() {
   return (
      <section className="section-yellow section-bottom-border" id="features">
         <div className="container">
            <div className="row">
               <div className="col-md-12 text-center padding-bottom-10">
                  <h2 className="section-title">Amazing Features</h2>
                  <p className="section-subtitle">Wide ranging features available to provide you with the flexibility and convenience you deserve.</p>
               </div>
            </div>
            <div className="row">
               <h4>Book on the app</h4>
               <p>
                  Our APP is user friendly. Tap the TAXIS.PLUS icon. Speak or Type your Destination and select the choice. Tap the Service Type – TAXI  ..  MAXI CAB  ..  WATS TAXI  .. DELIVERY. You get an instant Fare Estimate. Tap the Arrow and you see available cabs and their Driver details. Select your Taxi. That's it ! <br/>
                  You can see the Taxi coming. You can talk to the Driver. From the Settings Button you can list past trips in detail, you can add or change Credit Card details, and more.
               </p>
               <p>
                  As a first time Passenger, or from a Street Hail, just scan the QR Code on the meter, and you are welcomed into TAXIS.PLUS with minimal personal details, and  the current trip gets linked to your Account / Credit Card.
               </p>
               <p>
                  If you just tap the Arrow, you get the nearest Taxi. Obviously we can't give you a fare estimate, but you get the nearest Taxi who accepts the Booking. Much better to give a Destination !
               </p>
            </div>
            <div className="row">
               <div className="col-md-4">
                  <div className="feature-box wow">
                     <i className="fa fa-id-card-o"></i>
                     <h4>Pick your Driver</h4>
                     <p>While our patented algorithm picks the best suited Driver and Taxi, you can also pick your own favourite Driver or Group to suit your preferences, or change the Vehicle type.</p>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="feature-box wow">
                     <i className="fa fa-shield"></i>
                     <h4>Security</h4>
                     <p>Your ride is fully recorded on-line and a passenger alarm is a part of the App. The trip and all in-taxi activities are available for authorized access</p>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="feature-box wow">
                     <i className="fa fa-rocket"></i>
                     <h4>Invoice</h4>
                     <p>A tax invoice / receipt will be provided by email immediately at end of trip with all trip details.</p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-4">
                  <div className="feature-box wow">
                     <i className="fa fa-globe"></i>
                     <h4>Extensive Network</h4>
                     <p>Our network of Taxis cover Sydney with professional, trusted and trained Drivers. Soon it will be Australia wide.</p>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="feature-box wow">
                     <i className="fa fa-medkit"></i>
                     <h4>Support 24/7</h4>
                     <p>You can call through our App or on the hotline at +61 435 191 400 for help or support, and to pass on Compliments or Complaints. We are at your Service.</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}
