import React, { useCallback, useEffect, useState } from 'react';
import trees from '../../img/Trees.png';
import print from '../../img/print.png';
import logo from '../../img/taxisplus-logo.png';
import { ApiService } from '../../service/api.service';
import { Driver, Session, Trip, Vehicle, paidBy, tripIds } from './passengerDetails';

export const TripReceipt = () => {

    const [selectedTrip, setSelectedTrip] = useState<Trip>()
    const [selectedSession, setSelectedSession] = useState<Session>()
    const [selectedDriver, setSelectedDriver] = useState<Driver>()
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>() 

    const refresh = useCallback(async () => {
        const trip = await ApiService.post(`trip`, { id: tripIds })
        setSelectedTrip(trip.data)
        const session = selectedTrip?.session as Session
        setSelectedSession(session)
        const driver = selectedSession?.driver as Driver
        setSelectedDriver(driver)
        const vehicle = selectedSession?.vehicle as Vehicle
        setSelectedVehicle(vehicle)
    }, [selectedTrip])

    useEffect(() => {
        refresh()
    }, [refresh])

    const printOnClick = () =>{
        window.print()
    }

    return (
        <>
            <div className='header backgroundOrange inverted-border-radius'>
                <div className="row">
                    <div className="Headercolumnleft">
                        <div className="row">
                            <div style={{ marginLeft: 80, paddingTop: 15 }}>
                                <img className='headerLogo' src={logo} style={{ height: 30 }} alt="taxis plus" />
                            </div>
                        </div>
                        <div className="row">
                            <div style={{ marginLeft: 75 }}>
                                <img src={trees} alt="taxis-plus" height="100" />
                            </div>
                        </div>
                    </div>
                    <div className="headercolumnright">
                        <h1 style={{ paddingTop: 50 }}>Thanks for riding with us!</h1>
                    </div>
                </div>
            </div>
            <div style = {{position:'absolute', right:50, top:150, cursor:'pointer'}} className="row">
                <img role = 'button' onClick = {printOnClick} src={print} alt="taxis plus" />
            </div>
            <div className="content">
                <div style={{ marginLeft: 20 }} className="row">
                    <div className="column">
                        <div style={{ padding: 15 }}>
                            <h4 style={{ padding: 5, margin: 0 }}>TRIP</h4>
                            <div className='whiteBox' style={{margin:'20px'}}>
                                <div className="row">
                                    <div className="column" style={{ width: '20%' }}>
                                        <p>Code</p>
                                    </div>
                                    <div className="column" style={{ width: '75%' }}>
                                        <p><b>{selectedTrip?.code}</b></p>
                                    </div>
                                </div>
                                <hr style={{ color: '#D3D3D3' }} />
                                <div className="row">
                                    <div className="column" style={{ width: '20%' }}>
                                        <p>Driver</p>
                                    </div>
                                    <div className="column" style={{ width: '75%' }}>
                                        <p><b>{selectedDriver?.firstName} {selectedDriver?.lastName}</b></p>
                                    </div>
                                </div>
                                <hr color='#D3D3D3' />   <div className="row">
                                    <div className="column" style={{ width: '20%' }}>
                                        <p>Driver ABN</p>
                                    </div>
                                    <div className="column" style={{ width: '75%' }}>
                                        <p><b>{selectedDriver?.businessRegistration}</b></p>
                                    </div>
                                </div>
                                <hr color='#D3D3D3' />
                                <div className="row">
                                    <div className="column" style={{ width: '20%' }}>
                                        <p>Vehicle</p>
                                    </div>
                                    <div className="column" style={{ width: '75%' }}>
                                        <p><b>{selectedVehicle?.plate}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: 15 }}>
                            <h4 style={{ padding: 5, margin: 0 }}>Drive</h4>
                            <div className='whiteBox'  style={{margin:'20px'}}>
                                <div className="row">
                                    <div className="column" style={{ width: '20%' }}>
                                        <p>Distance</p>
                                    </div>
                                    <div className="column" style={{ width: '75%' }}>
                                        <p><b>{selectedTrip?.distance?(selectedTrip?.distance)/100:'0'}km</b></p>
                                    </div>
                                </div>
                                <hr color='#D3D3D3' />
                                <div className="row">
                                    <div className="column" style={{ width: '20%' }}>
                                        <p>Ride Time</p>
                                    </div>
                                    <div className="column" style={{ width: '75%' }}>
                                        <p><b>{selectedTrip?.rideTime?(selectedTrip?.rideTime)/60:'0'}m</b></p>
                                    </div>
                                </div>
                                <hr color='#D3D3D3' />
                                <div className="row">
                                    <div className="column" style={{ width: '20%' }}>
                                        <p>Wait Time</p>
                                    </div>
                                    <div className="column" style={{ width: '75%' }}>
                                        <p><b>{selectedTrip?.waitTime?(selectedTrip?.waitTime)/100:'0'}m</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div style={{ padding: 15 }}>
                            <h4 style={{ padding: 5, margin: 0 }}>Fare</h4>
                            <div className='whiteBox'>
                                <div className="row">
                                    <div className="column">
                                        <div className="row">
                                            <div className="column" style={{ width: '50%',margin:'0px',padding:'0px' }}>
                                                <p style={{margin:'0px'}}>Start</p>
                                            </div>
                                            <div className="column" style={{ width: '50%',margin:'0px',padding:'0px' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.startCharge?(selectedTrip?.startCharge)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%',margin:'0px',padding:'0px' }}>
                                                <p style={{margin:'0px'}}>Booking</p>
                                            </div>
                                            <div className="column" style={{ width: '50%',margin:'0px',padding:'0px' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.bookingCharge?(selectedTrip?.bookingCharge)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%',margin:'0px',padding:'0px' }}>
                                                <p style={{margin:'0px'}}>Wait Charge</p>
                                            </div>
                                            <div className="column" style={{ width: '50%',margin:'0px',padding:'0px' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.waitCharge?(selectedTrip?.waitCharge)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}>Distance Charge</p>
                                            </div>
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.distanceCharge?(selectedTrip?.distanceCharge)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}>{selectedTrip?.taxLabel?selectedTrip?.taxLabel:'Tax'}</p>
                                            </div>
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.tax?(selectedTrip?.tax)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}>Levy</p>
                                            </div>
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.levy?(selectedTrip?.levy)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}>Extra</p>
                                            </div>
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.extraCharge?(selectedTrip?.extraCharge)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}>Tip</p>
                                            </div>
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.tip?(selectedTrip?.tip)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}>CC Fee</p>
                                            </div>
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.ccCharge?(selectedTrip?.ccCharge)/100:'0'}</b></p>
                                            </div>
                                        </div>     
                                        <hr color='#D3D3D3' />
                                        <div className="row">
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}>Tolls</p>
                                            </div>
                                            <div className="column" style={{ width: '50%' }}>
                                                <p style={{margin:'0px'}}><b>${selectedTrip?.tolls?(selectedTrip?.tolls)/100:'0'}</b></p>
                                            </div>
                                        </div>
                                        <div className="row" style={{ height: 30 }}>
                                        </div>
                                    </div>
                                    <div className="column" style={{ textAlign: 'center' }}>
                                        <div style={{ padding: 60 }}>
                                            <h4 style={{ padding: 0, margin: 0, textTransform: 'uppercase' }}>Total</h4>
                                            <h2 style={{ padding: 0, margin: 0, fontSize: 40 }}>${selectedTrip?.totalFare?(selectedTrip?.totalFare)/100:'0'}</h2>
                                            <hr color='#D3D3D3' />
                                           {selectedTrip?.paidBy?<><p style={{ padding: 0, margin: 0, textTransform: 'uppercase' }}>paid via</p>
                                            <h4 style={{ textTransform: 'uppercase' }}>{selectedTrip?.paidBy === paidBy.CreditCard?paidBy.CreditCard:paidBy.Cash}</h4>
                                            <hr color='#D3D3D3' /></>:null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="backgroundOrange footer inverted-border-radius-footer">
                <p style={{ padding: 5, paddingLeft: 30, paddingRight: 30 }}>Central Booking &nbsp;&nbsp;<b>1300 864 222</b>
                    <span style={{ textAlign: 'right', float: 'right' }}><b><a href="mailto:mail@taxi.plus">mail@taxi.plus</a>
                        &nbsp;&nbsp; 0419 27 27 44
                    </b>
                    </span>
                </p>
            </footer>

        </>
    )
}