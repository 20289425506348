import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import { UseFormMethods } from 'react-hook-form';

const pdf = require(`../../img/pdf.png`);

export interface UploadPreviewUrl {
  thumb: string;
  full: string;
};

interface Props {
  label: string;
  name: string;
  originalName: string;
  urls?: UploadPreviewUrl[];
  description?: string;
  multiple?: boolean;
  className?: any;
  autoFocus?: boolean;
  tabIndex?: number;
  onchange?: any;
  FormEditingOnclick?: (e: any) => void
  FormAddingOnclick?: (e: any) => void

}

interface DropzoneFile extends File {
  dataUrl?: string;
}

export let selectedUrl: string | undefined = '';
export let show: boolean = false;
export let selectedPic: string | undefined = '';


export function FormUpload(props: Props & UseFormMethods) {

  let expandedImgRef!: HTMLImageElement | null;
  let dropzone = useRef<DropzoneRef>(null);

  const {
    name, originalName, label, urls, description, multiple, className, register, errors,
    setError, setValue, tabIndex, onchange, FormEditingOnclick, FormAddingOnclick, reset, clearErrors } = props;
  const [files, setFiles] = useState<DropzoneFile[]>([]);
  const [expanded, setExpanded] = useState<string>();


  useEffect(() => register({ name, required: true }), [register, name]);
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (expandedImgRef !== event?.currentTarget) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mousedown', listener);
    return () => { document.removeEventListener('mousedown', listener); }
  }, [expandedImgRef]);

  const onDropped = (_files: File[]) => {
    clearErrors()
    const __files: DropzoneFile[] = files.concat(_files);
    for (let __file of __files) {
      __file.dataUrl = __file.dataUrl || URL.createObjectURL(__file);
    }
    // errors.map
    setFiles(__files);
    setValue(name, multiple ? __files : __files[0]);
  }

  const onError = (rejections: FileRejection[]) => {
    const [{ errors }] = rejections;
    const [error] = errors;
    setError(name, { type: error.code, message: error.message });
  }

  const onClick = (url: string) => {
    if (multiple) {
      setExpanded(url);
    } else {
      // dropzone.current?.open();
    }
  }

  const onPdfClick = (url: string) => {
    window.open(url);
  }

  const hideDropzone = () => !multiple && files.length > 0;
  const hasPreview = () => files.length > 0 || (urls && urls.length > 0);



  return (
    <div className={classNames(
      {
        'form-upload': true,
        multiple: multiple,
        single: !multiple,
        'has-error': errors[name],
      },
      className,
    )} tabIndex={tabIndex}>
      {expanded && (
        <div>
          <img
            ref={r => expandedImgRef = r}
            className="expanded-img"
            src={expanded}
            alt={label}
          />
          <div className="expanded-backdrop"></div>
        </div>)}
      {hasPreview() && (<div className="preview-container">
        {urls?.map((url, i) => (
          url.thumb.includes('admin_single.pdf') ?
            <div key={i}>
              <img key={i} src={pdf} style={{ cursor: 'pointer' }}
                alt={i.toString()} onClick={() => onPdfClick(url.full)}
              />
              <a className="b" href="#delete" style={{ zIndex: 1000000, position: "absolute" }} onClick={(e: any) => {
                e.preventDefault()
                selectedUrl = url.thumb
                if (FormEditingOnclick) {
                  FormEditingOnclick(e)
                }
              }}>
                <i className="os-icon os-icon-trash-2"></i>
              </a>
            </div> :
            <div key={i}>
              <img
                key={i}
                src={url.thumb}
                alt={label}
                onClick={() => onClick(url.full)}
              />
              <a className="b" href="#delete" style={{ zIndex: 1000000, position: "absolute" }} onClick={(e: any) => {
                e.preventDefault()
                selectedUrl = url.thumb
                if (FormEditingOnclick) {
                  FormEditingOnclick(e)
                }
              }}>
                <i className="fa fa-trash"></i>
              </a>
            </div>
        ))}
        {files.map((file, i) => (
          file.type.includes('application/pdf') ?
            <div key={i} >
              <img src={pdf} style={{ cursor: 'pointer' }}
                alt={i.toString()} onClick={() => onPdfClick(file.dataUrl as string)}
              />
              <a key={i} className="b" href="#delete" style={{ zIndex: 1000000, position: "absolute" }} onClick={(e: any) => {
                e.preventDefault()
                setFiles(() => {
                  return files.filter((fill: DropzoneFile) => fill.dataUrl != file.dataUrl);
                });
                setValue('', '')
                selectedPic = file.dataUrl
                show = true
              }}>
                <i className="fa fa-trash"></i>
              </a>
            </div> :
            <div key={i} >
              <img
                key={i}
                src={file.dataUrl}
                className="preview"
                alt={label}
                onClick={() => onClick(file.dataUrl as string)}
              />
              <a key={i} className="b" href="#delete" style={{ zIndex: 1000000, position: "absolute" }} onClick={(e: any) => {
                e.preventDefault()
                setFiles(() => {
                  return files.filter((fill: DropzoneFile) => fill.dataUrl != file.dataUrl);
                });
                setValue('', '')
                selectedPic = file.dataUrl
                show = true
              }}>
                <i className="fa fa-trash"></i>
              </a>
            </div>
        ))}
      </div>
      )}
      <Dropzone
        ref={dropzone}
        accept="image/jpeg,image/png,image/jpg, application/pdf"
        multiple={multiple}
        maxSize={5 * 1024 * 1024}
        onDropRejected={onError}
        onDrop={onDropped}
        onDropAccepted={onchange}>

        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({
            className: classNames(
              'upload-dropzone',
              { 'hidden': false },
              { 'has-preview': hasPreview() },
            )
          })}>
            <input ref={register} name={name} {...getInputProps()} tabIndex={tabIndex} />
            <h3>{label}</h3>
            {description && <p>{description}</p>}
            {errors[name] &&

              <p className="error-message">{errors[name].message}</p>

            }
          </div>
        )}
      </Dropzone>

      {multiple ? urls?.map((_, i) => (
        <>
          <input ref={register} type="hidden" name={`${originalName}[${i}].id`} />
          <input ref={register} type="hidden" name={`${originalName}[${i}].extension`} />
          <input ref={register} type="hidden" name={`${originalName}[${i}].type`} />
        </>
      )) : (
        <>
          {/* <input ref={ register } type="hidden" name={`${originalName}.id`} />
          <input ref={ register } type="hidden" name={`${originalName}.extension`} />
          <input ref={ register } type="hidden" name={`${originalName}.type`} /> */}
        </>
      )}
    </div>
  );
}
