import React, { useState } from "react";
import { DriverFormSection, DriverFormSectionProps } from "../Section";
import { FormUpload } from "../Upload";

export function DriverFormVerification(props: DriverFormSectionProps) {
  const { getValues, register } = props;
  const [identityFrontFiles, setIdentityFrontFiles] = useState();
  const [identityBackFiles, setIdentityBackFiles] = useState();
  const [policeFiles, setPoliceFiles] = useState();
  const [historyFiles, setHistoryFiles] = useState();
  const [medicareCardFiles, setMedicareCardFiles] = useState()
  const [englishLanguageProficiencyFiles, setEnglishLanguageProficiencyFiles] = useState()
  const [englishLanguageProficiencyFilesError, setEnglishLanguageProficiencyFilesError] = useState<string>()
  const [medicareCardFilesError, setMedicareCardFilesError] = useState<string>()
  const [identityFilesFrontError, setIdentityFilesFrontError] = useState<string>();
  const [identityFilesBackError, setIdentityFilesBackError] = useState<string>();
  const [policeFilesError, setPoliceFilesError] = useState<string>();
  const [historyFilesError, setHistoryFilesError] = useState<string>();
  const [citizen, setCitizen] = useState<boolean>(false)

  // const handleCitizen = (event:any) =>{
  //   event.target.isChecked
  // }

  const onChangeMedicareCardFiles = () => {
    const data = getValues();
    setMedicareCardFiles(data.newMedicareFiles);
    if (medicareCardFiles === undefined) {
      setMedicareCardFilesError('Medicare Files are missing')
    }
    else {
      setMedicareCardFilesError(undefined);
    }
  }

  const onChangeEnglishLanguageProficiencyFiles = () => {
    const data = getValues();
    setEnglishLanguageProficiencyFiles(data.newProficiencyFiles);
    if (englishLanguageProficiencyFiles === undefined) {
      setEnglishLanguageProficiencyFilesError('English Language Proficiency Files are missing')
    }
    else {
      setEnglishLanguageProficiencyFilesError(undefined);
    }
  }

  const onChangeIdentityFilesFront = () => {
    const data = getValues();
    setIdentityFrontFiles(data.newIdentityFrontFile);
    if (identityFrontFiles === undefined) {
      setIdentityFilesFrontError('Identity Front File is missing')
    }
    else {
      setIdentityFilesFrontError(undefined);
    }
  }

  const onChangeIdentityFilesBack = () => {
    const data = getValues();
    setIdentityBackFiles(data.newIdentityBackFile);
    if (identityFilesBackError === undefined) {
      setIdentityFilesBackError('Identity Back File missing')
    }
    else {
      setIdentityFilesBackError(undefined);
    }
  }
  const onChangepoliceFiles = () => {
    const data = getValues();
    setPoliceFiles(data.newPoliceFiles);
    if (policeFiles === undefined) {
      setPoliceFilesError('Police Check Files are missing')
    }
    else {
      setPoliceFilesError(undefined);
    }
  }
  const onChangeHistoryFiles = () => {
    const data = getValues();
    setHistoryFiles(data.newHistoryFiles);
    if (historyFiles === undefined) {
      setHistoryFilesError('Driving History Files are missing')
    }
    else {
      setHistoryFilesError(undefined);
    }
  }
  const onchange = () => {
    onChangeHistoryFiles();
    onChangeIdentityFilesFront();
    onChangeIdentityFilesBack();

    onChangepoliceFiles();
    // onChangeMedicareCardFiles();
    onChangeEnglishLanguageProficiencyFiles();
  }
  return (

    <DriverFormSection {...props}>
      <>
        {identityFilesFrontError === undefined && identityFilesFrontError && <div className="error">{identityFilesFrontError}</div>}
        {identityFilesBackError === undefined && identityFilesBackError && <div className="error">{identityFilesBackError}</div>}
        {policeFiles === undefined && policeFilesError && <div className="error">{policeFilesError}</div>}
        {historyFiles === undefined && historyFilesError && <div className="error">{historyFilesError}</div>}
        {/* {medicareCardFiles === undefined && medicareCardFilesError && <div className="error">{medicareCardFilesError}</div>} */}
        {englishLanguageProficiencyFiles === undefined && englishLanguageProficiencyFilesError && <div className="error">{englishLanguageProficiencyFilesError}</div>}
        <FormUpload
          label='Proof of Identity Front'
          name="newIdentityFrontFile"
          originalName="identityFrontFile"
          onchange={onChangeIdentityFilesFront}
          description='Scanned copies of any government issued identification doument'
          multiple={false}

          {...props}
        />

        <FormUpload
          label='Proof of Identity Back'
          name="newIdentityBackFile"
          originalName="identityBackFile"
          onchange={onChangeIdentityFilesBack}
          description='Scanned copies of any government issued identification doument'
          multiple={false}

          {...props}
        />

        <div>
          <label className="register-radio">I am not a citizen
            <input
              ref={register({ required: false })}
              name="citizen"
              type="checkbox"
              onChange={() => setCitizen(!citizen)}
            />
          </label>
        </div>
        {citizen === true ?
          <>
            <FormUpload
              label='VEVO'
              name="newVevoFiles"
              originalName="vevoFiles"
              description='Scanned copies of VEVO Files'
              multiple={true}
              {...props}
            />


          </> : null}

        {citizen === false ? <FormUpload
          label='Medicare Card'
          name="newMedicareFiles"
          // onchange={onChangeMedicareCardFiles}
          originalName="medicareFiles"
          description='Scanned copies of Medicare Card'
          multiple={true}

          {...props}
        /> : null}

        <FormUpload
          label='English Language Proficiency Test'
          name="newProficiencyFiles"
          originalName="proficiencyFiles"
          onchange={onChangeEnglishLanguageProficiencyFiles}
          description='Scanned copies of English Language Proficiency Test'
          multiple={true}

          {...props}
        />

        <FormUpload
          label='Police Check'
          name="newPoliceFiles"
          onchange={onChangepoliceFiles}
          originalName="policeFiles"
          description='Scanned copies of Police Background Check'
          multiple={true}

          {...props}
        />

        <FormUpload
          label='Driving History'
          name="newHistoryFiles"
          onchange={onChangeHistoryFiles}
          originalName="historyFiles"
          description='Scanned copies of your Certified Driving Record'
          multiple={true}

          {...props}
        />
        {policeFiles === undefined || historyFiles === undefined || identityFrontFiles === undefined || identityBackFiles === undefined || englishLanguageProficiencyFiles === undefined ?
          <div
            onClick={onchange}
            className="register-submit"
          >Next</div>
          :
          <input
            style={{ marginBottom: '50px' }}
            value={'next'}
            className="register-submit"
            type={'submit'}
          />
        }
      </>
    </DriverFormSection>

  );
}