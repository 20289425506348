import React from 'react';
import { Footer } from '../../footer/Footer';

export function SeptNews(){
    return(
        <>
            <section className="section-yellow section-bottom-border feature-section"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-bottom-10">
                                <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> September Newsletter </h1>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <h2 className="section-subtitle" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}>Regional Taxi Networks</h2>

                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">For those of us who have almost got to the other side of the Pandemic, it is now time to reassess our Taxi Future. The Disruption of so-called “ride share”, followed by the near Destruction of COVID 19 makes it necessary to honestly review and rebuild our Taxi Industry. We need a new operational model in which the traditional and excessive costs are minimised, and which adopts technological changes beyond the best of our competitors. The new model must work equally in Metropolitan or Regional areas, and provide all our Passengers with an Optimal Taxi Service.</p>
                            </div> 
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">TAXIS.PLUS provides a solution. It is a totally new system formulated by and for Taxi Drivers, and constructed by a small and incredibly skilled group of program developers. It operates on a single Tablet, with Internal and External Camera Alarm System and a new Roof Bar, with in-built Meter and GPS. It’s all WiFi, so there is practically  no installation cost or downtime. For new vehicles with an Android Auto Screen, it can operate off your Smartphone. All the records – trips, routes, audio-video are stored on the Cloud, ready for immediate download. Bookings, Despatch and Payments are built-in.  Receipts in full detail go out by e-mail or SMS. Fares are automatically re-distributed at end-of-shift to Driver and or Operator. It has NFC for Credit Cards, or Registered  Passenger Cards, or can even accept Cash. As the local Network you can make all your own rules and regulations. Because we are providing the Driver with full information, with Google Route and Fare Estimates, we have one rule :  If you accept the job; you do the job.</p>
                            </div> 
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">We are making the TAXIS.PLUS system freely available to Taxi Networks. We will retain the Credit Card Transaction Fee (4%) and $1.00 of the Booking Fee. The Operator can purchase the Tablet with pre-paid SIM, Cameras and Roof Bar at a once-off cost of $2000, or he can continue to use his existing equipment in conjunction with his own Smartphone or Tablet. We recommend he moves over completely when he is ready.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Given that over 90% of Regional Fares are Booked, our analysis shows that if you split the balance of the Booking Fee between the Driver and your own Network, you can keep Drivers happy, and fund your own expenses to significantly reduce or even eliminate Network Fees. Our APP enables the Passenger to Register and Book or actually talk to your office or our call centre. You can re-badge the App if you want.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">So, what’s different ?  The system Software takes care of most, if not all, of your Regulatory obligations. The Passenger Service Levy is deducted on every trip, and is accumulated separately. Records are all on-system.      As Network Manager you have access to (almost) everything. If you want, you can also Despatch work from the CAD ( Computer Aided Despatch) module. You can see every Taxi and talk to every Driver. You can set up your own Fatigue Management system. You can Warn, Restrict or Block Drivers, according to your own rules.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">You can’t charge more than the Regulated Fare for Rank and Hail ; but you can charge less !!   For all your Booked trips you can, however, be creative. We can set Fare Tariffs as you require, and with Special Rates for Special Jobs. The extra responsibilities from the Regulator are easily up-dated into our system – notification of incidents, records of Inspections, etc. The in-built meter prompts for tips, and announces the Fare Total.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">It's time to change and move forward.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">Give me a call.</p>
                            </div>
                            <div className="col-md-12 padding-bottom-10">
                                <p className="section-subtitle">
                                    Michael Jools<br />
                                    TAXIS.PLUS<br />
                                    0419 27 27 44<br />
                                    mj@taxis.plus
                                </p>
                            </div>
                        </div>
                    </div>
            </section>
            <Footer />
        </>
    )
}