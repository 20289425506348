import React, { useEffect } from 'react';
import { DriverFormSection, DriverFormSectionProps } from '../Section';

export function DriverFormError(props: DriverFormSectionProps) {
  return (
    <DriverFormSection {...props}>
      <div className="registeredfail">
        <p>Failed To register driver</p>
        {/* <p>This Driver is already registered.</p> */}
      </div>
    </DriverFormSection>
  );
}