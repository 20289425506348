import React, { useCallback, useEffect, useState } from 'react';
import { ApiService } from '../../../service/api.service';
import { Footer } from '../../footer/Footer';

interface Article {
    title: string,
    date: string,
    description: string;
}

export function NewsDetail(props: any) {

    const [articleData, setArticleData] = useState<Article>();
    const [date, setDate] = useState<string>();

    const refresh = useCallback(async () => {
        try {
            const response = await ApiService.get(`article/public/${props.match.params.article}`);
            setArticleData(response.data);
            if (response.data) {
                const date: Date = new Date(response.data.date);
                const month = ((date.getMonth() + 1) < 10) ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
                const day = ((date.getDate()) < 10) ? `0${date.getDate()}` : `${date.getDate()}`
                const formattedDate = `${date.getFullYear()}-${month}-${day}`;
                setDate(formattedDate);
            }
        } catch (err) {
            console.log('Failed to load Article', err);
        }
    }, [articleData])

    useEffect(() => {
        refresh();
    }, [refresh])
    
    return (
        <>
            <section className="section-yellow section-bottom-border feature-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h1 className="section-title" style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> {articleData?.title} </h1>
                            <h6 style={{ color: "#071e55", fontFamily: "Dosis,sans-serif" }}> Date: {date} </h6>
                        </div>
                        <div className="col-md-12 padding-bottom-10">
                            <div className="section-subtitle" dangerouslySetInnerHTML={{ __html: articleData ? articleData.description : '' }}></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}