import React from 'react';
import { DriverAgreement } from '../Agreement';
import { DriverFormSection, DriverFormSectionProps } from '../Section';

export function DriverFormAgreement(props: DriverFormSectionProps) {

  return (
    <DriverFormSection {...props}>
      <>
        <DriverAgreement />

        <p><b>By clicking the "Next" button below, you consent to the agree "Driver Agreement" above.</b></p>
        <input style={{marginBottom:'50px'}}
          value='Next'
          className="register-submit"
          type="submit"
        />
      </>

    </DriverFormSection>
  );
}