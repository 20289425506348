import React from 'react';
import { Footer } from '../footer/Footer'

export function Contact() {
    return (
        <>
            <section id="main-content" className="about-section text-center">
                <h2 className="mb-20 section-title">
                    Contact us:
                    <span className="d-block h4 section-title">TAXIS.PLUS and TAXIS.NET.AU</span>
                </h2>
                <br />
                <h3 className="h4 section-subtitle">Michael Jools</h3>
                <ul className="list-unstyled">
                    <li>Central Booking: <a href="tel:1300864222">1300 864 222</a></li>
                    <li>Email: <a href="mailto:mail@taxis.plus">mail@taxis.plus</a></li>
                    <li>Mobile: <a href="tel:0419272744">0419 27 27 44</a></li>
                    <li>ABN 63 516 729 945</li>
                </ul>
            </section>

            <Footer />

        </>
    )
} 