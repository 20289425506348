import React, { useEffect, useState } from 'react';
import { ApiService } from '../../service/api.service';
import { Consent } from './forms/Consent';


export function DriverAgreement() {
  const [driverAgreement, setDriverAgreement] = useState<string>();

  useEffect(() => {
    refresh();
  }, [])

  const refresh = async () => {
    try {
      const response = await ApiService.get('consent/public/currentConsent');
      const data: Consent = response.data;
      setDriverAgreement(data.driverAgreement);
    } catch (err) {
      console.log('Failed to load fleet list', err);
    }
  }
  return (
    <div className="container text-justify">
      <h1 className="padding-top-20">Driver Agreement</h1>
      <p className="section-subtitle" style={{whiteSpace:'pre-wrap'}}>
       {driverAgreement}
      </p>
    </div>
  );
}