import React from 'react';
import { Link } from "react-router-dom";

export function Footer() {
   return (
      <div className="footer">
         <div className="container-fluid px-0">
            <div className="row no-gutters mx-0">
               <div className="col-md-4 footer-white-box text-center">
                  <i className="pe-7s-map-2"></i>
                  <h5>Contact Us</h5>
                  <p>Central Booking: <a href="tel:1300864222">1300 864 222</a></p>
                  <p><a href="mailto:mail@taxis.plus">mail@taxis.plus</a></p>
                  <p><a href="tel:0419272744">0419 27 27 44</a></p>
               </div>
               <div className="col-md-4 footer-dark-grey-box text-center">
                  <i className="pe-7s-comment"></i>
                  <h5>Social Media</h5>
                  <p>See below where you can find us.</p>
                  <ul className="footer_social">
                     <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                     <li><a href="#"><i className="fa fa-facebook-square"></i></a></li>
                     <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                  </ul>
               </div>
               <div className="col-md-4 footer-grey-box text-center">
                  <i className="pe-7s-link"></i>
                  <h5>Links</h5>
                  <Link to="/privacy-policy/" className="footer-links">Privacy Policy</Link>
                  <Link to="/terms-conditions/" className="footer-links">Terms and Conditions</Link>
               </div>
            </div>
            <div className="row">
               <div className="col-md-12 footer-bottom text-center">
                  <p>Copyright © 2022 <strong>Taxis.Plus</strong></p>
               </div>
            </div>
         </div>
      </div>
   )
}
