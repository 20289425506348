import React, { useEffect, useState } from 'react';
import { ApiService } from '../../../service/api.service';
import { DriverFormSection, DriverFormSectionProps } from '../Section';


export interface Consent {
  id: string,
  driverUseOfInformation: string,
  driverAgreement: string,
}
export function DriverFormConsent(props: DriverFormSectionProps) {
  const [consent, setConsent] = useState<Consent>();
  const { register } = props;

  useEffect(() => {
    refresh();
  }, [])

  const refresh = async () => {
    try {
      const response = await ApiService.get('consent/public/currentConsent');
      const data: Consent = response.data;
      setConsent(data);
    } catch (err) {
      console.log('Failed to load fleet list', err);
    }
  }
  return (
    <DriverFormSection {...props}>
      <>
        <div className="container text-justify">
          <h1 className="padding-top-20">Use of Information</h1>
          <p className="mb-30" style={{whiteSpace:'pre-wrap'}}>
            {consent?.driverUseOfInformation}
          </p>
        </div>
        <input
          ref={register({ required: true })}
          name="consentId"
          type="hidden"
          value={consent?.id}
        />
        <p><b>By clicking the "Complete Registration" button below, you consent to the agree "Driver Consent" above.</b></p>
        <input
        style={{marginBottom:'50px'}}
          value='Complete Registration'
          className="register-submit"
          type="submit"
        />
      </>

    </DriverFormSection>
  );
}