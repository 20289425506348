import React from 'react';
import { DriverStep, DriverViewState } from './Register';

interface Props {
  steps: DriverStep[],
  currentView: DriverViewState,
  setStep?: (step: DriverStep) => void,
}

export function DriverSteps({ steps, currentView, setStep }: Props) {

  const isCurrentStep = (step: DriverStep): boolean => step.view === currentView;

  const isIncomplete = (step: DriverStep): boolean => !step.completed;

  const getClassNames = (step: DriverStep): string => {
    const classNames = ['nav-link'];
    if (isCurrentStep(step)) {
      classNames.push('active');
    } else if (isIncomplete(step)) {
      classNames.push('muted');
    }
    return classNames.join(' ');
  }

  const onSelect = (step: DriverStep) => {
    if (!isIncomplete(step) && setStep) {
      setStep(step);
    }
  }

  return (
    <div className="form-steps nav flex-column nav-pills">
      { steps.map(step => (
        <a href={`#step-${step.view}`} key={ step.view } className={ getClassNames(step) } onClick={ () => onSelect(step) }>
          <div className="features-second">
            <h4>{ step.title }</h4>
            <p>{ step.description }</p>
          </div>
        </a>
      ))}

    </div>
  );
}
