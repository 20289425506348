import { Autocomplete } from "@react-google-maps/api";
import React, { FormEvent, useRef, useState } from "react";
import { ApiService } from "../../../service/api.service";
import { DriverFormSectionProps } from "../../drivers/Section";
import { OperatorFormSection } from "../Section";

export function Form(props: DriverFormSectionProps) {

    const { register, setValue } = props;

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [title, setTitle] = useState("");
    const [contactPerson, setPerson] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setPhone] = useState("");
    const [contactAddress, setAddress] = useState("");
    const [businessRegistration, setBusinessRegistration] = useState("");
    const [accreditionNumber, setAccreditionNumber] = useState("");
    const [bankSwiftCode, setBankSwiftCode] = useState("");
    const [password, setPassword] = useState("");
    let pickupAutocompleteRef = useRef<Autocomplete>(null);
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(undefined);
        setLoading(true);
        try {
            const res = await ApiService.post('operator/register', {
                contactEmail, businessRegistration, accreditionNumber, bankSwiftCode, password, title, contactPerson, contactPhone, contactAddress: { lat: 0, lng: 0, text: contactAddress },
            });
            setRegistered(true);
        } catch (err: any) {
            setError(err.message);
        }
        setLoading(false);

        return false;
    }
    const onPlaceChanged = () => {
        const autocomplete: google.maps.places.Autocomplete | null | undefined = pickupAutocompleteRef.current?.state.autocomplete;

        if (autocomplete) {
            const place = autocomplete.getPlace();
            var city, state, country;
            const address: any = place.address_components
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            address?.map((component: any) => {
                var types = component.types;
                if (types.indexOf('locality') > -1) {
                    city = component.long_name;
                }

                if (types.indexOf('administrative_area_level_1') > -1) {
                    state = component.short_name;
                }

                if (types.indexOf('country') > -1) {
                    country = component.long_name;
                }
            });

            const lat = place.geometry?.location.lat();
            const lng = place.geometry?.location.lng();
            setValue(`contactAddress.lat`, lat);
            setValue(`contactAddress.lng`, lng);
            setValue(`contactAddress.text`, place.formatted_address);
            setValue(`contactAddress.placeId`, place.place_id);
            setValue(`contactAddress.country`, country);
            setValue(`contactAddress.state`, state);
            setValue(`contactAddress.city`, city);

            // } else {
            // console.log(`Unable to fetch current coordinates!`)
            // }
        }
    }
    return (
        <OperatorFormSection {...props}>
            <>
                <div>
                    <input
                        name="title"
                        placeholder="Company"
                        type="text"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        autoFocus
                        required />
                </div>
                <div>
                    <input
                        name="password"
                        type="password"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required />
                </div>
                <div>
                    <input
                        name="contactPerson"
                        type="text"
                        placeholder="Contact Person"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        value={contactPerson}
                        onChange={e => setPerson(e.target.value)}
                        required />
                </div>
                <div>
                    <input
                        name="contactEmail"
                        type="email"
                        placeholder="Contact Email"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        value={contactEmail}
                        onChange={e => setContactEmail(e.target.value)}
                        required />
                </div>
                <div>
                    {/* <input
                        name="contactAddress"
                        type="text"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Contact Address"
                        value={contactAddress}
                        onChange={e => setAddress(e.target.value)}
                        required /> */}
                    <Autocomplete
                        onPlaceChanged={onPlaceChanged}
                        ref={pickupAutocompleteRef}
                        fields={['geometry', "address_component", "formatted_address"]}
                        restrictions={{ country: ['au'] }}
                    >
                        <input
                            ref={register({ required: true })}
                            name="contactAddress"
                            type="text"
                            placeholder="Home Address"
                            className="register-input white-input"
                        />
                    </Autocomplete>
                    <input type="hidden" name={`contactAddress.lat`} ref={register} />
                    <input type="hidden" name={`contactAddress.text`} ref={register} />
                    <input type="hidden" name={`contactAddress.lng`} ref={register} />
                    <input type="hidden" name={`contactAddress.placeId`} ref={register} />
                    <input type="hidden" name={`contactAddress.country`} ref={register} />
                    <input type="hidden" name={`contactAddress.state`} ref={register} />
                    <input type="hidden" name={`contactAddress.city`} ref={register} />
                </div>
                <div>
                    <input
                        name="contactPhone"
                        type="number"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Contact Phone"
                        value={contactPhone}
                        onChange={e => setPhone(e.target.value)}
                        required />
                </div>
                <div>
                    <input
                        name="businessRegistration"
                        type="text"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="ABN / CAN"
                        value={businessRegistration}
                        onChange={e => setBusinessRegistration(e.target.value)}
                        required />
                </div>
                <div>
                    <input
                        name="accreditionNumber"
                        type="text"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Accredition Number"

                    />
                </div>
                <div>
                    <input
                        name="bankSwiftCode"
                        type="text"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Bank BSB"

                    />
                </div>
                <div>
                    <input
                        name="bankName"
                        type="text"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Bank Name"

                    />
                </div>
                <div>
                    <input
                        name="bankAccountNumber"
                        type="number"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Bank Account Number"

                    />
                </div>
                <div>
                    <input
                        name="bankAccountTitle"
                        type="text"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="Bank Account Title"

                    />
                </div>
                {/* <div>
                    <textarea
                        style={{ width: '100%' }}
                        name="bankSwiftCode"
                        ref={register({ required: true })}
                        className="register-input white-input"
                        placeholder="BSB Details"
                        value={bankSwiftCode}
                        onChange={e => setBankSwiftCode(e.target.value)}
                    />
                </div> */}
                <input
                    value='Next'
                    className="register-submit"
                    type="submit"
                />
            </>
        </OperatorFormSection>
    );
}
