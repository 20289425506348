import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { PaxApiService } from '../../service/api.service';
import { authentication } from '../../service/auth.service';
import { Footer } from '../footer/Footer';

export enum PassengerStatus {
    Active = 'active',
    Blocked = 'blocked',
  }
  
  export enum Gender {
    Male = 'male',
    Female = 'female',
    Other = 'other',
  }
  
  export interface Passenger { 
    id:string  
    firstName: string;
    lastName: string;
    gender: Gender;
    email: string;
    phone: string;
    dateOfBirth: Date | string;
    status?: PassengerStatus;
  } 

export let passenger:Passenger  = { 
    id:'',
    firstName: '',
    lastName: '',
    gender: Gender.Male,
    email:'',
    phone:'',
    dateOfBirth:''
}

export let navbarShow:boolean = true;

export const PassengerLogin = (props:any) => {

   const [phone, setPhone] = useState<string>();
   const [password, setPassword] = useState<string>(); 

    const onHandleClieck = async (e: any) => {
      e.preventDefault()
      try {
        const response =  await PaxApiService.post('passenger/login',{
            phone,
            password
          })

          if(response){
            navbarShow = false
            authentication.onAuthentication(response.data.passenger)  
            window.location.replace('/passenger-details')
          }

          passenger = response.data.passenger  
      } catch (err) {
        alert('Invalid Credentials');
          throw(err)
      }
    } 

    return (
        <>
        <section style = {{paddingBottom:160}} className="section-yellow section-bottom-border feature-section" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 padding-top-20">
                        <h1>Login as a Passenger</h1>
                        <div className="register-form-wrapper wow bounceIn" data-wow-delay="0.5s">
                            <div>
                                <p className="register_success_box" style={{ display: "none" }}>We received your message and you'll hear from us soon. Thank You!</p>
                                <form id="register-form" className="register-form register" method="post" onSubmit={onHandleClieck}>  
                                        <input autoFocus className="register-input white-input" required placeholder="Phone Number" type="tel" onChange={(e: any) => setPhone(e.target.value)} /> 
                                        <input className="register-input white-input" required placeholder="Password" type="password" onChange={(e: any) => setPassword(e.target.value)} />
                                    <input value="Login" className="register-submit" type="submit" />
                                </form>
                                <p className="register-form-terms"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </>
    )
}