import React from 'react';
import { Navbar } from './../navbar/Navbar';
import {Footer} from './../footer/Footer'
import { authentication } from '../../service/auth.service';
export function Page404(props:any) {
    return (
        <>
            <Navbar isCorporate={props.isCorporate} isPassenger={props.isPassenger} corporateUser={authentication.getCorporateUser()} />
            <div className="about-section ">
                <h2 className="text-center">
                    404 Page Not Found !
                </h2>
            </div>
            <Footer/>
        </>
    )
} 