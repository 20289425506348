import React, { useCallback, useEffect, useState } from 'react';
import { ApiService } from '../../service/api.service';
import { Footer } from '../footer/Footer';



export function UnsubscribeEmail(props: any) {

    const [unsubscribed, setUnsubscribed] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    
    const [supportMessage, setSupportMessage] = useState<string>();


    useEffect(() => {
        refresh();
    }, [])

    const refresh = useCallback(async () => {
        try {
            const response = await ApiService.delete(`/newsletter/unsubscribe/${props.match.params.id}`);
            if (response.data) {
                setUnsubscribed(true);
            }
        } catch (err) {
            console.log('Error: ', err);
            setErrorMessage("Something went wrong, please try again later.")
            setSupportMessage('Contact us at mail@taxis.plus to have it removed manually')
        }
    }, [unsubscribed])
    return (
        <>
            <section className="section-yellow section-bottom-border feature-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-bottom-10">
                            <h2>Please wait...</h2>
                           {unsubscribed?
                           <div className="registered">
                                <p>You have unsubscribed from our newsletter</p>
                                <p>You will no longer receive our latest updates and emails.</p>
                            </div>:
                            errorMessage?
                            <div className="registeredfail">
                                <p style={{color:'white'}}>{errorMessage}</p>
                                <p style={{color:'white'}}>{supportMessage}</p>
                            </div>:null}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}